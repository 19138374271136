import '@/main.css'
/**
 * Order of these components is important
 * Try to keep the most important components at the top or the ones that must be render first
 */

import '@/Views/Blocks/GlobalNavigation.js'
import '@/Views/Blocks/NotificationsBar.js'

import '@/Views/SearchResultsPage.js'
import '@/Views/BlogArticleLandingPage.js'
import '@/Views/BlogCategoryLandingPage.js'
import '@/Views/BlogLandingPage.js'
import '@/Views/LoginPage.js'
import '@/Views/BlocksOnly.js'

import '@/Views/Blocks/HeroBlockV3.js'
import '@/Views/Blocks/CardSectionV1.js'
import '@/Views/Blocks/CardSectionV5.js'
import '@/Views/Blocks/ContentSectionV1a.js'
import '@/Views/Blocks/QuoteBlockV1b.js'
import '@/Views/Blocks/CallToActionV3.js'
import '@/Views/Blocks/ContentSectionV4.js'
import '@/Views/Blocks/ContentSectionV6.js'

import '@/Views/Blocks/PersonalisationBlockV1a.js'
import '@/Views/Blocks/PricePlansV1.js'
import '@/Views/Blocks/ComparisonTableV1a.js'

import '@/Views/Blocks/FAQBlock.js'
import '@/Views/Blocks/FormSection.js'

import '@/Views/Blocks/Footer.js'

const handleRegionalization = () => {
  const hasRegionData = document.querySelector('[data-umbraco-regionalization]')
  if (hasRegionData?.dataset?.umbracoRegionalization) {
    const regionData = JSON.parse(hasRegionData.dataset.umbracoRegionalization)
    window.localStorage.setItem('regionIso', regionData.Country)
    window.localStorage.setItem('regionGroup', regionData.LanguageGroup)
  }
}

function setCookie (name, value, days, domain) {
  if (!name || !value) return
  const _nameValue = `${name}=${encodeURIComponent(value)};`

  const maxAge = days * 24 * 60 * 60
  const _maxAge = `max-age=${maxAge};`

  const _path = 'path=/;'
  const _domain = domain ? `domain=${domain};` : ''

  document.cookie = `${_nameValue} ${_maxAge} ${_path} ${_domain}`
}

function handlePartnerParam () {
  const searchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(searchParams.entries())

  if (params.partner) {
    const domain = document.location.hostname.endsWith('gettimely.com') ? '.gettimely.com' : undefined
    setCookie('TimelyReseller', params.partner, 90, domain)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  handleRegionalization()
  handlePartnerParam()
})
