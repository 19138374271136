import { reactMount } from '@/lib/reactMount'
import { useId, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { HeroBlockV3 } from '@/Views/Blocks/HeroBlockV3'
import { Media } from '@/components/Media'
import { Button } from '@/components/Button'
import { ArrowLongRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import { format as formatDate, parseISO } from 'date-fns'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'

const BlogLandingPage = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.Theme || 'coral-light'

  const blogSlideShow = umbraco.TagSections.find(SlideShow => SlideShow.ContentTypeTag === 'Blog')
  const whatsNewSlideShow = umbraco.TagSections.find(SlideShow => SlideShow.ContentTypeTag === "What's New")
  const salonResourcesSlideShow = umbraco.TagSections.find(SlideShow => SlideShow.ContentTypeTag === 'Guides')
  const freeTemplatesSlideShow = umbraco.TagSections.find(SlideShow => SlideShow.ContentTypeTag === 'Tools')

  return (
    <div data-theme={theme}>
      <Header Header={umbraco.Header} />
      <HeaderHero Hero={umbraco.Header.Hero} />
      <SlideShowBlock Hash='timely-blog' Content={blogSlideShow} />
      <HeroBlockV3 umbraco={umbraco.FeaturedTagHeader} />
      <SlideShowBlock Hash='timely-blog-essential-salon-guides' Content={salonResourcesSlideShow} />
      <SlideShowBlock Hash='timely-blog-free-templates-and-tools' Content={freeTemplatesSlideShow} />
      <FeaturedArticles umbraco={umbraco} />
      <SlideShowBlock Hash='timely-blog-whats-new' Content={whatsNewSlideShow} />
    </div>
  )
}

/*
  TODO : @justin @alex - the media should use below crops
    Image Crops main media
  -------------------------
    Url1640           : HeroHorizontalTvCrop                 = 1536 x 920    @1640 ^ // Custom
    Url1024           : HeroHorizontalDesktopCrop            = 940 x 920     @1024 ^
    Url768            : HeroHorizontalTabletCrop             = 1024 x 768     @768 ^
    Url640            : HeroHorizontalMobileLargeCrop        = 768 x 576      @640 ^
    Url               : HeroHorizontalMobileCrop             = 640 x 480
*/

const Header = ({ Header }) => {
  const theme = Header.Theme || 'emerald-dark'

  /* eslint-disable operator-linebreak */
  const hasHeaderContent = false
    || Header?.Label
    || Header?.Headline
    || Header?.Summary
    || Header?.Button
    || Header?.ButtonLink
  /* eslint-enable operator-linebreak */

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <section data-theme={theme} className='pt-16 pb-9 bg-[--theme-bg] text-[--theme-text]'>
      {hasHeaderContent && (
        <motion.header
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='text-center'
        >
          <div className='container mx-auto max-w-5xl px-4 md:px-8 lg:px-10'>
            {Header.Label && (
              <p className='text-pretty mb-1 text-sm font-medium text-[--theme-subLabel]'>
                {Header.Label}
              </p>
            )}

            {Header.Headline && (
              <h1 className='mb-4 text-4xl md:text-6xl text-pretty font-serif tracking-tight'>
                {Header.Headline}
              </h1>
            )}

            {Header.Summary && (
              <div
                className='_rte text-xl text-pretty font-light'
                dangerouslySetInnerHTML={{ __html: Header.Summary }}
              />
            )}

            <div className='flex justify-center gap-3.5 flex-wrap pt-4'>
              <Button mobile='sm' href='#timely-blog'>Timely blog</Button>
              <Button mobile='sm' href='#timely-blog-essential-salon-guides'>Salon guides</Button>
              <Button mobile='sm' href='#timely-blog-free-templates-and-tools'>Templates and tools</Button>
              <Button mobile='sm' href='#timely-blog-whats-new'>What’s new?</Button>
            </div>

          </div>
        </motion.header>
      )}
    </section>
  )
}

/*
  TODO : @justin @alex - the media should use below crops
    Image Crops main media
  -------------------------
    Url1024      : AccordionDesktopCrop        = 870 x 870       @1024 ^
    Url768       : AccordionTabletCrop         = 942 x 706       @768 ^
    Url640       : AccordionMobileLargeCrop    = 680 x 510       @640 ^
    Url          : AccordionMobileCrop         = 560 x 560
*/

const HeaderHero = ({ Hero }) => {
  const theme = Hero?.Theme || 'charcoal-dark'
  const cardTheme = Hero?.CardTheme || 'coral-dark'

  const isMobile = useIsViewPortLessOrEqualTo(1024)

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionDistance
  } = animationSettings()

  return (
    <div data-theme={theme} className='bg-[--theme-bg]'>
      <div className='mx-auto max-w-screen-2xl pb-8 md:py-12 md:pb-12 px-4 md:px-6 _cta-animation-distance'>
        <motion.div
          initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }} transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          data-theme={cardTheme}
          className='text-[--theme-text] lg:min-h-[710px] relative isolate overflow-hidden rounded-3xl lg:flex lg:pt-0'
        >
          <div className='bg-[--theme-bg] relative px-6 md:px-10 py-10 mx-auto w-full lg:w-1/2 lg:max-w-[600px] lg:mx-0 justify-end lg:flex flex-col lg:py-28 xl:py-12'>
            <motion.div
              initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
              transition={{ duration: standardTransitionDuration, delay: isMobile ? standardTransitionDelay : 0.8 }} viewport={{ once: true, amount: standardViewportTrigger }}
              className='relative w-full mx-auto xl:max-w-none'
            >
              {Hero.Headline && (
                <h2 className='text-balance font-serif text-3xl tracking-tight md:text-5xl pb-5'>
                  {Hero.Headline}
                </h2>
              )}

              {Hero.Summary && (
                <div
                  className='_rte pt-6 text-pretty text-xl border-t border-[--theme-hr] font-light'
                  dangerouslySetInnerHTML={{ __html: Hero.Summary }}
                />
              )}

              {Hero.Button && (
                <div className='mt-6 flex gap-y-4 gap-x-6 xl:justify-start flex-wrap'>
                  {Hero.Button && (
                    <Button href={Hero.Button.Url} target={Hero.Button.Target}>
                      {Hero.Button.Name || 'Learn more'}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          </div>
          <div className='relative h-[360px] md:h-[550px] lg:h-auto lg:mt-0 grow'>
            {Hero.Media && <Media umbraco={Hero} />}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

// TODO : @justin @alex - These card image sizes should return the cardMobileCrop
const FeaturedArticles = ({ umbraco }) => {
  const theme = umbraco?.FeaturedArticles?.Theme || 'rose-light'
  const highlightedTrendingCount = umbraco.FeaturedArticles?.HighlightedTrendingTopics?.length
  const additionalTrendingCount = umbraco.FeaturedArticles?.AdditionalTrendingTopics?.length

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <section data-theme={theme} className='py-16 lg:py-20 px-4 md:px-0 bg-[--theme-bg] text-[--theme-text]'>
      <motion.header
        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
        transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
        className='mx-auto max-w-7xl'
      >
        <div className='mx-auto max-w-xl text-center px-2.5'>
          {umbraco.FeaturedArticles.Headline && (
            <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance md:mt-2'>
              {umbraco.FeaturedArticles.Headline}
            </h2>
          )}
        </div>
      </motion.header>
      {!!additionalTrendingCount && (
        <div className='mx-auto max-w-screen-2xl pt-6 flex flex-wrap gap-4 lg:gap-2 2xl:gap-4 justify-center md:px-6'>
          {umbraco.FeaturedArticles.HighlightedTrendingTopics.map((card, index) => (
            <Card card={card} key={index} cardIndex={index} />
          ))}
        </div>
      )}
      {!!highlightedTrendingCount && (
        <motion.div
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='mx-auto max-w-screen-2xl pt-8 flex flex-wrap gap-2 justify-center md:px-6'
        >
          {umbraco.FeaturedArticles.AdditionalTrendingTopics.map((item, index) => (
            <Button size='sm' href={item.Button.Url} target={item.Button.Target} key={index}>
              {item.Tag}
            </Button>
          ))}
        </motion.div>
      )}
    </section>
  )
}

const Card = ({ card, cardIndex }) => {
  const {
    standardViewportTrigger,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionDistance,
    standardTransitionStagger,
    standardTransitionEasing
  } = animationSettings()
  const isStackedContent = useIsViewPortLessOrEqualTo(1024)
  return (
    <motion.a
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: standardTransitionDuration, delay: isStackedContent ? 0 : (standardTransitionDelay + cardIndex * standardTransitionStagger), ease: standardTransitionEasing }} viewport={{ once: true, standardViewportTrigger }}
      href={card.Button.Url} target={card.Button.Target} className='relative isolate justify-end text-[--theme-text] h-[490px] w-full md:w-[calc((100%-16px)/2)] lg:w-[calc((100%-16px)/3)] 2xl:w-[calc((100%-32px)/3)] 2xl:max-w-[482px]'
    >
      <div className='
        [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button
        h-full flex flex-col rounded-3xl overflow-hidden absolute top-0 left-0 w-full transition-all duration-300
        border border-charcoal-200
        active:-translate-y-1 active:-translate-x-1 active:shadow-button
        '
      >
        {card.Media?.Image && (
          <div className='relative w-full grow'>
            <Media umbraco={card} isVideoPreview />
          </div>
        )}

        {card.Tag && (
          <div className='md:mx-0 py-6 px-8 flex flex-col justify-center items-center md:text-left w-full bg-white h-24'>
            <h2 className='text-balance text-xl font-medium text-center text-charcoal-800'>
              {card.Tag}
            </h2>
          </div>
        )}
      </div>
    </motion.a>
  )
}

const SlideShowBlock = ({ Hash, Content }) => {
  if (!Content?.Features?.length) return null
  Content.BlockId = useId()
  const theme = Content?.Theme || 'rose-light'

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <>
      <a id={Hash} />
      <section data-block-id={Content.BlockId} data-theme={theme} className='py-16 bg-[--theme-bg] text-[--theme-text] overflow-hidden'>
        <header className='mx-auto w-full max-w-screen-2xl px-4 md:px-8'>
          <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
            className='md:flex items-end justify-between w-full'
          >

            <div className='grow md:pr-5'>
              {Content.Label && (
                <p className='text-pretty text-base text-[--theme-link-text]'>
                  {Content.Label}
                </p>
              )}
              {Content.Headline && (
                <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance sm:text-5xl mb-1'>
                  {Content.Headline}
                </h2>
              )}
              {Content.Summary && (
                <p
                  className='text-base sm:text-balance'
                  dangerouslySetInnerHTML={{ __html: Content.Summary }}
                />
              )}
            </div>
            {Content.Button && (
              <Button href={Content.Button.Url} target={Content.Button.Target} variant='link'>
                <span>{Content.Button.Name}</span>
                <ArrowLongRightIcon aria-hidden='true' className='size-5 ml-2' />
              </Button>
            )}
          </motion.div>
        </header>

        <div className='relative pt-6 sm:pt-10'>
          <div className='mx-auto max-w-screen-2xl px-4 md:px-8'>
            <SlideShow Content={Content} />
          </div>
        </div>
      </section>
    </>
  )
}

const SlideShow = ({ Content }) => {
  if (!Content?.Features?.length) return null
  const [initialLoad, setInitialLoad] = useState(true)

  const onResize = (swiper) => {
    window.requestAnimationFrame(() => {
      const block = document.querySelector(`[data-block-id='${Content.BlockId}']`)
      if (block.querySelector('[data-footer-navigation]')) {
        block.querySelector('[data-footer-navigation]').hidden = swiper.isLocked
      }
    })
  }

  return (
    <div className='relative'>
      <Swiper
        className='w-full overflow-visible'
        data-style='carousel'
        data-swiper={'BlogLandingPage' + Content.BlockId}
        grabCursor
        modules={[Pagination, Navigation]}
        onResize={onResize}
        pagination={{ type: 'progressbar' }}
        slidesPerView='auto'
        spaceBetween={24}
        onInit={(swiper) => {
          onResize(swiper)
          if (swiper.slides.length <= 1) return
          const block = document.querySelector(`[data-block-id='${Content.BlockId}']`)

          const mobilePrevEl = block.querySelector("[data-footer-navigation] [data-navigation='prev']")
          const mobileNextEl = block.querySelector("[data-footer-navigation] [data-navigation='next']")

          mobilePrevEl.addEventListener('click', () => { swiper.slidePrev() })
          mobileNextEl.addEventListener('click', () => { swiper.slideNext() })
        }}
        onSlideChange={() => setInitialLoad(false)}
        onProgress={(swiper, progress) => {
          window.requestAnimationFrame(() => {
            const block = swiper.el.parentNode
            const prevButton = block.querySelector("[data-navigation='prev']")
            const nextButton = block.querySelector("[data-navigation='next']")
            if (prevButton) { prevButton.parentNode.hidden = progress <= 0 }
            if (nextButton) { nextButton.parentNode.hidden = progress >= 1 }
          })
        }}
      >
        {Content.Features.map((Card, index) => (
          <SwiperSlide key={index} className='w-full sm:w-[469px] h-auto flex items-end'>
            <SlideShowCard card={Card} cardIndex={index} initialLoad={initialLoad} />
          </SwiperSlide>
        ))}
      </Swiper>
      {Content.Features.length >= 2 && (
        // Leave the below theme as default for all not block inheritances
        <div data-footer-navigation data-theme='charcoal-dark' className='max-md:flex max-md:gap-6 max-md:mx-auto max-md:justify-center mt-4'>
          <div className='md:absolute z-10 md:top-1/2 md:-left-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='prev'
              className=''
            >
              <span className='sr-only'>Previous slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5' />
            </button>
          </div>
          <div className='md:absolute z-10 md:top-1/2 md:-right-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='next'
              className=''
            >
              <span className='sr-only'>Next slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5 rotate-180' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const SlideShowCard = ({ card, cardIndex, initialLoad = true }) => {
  const {
    standardTransitionDistance,
    standardTransitionDuration,
    standardTransitionDelay,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionStagger
  } = animationSettings()

  return (
    <motion.a
      href={card.Button.Url} target={card.Button.Target}
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: initialLoad ? standardTransitionDuration : 0, delay: initialLoad ? (standardTransitionDelay + cardIndex * standardTransitionStagger) : 0, ease: standardTransitionEasing }} viewport={{ once: true, amount: standardViewportTrigger }}
      className='group relative isolate justify-end h-[500px] text-charcoal-800 w-full'
    >
      <div className='
        [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button
        h-full flex flex-col rounded-3xl overflow-hidden absolute top-0 left-0 w-full transition-all duration-300
        border border-charcoal-200
        active:-translate-y-1 active:-translate-x-1 active:shadow-button
        '
      >
        <div className='relative w-full grow'>
          <div className='absolute inset-0 flex justify-center items-center'>
            {/* TODO : @justin @alex - These card image sizes should return the CardDesktopCrop */}
            {card.Media && (
              <Media umbraco={card} isVideoPreview />
            )}
          </div>
        </div>
        <div className='relative md:mx-0 p-8 flex flex-col justify-start md:text-left w-full bg-white h-48 overflow-hidden after:content-[""] after:h-10 after:block after:absolute after:left-0 after:right-0 after:bottom-0 after:bg-gradient-to-t after:from-white after:via-white/95 after:via-20% after:to-white/0'>
          {!!card?.PublishedDate && !!card.Tags?.length && (
            <div className='text-base flex items-center pb-2'>
              <span className='max-w-[calc(100%-96px)] block truncate font-medium text-midnight-500 whitespace-nowrap'>
                {card.Tags[0]}
              </span>
              {!!card?.PublishedDate && (
                <time className='shrink-0 grow pl-2.5 before:content-[""] before:inline-block before:bg-charcoal-800 before:size-1 before:mr-2.5 before:mt-1 inline-flex items-center' dateTime={card.PublishedDate}>{formatDate(parseISO(card.PublishedDate), 'MMM dd, yyyy')}</time>
              )}
            </div>
          )}

          {card.Headline && (
            <h2 className='text-balance text-xl font-medium mb-2.5'>
              {card.Headline}
            </h2>
          )}

          {card.Summary && (
            <div
              className='text-pretty text-sm'
              dangerouslySetInnerHTML={{ __html: card.Summary }}
            />
          )}
        </div>
      </div>
    </motion.a>
  )
}

reactMount('BlogLandingPage', BlogLandingPage)
