import { reactMount } from '@/lib/reactMount'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useRef, useState } from 'react'

const FAQBlock = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco.Theme || 'coral-light'

  const [openIndex, setOpenIndex] = useState(null)
  const questionRefs = useRef([])

  const maybeScrollToQuestion = (element) => {
    const headerHeight = 64
    const topPosition = element.getBoundingClientRect().top + window.scrollY - headerHeight
    const elementInView = element.getBoundingClientRect().top >= headerHeight &&
                          element.getBoundingClientRect().bottom <= window.innerHeight
    if (!elementInView) {
      window.scrollTo({ top: topPosition, behavior: 'smooth' })
    }
  }

  const handleQuestionClick = (index) => {
    const isOpening = openIndex !== index
    setOpenIndex(isOpening ? index : null)
    if (isOpening && questionRefs.current[index]) {
      setTimeout(() => {
        maybeScrollToQuestion(questionRefs.current[index]?.querySelector('dt'))
      }, 400) // 400ms as the other animation events
    }
  }

  return (
    <section id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-[--theme-bg] text-[--theme-text] px-4 md:px-8 py-16 md:py-32 lg:px-10'>
      <header className='mx-auto w-full max-w-5xl xl:px-6'>
        {umbraco.Headline && (
          <h2 className='text-4xl font-serif tracking-tight md:text-5xl'>
            {umbraco.Headline}
          </h2>
        )}
      </header>
      <div className='mx-auto w-full mt-10 max-w-5xl xl:px-6'>
        <hr className='border-[--theme-hr]' />
        <div className='divide-y divide-[--theme-hr]'>
          {umbraco.Faqs.filter(faq => faq.Question && faq.Answer).map((faq, index) => {
            const isOpen = openIndex === index
            return (
              <dl key={faq.Question} className='mt-0 pt-1.5' ref={el => { questionRefs.current[index] = el }}>
                <dt>
                  <button
                    className='group pt-[14px] pb-[18px] flex w-full items-start justify-between text-left'
                    onClick={() => handleQuestionClick(index)}
                  >
                    <span className='text-base font-semibold'>{faq.Question}</span>
                    <span className='relative ml-6 flex h-7 items-center'>
                      <PlusIcon aria-hidden='true' className={`ease-out duration-400 size-5 _icon-stroke ${isOpen ? 'opacity-0' : 'opacity-100'} text-[--theme-action]`} />
                      <MinusIcon aria-hidden='true' className={`ease-in duration-400 size-5 _icon-stroke ${isOpen ? 'opacity-100' : 'opacity-0'} absolute`} />
                    </span>
                  </button>
                </dt>
                <dd className={`grid ease-out duration-400 ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
                  {/* this div.overflow-hidden is used for the transition effect */}
                  <div className={`overflow-hidden ease-in duration-400 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
                    <div
                      className='_rte text-base font-light pr-12 pb-6'
                      dangerouslySetInnerHTML={{ __html: faq.Answer }}
                    />
                  </div>
                </dd>
              </dl>
            )
          })}
        </div>
      </div>
    </section>
  )
}

reactMount('FAQBlock', FAQBlock)
