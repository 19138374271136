/*
  Image Crops main media
  -------------------------
  Url1280         : HeroHorizontalTvCrop             @1024 ^
  Url768          : HeroHorizontalTabletCrop          @768 ^
  Url             : IconLargeCrop
*/

import { reactMount } from '@/lib/reactMount'
import { useRef } from 'react'
import { Button } from '@/components/Button'
import { Media } from '@/components/Media'

const LoginPage = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.Theme || 'charcoal-dark'

  return (
    <section data-theme={theme} className='flex flex-col h-dvh bg-[--theme-bg] text-[--theme-text] overflow-auto'>
      <header className='order-2 shrink-0 w-full flex max-w-3xl px-4 lg:px-6 xl:px-10 2xl:px-12 mx-auto justify-center text-center flex-col pb-16 xl:pb-20'>
        {umbraco.Summary && (
          <div
            className='font-serif text-5xl text-pretty'
            dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
          />
        )}

        {(umbraco.Button?.Url || umbraco.ButtonLink?.Url) && (
          <div className='mt-8 flex items-center justify-center gap-y-4 gap-x-8 flex-wrap w-full'>
            {umbraco.Button?.Url && (
              <Button href={umbraco.Button.Url} target="_top">
                <span>{umbraco.Button.Name}</span>
              </Button>
            )}

            {umbraco.ButtonLink?.Url && (
              <Button href={umbraco.ButtonLink.Url} target="_top" variant='link'>
                <span>{umbraco.ButtonLink.Name}</span>
              </Button>
            )}
          </div>
        )}
      </header>
      <div className='grow order-1 w-full flex max-w-screen-3xl px-10 lg:px-16 mx-auto py-10 lg:py-16'>
        <figure className='relative h-full w-full block rounded-3xl overflow-hidden min-h-48'>
          {umbraco.Media && <Media umbraco={umbraco} />}
        </figure>
      </div>
    </section>
  )
}

reactMount('LoginPage', LoginPage)
