/* Content section V4 aka Typography content */

import { reactMount } from '@/lib/reactMount'
import { useRef } from 'react'
import { Button } from '@/components/Button'
import { motion } from 'motion/react'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'
import { animationSettings } from '@/animationSettings'

const ContentSectionV4 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current

  const theme = umbraco?.Theme || 'charcoal-light'
  const cardCount = umbraco?.Cards?.length

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDistance,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionStagger
  } = animationSettings()

  // by design the min number of cards is 2 and the max is 4
  let gridCols = 'md:grid-cols-2'
  let stackedContentWidth = 768
  if (cardCount === 3) {
    gridCols = 'lg:grid-cols-3'
    stackedContentWidth = 1024
  }
  if (cardCount === 4) {
    gridCols = 'md:grid-cols-2 xl:grid-cols-4'
    stackedContentWidth = 1280
  }

  /* eslint-disable operator-linebreak */
  const hasHeaderContent = false
    || umbraco?.Label
    || umbraco?.Headline
    || umbraco?.Summary
    || umbraco?.Button
    || umbraco?.ButtonLink
  /* eslint-enable operator-linebreak */

  const isStackedContent = useIsViewPortLessOrEqualTo(stackedContentWidth)

  return (
    <section id={umbraco?.AnchorTarget || null} data-theme={theme} className='py-20 bg-[--theme-bg] text-[--theme-text]'>
      {hasHeaderContent && (
        <header className='text-center'>
          <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
            className='container mx-auto max-w-5xl px-4 md:px-8 lg:px-10'

          >
            {umbraco?.Label && (
              <p className='text-pretty mb-1 text-sm font-medium text-[--theme-subLabel]'>
                {umbraco.Label}
              </p>
            )}

            {umbraco.Headline && (
              <h2 className='mb-4 text-4xl md:text-5xl text-pretty font-serif tracking-tight'>
                {umbraco.Headline}
              </h2>
            )}

            {umbraco.Summary && (
              <div
                className='_rte text-xl text-pretty font-light'
                dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
              />
            )}

            {(umbraco.Button || umbraco.ButtonLink) && (
              <div className='mt-8 flex items-center justify-center gap-y-4 gap-x-8 flex-wrap'>
                {umbraco.Button && (
                  <Button href={umbraco.Button.Url} target={umbraco.Button.Target}>
                    {umbraco.Button.Name}
                  </Button>
                )}

                {umbraco.ButtonLink && (
                  <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                    {umbraco.ButtonLink.Name}
                  </Button>
                )}
              </div>
            )}
          </motion.div>
        </header>
      )}

      {cardCount > 0 && (
        <div className='container mx-auto px-4 md:px-8 max-w-screen-2xl'>
          <hr className='border-[--theme-hr] mt-8 mb-6' />

          <div className={`grid gap-5 ${gridCols}`}>
            {umbraco?.Cards.map((Card, index) => (
              <article key={index}>
                <motion.div
                  initial={{ translateY: standardTransitionDistance, opacity: 0 }}
                  whileInView={{ translateY: '0', opacity: 1 }}
                  transition={{ duration: standardTransitionDuration, delay: isStackedContent ? standardTransitionDelay : (standardTransitionDelay + index * standardTransitionStagger), ease: standardTransitionEasing }}
                  viewport={{ once: true, amount: standardViewportTrigger }}
                >
                  {Card?.Headline && (
                    <h2 className='text-3xl mb-2.5 text-pretty font-serif tracking-tight'>
                      {Card.Headline}
                    </h2>
                  )}

                  {Card?.Summary && (
                    <p
                      className='_rte text-base text-pretty font-light'
                      dangerouslySetInnerHTML={{ __html: Card.Summary }}
                    />
                  )}

                  {Card?.Button && (
                    <div className='mt-5'>
                      <Button href={Card.Button.Url} target={Card.Button.Target}>
                        {Card.Button.Name}
                      </Button>
                    </div>
                  )}
                </motion.div>
              </article>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

reactMount('ContentSectionV4', ContentSectionV4)
