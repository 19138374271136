import { reactMount } from '@/lib/reactMount'
import { useEffect, useRef } from 'react'
import { Breadcrumbs } from '@/components/Breadcrumbs'

const BlocksOnly = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.BreadcrumbsTheme || 'coral-light'

  useEffect(() => {
    if (umbraco?.ReferralId) {
      window.sessionStorage.setItem('referralId', umbraco?.ReferralId)
    } else {
      window.sessionStorage.removeItem('referralId')
    }
  }, [umbraco])

  return (
    <>
      {!umbraco?.BreadcrumbsDisabled && !!umbraco.Breadcrumbs.length && (<Breadcrumbs Theme={theme} Breadcrumbs={umbraco.Breadcrumbs} />)}
    </>
  )
}

reactMount('BlocksOnly', BlocksOnly)
