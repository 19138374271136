import { reactMount } from '@/lib/reactMount'
import { Fragment, useRef } from 'react'
import { SocialIconFacebook, SocialIconInstagram, SocialIconTikTok, SocialIconYoutube } from '@/components/icons/CustomIcons'
import { Logo, LogoIcon } from '@/components/icons/logo'

// Layouts as per umbraco
const LAYOUTS = {
  Heading: 'Heading',
  Link: 'Link',
  HeadingLink: 'Heading Link'
}

const Footer = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current

  const socialHeading = umbraco.SocialLinks.find((item) => item.Layout === LAYOUTS.Heading)

  return (
    <footer data-theme='charcoal-dark' className='bg-[--theme-bg] text-[--theme-text] [&_a]:text-[--theme-text] [&_a:hover]:text-[--theme-button-1-bg]'>
      <div className='mx-auto max-w-screen-2xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
        <div className='lg:flex lg:gap-8'>
          <div className='flex flex-col items-start gap-5 lg:w-[304px] pb-8 pr-10 border-b border-charcoal-600 lg:border-0'>
            <a href='/'>
              <Logo />
            </a>
            {socialHeading?.Heading && (
              <p className='opacity-80 text-balance text-base'>
                {socialHeading.Heading}
              </p>
            )}
            <div className='flex gap-x-3'>
              {umbraco.SocialLinks.map((item, index) => {
                if (item.Layout !== LAYOUTS.Link) return null
                const Icon = getSocialIcon(item.Link.Url)
                return (
                  <Link key={index} {...item.Link}>
                    <span className='sr-only'>{item.Link.Name}</span>
                    {Icon && <Icon aria-hidden='true' className='size-6' />}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className='grow mt-10 grid grid-cols-2 gap-8 lg:grid-cols-4 lg:mt-0'>
            <Column items={umbraco.Column1} />
            <Column items={umbraco.Column2} />
            <Column items={umbraco.Column3} />
            <Column items={umbraco.Column4} />
          </div>
        </div>
        <div className='mt-8 border-t border-charcoal-600 pt-8 lg:mt-10' role='contentinfo'>
          <div className='flex flex-col lg:flex-row justify-between max-md:flex-wrap lg:justify-between gap-x-4 gap-y-6'>
            <ul className='flex items-center md:justify-center lg:justify-start text-sm grow gap-x-8 gap-y-3 flex-wrap'>
              {umbraco.LegalLinks.map((item, index) => {
                if (item.Layout === LAYOUTS.Heading && item.Heading) {
                  return (
                    <li key={index} className='pr-2'>
                      {item.Heading}
                    </li>
                  )
                }

                if (item.Layout === LAYOUTS.Link && item.Link) {
                  return (
                    <li key={index}>
                      <Link {...item.Link} />
                    </li>
                  )
                }

                return null
              })}
            </ul>

            <div className='flex md:justify-center w-full lg:w-auto lg:justify-end shrink-0'>
              <p className='flex gap-1 items-center text-base'>
                <LogoIcon className='mr-3 space-x-2 relative -bottom-0.5' />
                {umbraco.Apps.length && (
                  <span>
                    {umbraco.Apps.map((item, index) => {
                      if (item.Layout === LAYOUTS.Heading && item.Heading) {
                        return <Fragment key={index}> {item.Heading} </Fragment>
                      }

                      if (item.Layout === LAYOUTS.Link && item.Link) {
                        return <Link key={index} {...item.Link} className='text-nowrap' />
                      }

                      return null
                    })}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const Column = ({ items }) => {
  return (
    <ul className='space-y-4'>
      {items.map((item, index) => {
        if (item.Layout === LAYOUTS.Heading && item.Heading) {
          return (
            <li key={index} className='[&:not(:first-child)>*]:mt-10'>
              <h3 className='opacity-80 text-xl font-medium'>{item.Heading}</h3>
            </li>
          )
        }

        if (item.Layout === LAYOUTS.HeadingLink && item.Link) {
          return (
            <li key={index} className='[&:not(:first-child)>*]:mt-10'>
              <h3 className='opacity-80 hover:opacity-100 text-xl font-medium'>
                <Link {...item.Link} />
              </h3>
            </li>
          )
        }

        if (item.Layout === LAYOUTS.Link && item.Link) {
          return (
            <li key={index}>
              <Link {...item.Link} className='text-base' />
            </li>
          )
        }

        return null
      })}
    </ul>
  )
}

const Link = ({ children, className, Name, Target, Url, Rel }) => {
  if (!children && !Name) return null

  return (
    <a
      className={className}
      href={Url}
      target={Target}
      rel={Rel || Target === '_blank' ? 'noreferrer' : Target}
    >
      {children || Name}
    </a>
  )
}

const getSocialIcon = (url) => {
  if (url.includes('facebook')) return SocialIconFacebook
  if (url.includes('youtube')) return SocialIconYoutube
  if (url.includes('instagram')) return SocialIconInstagram
  if (url.includes('tiktok')) return SocialIconTikTok
  return null
}

reactMount('Footer', Footer)
