'use client'

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from '@/components/Button'

export const VideoModal = (props) => {
  const theme = props?.theme || 'charcoal-light'

  const {
    setIsOpen,
    src,
    title = null
  } = props

  if (!setIsOpen) throw new Error('setIsOpen is required')
  if (!src) throw new Error('src is required')

  return (
    <Dialog open onClose={setIsOpen} className='relative z-30' data-video-modal>
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-black/30 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
      />

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center items-center'>
          <DialogPanel
            transition
            className='relative transform transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-7xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
          >
            <div className='relative overflow-hidden rounded-lg max-w-[150vh] mx-auto aspect-video shadow-xl'>
              <div className='z-10 absolute top-4 left-4'>
                <Button
                  theme={theme}
                  size='icon'
                  onClick={() => setIsOpen(false)}
                >
                  <span className='sr-only'>Close video modal</span>
                  <XMarkIcon aria-hidden='true' className='size-5' />
                </Button>
              </div>
              <iframe className='size-full absolute bg-charcoal-800 object-cover inset-0' title={title} src={src} width='640' height='auto' frameBorder='0' allowFullScreen />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
