/* Personalisation block V1a aka Personalisation block */

import { reactMount } from '@/lib/reactMount'
import { useEffect, useRef } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, UserIcon, SparklesIcon, RectangleGroupIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Button } from '@/components/Button'
import { useSessionStorage } from 'react-use'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const goalOptionsPaths = {
  'Make more money': 'make-more-money',
  'Take control of my admin': 'take-control',
  'Grow my business': 'grow-my-business',
  'Look and feel professional': 'look-and-feel-professional',
  'Get more bookings': 'get-more-bookings',
  'Create an amazing client experience': 'client-experience'
}
const goalOptions = Object.keys(goalOptionsPaths)

const currentSystemOptionsPaths = {
  'Pen and paper': '3',
  'New business': '1',
  'Another software': '2'
}

const currentSystemOptions = Object.keys(currentSystemOptionsPaths)

// Staff count is String in this component
const setStaffCountOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20+']

const PersonalisationBlockV1a = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco.Theme || 'midnight-light'
  const cardTheme = umbraco.Theme || 'midnight-dark'

  const [goal, setGoal] = useSessionStorage('goal', '')
  const [currentSystem, setCurrentSystem] = useSessionStorage('currentSystem', '')
  const [staffCount, setStaffCount] = useSessionStorage('staffCount', '')

  useEffect(() => {
    // Set staff count to a string if it's a number
    let staffCountNumber = parseInt(staffCount)

    if (isNaN(staffCountNumber) || staffCountNumber <= 0) {
      staffCountNumber = '1'
    } else if (staffCountNumber >= 20) {
      staffCountNumber = '20+'
    } else {
      staffCountNumber = '' + staffCount
    }

    setStaffCount(staffCountNumber)
  }, [])

  const isDisabled = !goal || !currentSystem

  const handleOnClick = () => {
    const goalPath = goalOptionsPaths[goal]
    const currentSystemPath = currentSystemOptionsPaths[currentSystem]

    window.location.href = '/' + currentSystemPath + '-' + goalPath + '/'
  }

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionDistance
  } = animationSettings()

  return (
    <div id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-rose-100 text-[--theme-text] py-16 px-4 sm:px-10'>
      <motion.div
        initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
        transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
        data-theme={cardTheme} className='text-center mx-auto max-w-lg lg:max-w-7xl rounded-3xl bg-[--theme-bg] text-[--theme-text] py-10 pt-12 px-6 md:px-10 lg:px-20'
      >
        <div className='lg:max-w-[962px] mx-auto'>
          <div className='px-4'>
            <h2 className='text-3xl md:text-4xl tracking-tight font-serif mb-2 text-pretty'>Tell us about you and your business</h2>
            <p className='text-base mb-3'>Find out how Timely can work for you</p>
          </div>
          <div className='lg:grid grid-cols-3 text-left pt-5 pb-2 lg:py-5 gap-y-4 -mx-4 lg:mb-1'>
            <div className='pb-6 lg:pb-5 px-4 lg:border-r lg:border-midnight-500'>
              <Select label='My goal is' selected={goal} setSelected={setGoal} placeholder='e.g To make more money' options={goalOptions} icon={SparklesIcon} />
            </div>
            <div className='pb-6 lg:pb-5 px-4 lg:border-r lg:border-midnight-500'>
              <Select label='My current system is' selected={currentSystem} setSelected={setCurrentSystem} placeholder='e.g Pen and paper' options={currentSystemOptions} icon={RectangleGroupIcon} />
            </div>
            <div className='pb-6 lg:pb-5 px-4'>
              <Select label='My staff amount is' selected={staffCount} setSelected={setStaffCount} placeholder='e.g 3 staff' options={setStaffCountOptions} icon={UserIcon} />
            </div>
          </div>
          <Button disabled={isDisabled} onClick={handleOnClick} mobile='block'>Let's get started</Button>
        </div>
      </motion.div>
    </div>
  )
}

const Select = ({ label, selected, setSelected, placeholder, options, icon: Icon }) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <Label className='block text-base font-medium'>{label}</Label>
      <div className='relative mt-1'>
        <ListboxButton className={`cursor-pointer grid w-full cursor-default grid-cols-1 rounded-md bg-white py-4 pl-3 pr-2 text-left ${selected ? 'text-charcoal-800' : 'text-charcoal-400'} outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-rose-500 sm:text-sm`}>
          <span className='col-start-1 row-start-1 flex items-center gap-3 pr-6'>
            {Icon && <Icon className='h-5 w-5 text-midnight-200' aria-hidden='true' />}
            <span className='block truncate pb-0.5'>{selected || placeholder}</span>
          </span>
          <ChevronDownIcon
            aria-hidden='true'
            className='col-start-1 row-start-1 size-[18px] stroke-[2.5] self-center justify-self-end text-charcoal-800'
          />
        </ListboxButton>

        <ListboxOptions
          transition
          className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm'
        >
          {options.map((option, index) => (
            <ListboxOption
              key={index}
              value={option}
              className='group cursor-pointer relative cursor-default select-none py-2 pl-3 pr-9 text-charcoal-800 data-[focus]:bg-charcoal-50 data-[focus]:outline-none'
            >
              <div className='flex items-center'>
                <span className='ml-3 block truncate font-normal group-data-[selected]:font-medium'>
                  {option}
                </span>
              </div>

              <span className='absolute inset-y-0 right-0 flex items-center pr-4 group-[&:not([data-selected])]:hidden'>
                <CheckIcon aria-hidden='true' className='size-5' />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  )
}

reactMount('PersonalisationBlockV1a', PersonalisationBlockV1a)
