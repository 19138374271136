/* Content section V6 aka Image strip */

import { useRef } from 'react'
import { reactMount } from '@/lib/reactMount'
import { RegionISO } from '../../lib/pricingMatrix/Region'

const ContentSectionV6 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current

  const theme = umbraco?.Theme || 'charcoal-light'

  const regionISO = window.localStorage.getItem('regionIso')
  const isGB = regionISO === RegionISO.GREAT_BRITAIN

  let logosTheme = 'light'
  if (theme?.includes('light')) logosTheme = 'dark'

  return (
    <div id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-[--theme-bg] py-8 lg:py-16'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <h2 className='text-center text-base font-medium text-[--theme-text]'>
          Timely. Trusted by salons worldwide.
        </h2>

        {!!isGB && logosTheme === 'dark' && (
          <div className='mx-auto mt-3 flex flex-wrap max-w-lg justify-center items-center gap-y-5 sm:max-w-xl lg:mx-0 lg:max-w-none'>
            <img
              alt='AHC'
              src='/static/image/AHC - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Mamawest'
              src='/static/image/Mamawest - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='ABIC'
              src='/static/image/ABIC - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='HUD'
              src='/static/image/HUD - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Jin Soon'
              src='/static/image/JINSOON - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain sm:col-start-2'
            />
            <img
              alt='Natalie Anne'
              src='/static/image/Natalie Anne - Charcoal.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
          </div>
        )}

        {!!isGB && logosTheme === 'light' && (
          <div className='mx-auto mt-3 flex flex-wrap max-w-lg justify-center items-center gap-y-5 sm:max-w-xl lg:mx-0 lg:max-w-none'>
            <img
              alt='AHC'
              src='/static/image/S - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Mamawest'
              src='/static/image/TLBC - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='ABIC'
              src='/static/image/JWC - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='HUD'
              src='/static/image/BBT - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Jin Soon'
              src='/static/image/BBC - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain sm:col-start-2'
            />
            <img
              alt='Natalie Anne'
              src='/static/image/AJ - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
          </div>
        )}

        {!isGB && logosTheme === 'dark' && (
          <div className='mx-auto mt-3 flex flex-wrap max-w-lg justify-center items-center gap-y-5 sm:max-w-xl lg:mx-0 lg:max-w-none'>
            <img
              alt='AHC'
              src='/static/image/S - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Mamawest'
              src='/static/image/TLBC - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='ABIC'
              src='/static/image/JWC - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='HUD'
              src='/static/image/BBT - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Jin Soon'
              src='/static/image/BBC - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain sm:col-start-2'
            />
            <img
              alt='Natalie Anne'
              src='/static/image/AJ - CharcoaL.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
          </div>
        )}

        {!isGB && logosTheme === 'light' && (
          <div className='mx-auto mt-3 flex flex-wrap max-w-lg justify-center items-center gap-y-5 sm:max-w-xl lg:mx-0 lg:max-w-none'>
            <img
              alt='AHC'
              src='/static/image/AHC - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Mamawest'
              src='/static/image/Mamawest - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='ABIC'
              src='/static/image/ABIC - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='HUD'
              src='/static/image/HUD - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
            <img
              alt='Jin Soon'
              src='/static/image/JINSOON - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain sm:col-start-2'
            />
            <img
              alt='Natalie Anne'
              src='/static/image/Natalie Anne - White.png'
              height={80}
              className='w-36 md:md:w-48 object-contain'
            />
          </div>
        )}
      </div>
    </div>
  )
}

reactMount('ContentSectionV6', ContentSectionV6)
