/*
  Card section V5 aka Flip cards

  Image Crops main media if 1 items
  -------------------------

  Url1024           : CardTvCrop                 = 1280 x 480    @1024 ^
  Url768            : CardTabletLargeCrop        = 940 x 450     @768 ^
  Url640            : CardMobileLargeCrop        = 700 x 450     @640 ^
  Url               : CardMobileCrop             = 575 x 450

  Image Crops main media if 2 items
  -------------------------
  Url1024           : CardDesktopCrop            = 640 x 450     @1024 ^

  Url768            : CardTabletCrop             = 475 x 450     @768 ^
  Url640            : CardMobileLargeCrop        = 700 x 450     @640 ^
  Url               : CardMobileCrop             = 575 x 450

  Image Crops main media if 3 items
  -------------------------
  Url1024           : CardTabletCrop             = 475 x 450     @1024 ^
  Url768            : CardTabletLargeCrop        = 940 x 450     @768 ^
  Url640            : CardMobileLargeCrop        = 700 x 450     @640 ^
  Url               : CardMobileCrop             = 575 x 450
*/

import { reactMount } from '@/lib/reactMount'
import { useRef, useState } from 'react'
import { ButtonExpand } from '@/components/ButtonExpand'
import { Media } from '@/components/Media'
import { motion } from 'motion/react'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'
import { animationSettings } from '@/animationSettings'

const CardSectionV5 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.Theme || 'rose-light'
  const cardTheme = umbraco?.CardTheme || 'emerald-dark'
  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  // Add classes for grid columns based on number of cards
  const cardCount = umbraco?.Cards?.length
  let gridCols = ''
  if (cardCount === 2) { gridCols = 'md:grid-cols-2' }
  if (cardCount === 3) { gridCols = 'lg:grid-cols-3' }

  return (
    <section id={umbraco?.AnchorTarget || null} data-theme={theme} className='py-16 px-6 md:px-8 bg-[--theme-bg] text-[--theme-text]'>
      <header className='mx-auto max-w-7xl pb-10'>
        <motion.div
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='mx-auto max-w-xl text-center px-2.5'
        >
          {umbraco.Headline && (
            <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance md:mt-2 sm:text-5xl'>
              {umbraco.Headline}
            </h2>
          )}

          {umbraco.Summary && (
            <p
              className='_rte mt-2 md:mt-4 text-pretty text-base sm:text-xl font-light'
              dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
            />
          )}
        </motion.div>
      </header>
      <div data-theme={cardTheme} className={`mx-auto max-w-screen-xl pt-6 grid gap-5 ${gridCols}`}>
        {umbraco.Cards.map((card, index) => (
          <Card card={card} key={index} cardIndex={index} />
        ))}
      </div>
    </section>
  )
}

const Card = ({ card, cardIndex }) => {
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const isStackedContent = useIsViewPortLessOrEqualTo(1024)
  card.Media ||= { Image: card.Image }
  const {
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDistance,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionStagger
  } = animationSettings()
  return (
    <motion.div
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: standardTransitionDuration, delay: isStackedContent ? 0 : (standardTransitionDelay + cardIndex * standardTransitionStagger), ease: standardTransitionEasing }} viewport={{ once: true, standardViewportTrigger }}
      className='flex'
      style={{ height: '500px !important' }}
    >
      <div className={`${isCardFlipped ? '_card-flip' : ''} _card relative isolate justify-end text-[--theme-text] w-full max-md:h-[520px] h-[620px] [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 active:-translate-x-1 active:-translate-y-1 will-change-transform transition-all duration-300`}>
        {/* content for front */}
        <div
          className='
          _card-front group/link cursor-pointer
          shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button active:shadow-button
          flex flex-col h-full rounded-3xl overflow-hidden absolute top-0 left-0 w-full'
          onClick={() => setIsCardFlipped(!isCardFlipped)}
        >
          <div className='bg-[--theme-bg] md:mx-0 p-8 flex flex-col justify-end md:text-left w-full'>
            {card.Label && (
              <p className='text-pretty text-base text-[--theme-subLabel] mb-1'>
                {card.Label}
              </p>
            )}

            {card.Headline && (
              <h2 className='text-balance text-3xl font-serif tracking-tight mb-3.5'>
                {card.Headline}
              </h2>
            )}

            {card.Summary && (
              <p
                className='text-pretty text-base'
                dangerouslySetInnerHTML={{ __html: card.Summary }}
              />
            )}
          </div>

          <div className='relative w-full grow'>
            {card.Media.Image && (
              <Media umbraco={card} isVideoPreview />
            )}
            <div className='absolute right-6 bottom-6 flex justify-end'>
              <ButtonExpand
                icon='+'
                onClick={() => setIsCardFlipped(!isCardFlipped)}
              >
                Learn more
              </ButtonExpand>
            </div>
          </div>
        </div>
        {/* content for back */}
        <div className='
          _card-back bg-[--theme-bg] h-full flex flex-col rounded-3xl overflow-hidden absolute top-0 left-0 w-full
          shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button active:shadow-button
        '
        >
          <div className='md:mx-0 p-8 pr-4 flex flex-col md:text-left w-full h-full'>
            <div className='
            h-[calc(100%-40px)] overflow-y-auto
            [&::-webkit-scrollbar]:w-1
            [&::-webkit-scrollbar-track]:bg-[--theme-bg]
            [&::-webkit-scrollbar-thumb]:bg-[--theme-hr-subtle]
            [&::-webkit-scrollbar-thumb]:rounded-full
          '
            >
              <div className='pr-4'>
                {card.Details && (
                  <div
                    className='_rte font-light'
                    dangerouslySetInnerHTML={{ __html: card.Details }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='absolute right-6 bottom-6 flex justify-end'>
            <ButtonExpand
              icon='x'
              onClick={() => setIsCardFlipped(!isCardFlipped)}
            >
              Close
            </ButtonExpand>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

reactMount('CardSectionV5', CardSectionV5)
