const variants = {
  defaultClassName: `
    shrink-0 pt-1 pb-2 md:pt-[7px] md:pb-[11px] px-4 md:px-4 text-base select-none relative translate-z-0 backface-hidden transition duration-200 inline-flex justify-center items-center
    before:absolute before:content-[""] before:w-full before:transition-all before:duration-200
    [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-x-0 focus:translate-y-0 focus:translate-x-0
    [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-[--theme-button-1-hover-bg] [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-[--theme-button-1-hover-text] rounded-full
    focus:bg-[--theme-button-1-active-bg] focus:before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:active:before:opacity-0
    before:h-full before:block before:rounded-full before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:hover:before:opacity-100 before:shadow-button
    disabled:bg-[--theme-button-1-disabled-bg] disabled:text-white/50 disabled:pointer-events-none
    [&.disabled]:bg-[--theme-button-1-disabled-bg] [&.disabled]:text-[--theme-button-1-disabled-text] [&.disabled]:pointer-events-none
    active:-translate-x-1 active:-translate-x-1 active:bg-[--theme-button-1-hover-bg] active:text-[--theme-button-1-hover-text] active:before:opacity-100
  `,
  state: {
    default: `
      bg-[--theme-tag-inactive-bg] text-[--theme-tag-inactive-text]
    `,
    active: 'bg-[--theme-button-1-bg] text-[--theme-button-1-text] pointer-events-none'
  }
}

export const ButtonTag = (props) => {
  const theme = props?.theme || null

  const {
    children,
    href = null,
    target = null,
    disabled = null,
    type = 'button',
    state = 'default',
    onClick
  } = props

  const TagName = href ? 'a' : 'button'

  const linkProps = { href, target }
  const buttonProps = { type }

  const className = `${props?.className || ''} ${variants.defaultClassName} ${variants.state[state]}`

  return (
    <TagName
      {...(href ? linkProps : buttonProps)}
      onClick={onClick}
      className={className}
      data-theme={theme}
      disabled={disabled}
    >
      {children}
    </TagName>
  )
}
