/*
  Card section V1 aka Carousel cards

  Image Crops main media if 2 items
  -------------------------
  Url1024         : CardDesktopCrop            = 640 x 450    @1024 ^
  Url768          : CardTabletCrop             = 475 x 450    @768 ^
  Url640          : CardMobileLargeCrop        = 700 x 450    @640 ^
  Url             : CardMobileCrop             = 575 x 450

  Image Crops main media if 3+ items
  -------------------------
  Url640          : CardCarouselCrop           = 420 x 450     @640 ^
  Url             : CardMobileCrop             = 575 x 450
*/

import { reactMount } from '@/lib/reactMount'
import { useState, useId, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { Media } from '@/components/Media'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { ButtonExpand } from '@/components/ButtonExpand'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const CardSectionV1 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  umbraco.BlockId = useId()
  const theme = umbraco?.Theme || 'rose-light'
  const cardTheme = umbraco?.CardTheme || 'emerald-dark'
  const cardCount = umbraco?.Cards?.length

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  let gridCols = ''
  if (cardCount === 2) { gridCols = 'md:grid-cols-2' }
  // Keeping for now in case we go with 3 card stack and grid
  if (cardCount === 3) { gridCols = 'lg:grid-cols-3' }

  return (
    <section id={umbraco?.AnchorTarget || null} data-block-id={umbraco.BlockId} data-theme={theme} className='py-16 px-6 md:px-8 bg-[--theme-bg] text-[--theme-text] overflow-hidden'>
      {(umbraco.Headline || umbraco.Summary) && (
        <header className='mx-auto max-w-7xl pb-14'>
          <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
            className='mx-auto text-center px-2.5'
          >
            {umbraco.Headline && (
              <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance md:mt-2 sm:text-5xl'>
                {umbraco.Headline}
              </h2>
            )}

            {umbraco.Summary && (
              <div
                className='_rte mt-2 md:mt-4 text-pretty text-base sm:text-xl font-light'
                dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
              />
            )}
          </motion.div>
        </header>
      )}

      {umbraco?.Cards?.length <= 2 && (
        <div data-theme={cardTheme} className={`mx-auto max-w-screen-xl grid gap-5 ${gridCols}`}>
          {umbraco?.Cards.map((Card, index) => (
            <div key={index} className='w-full h-auto flex'>
              <SlideShowCard card={Card} cardIndex={index} />
            </div>
          ))}
        </div>
      )}

      {umbraco?.Cards?.length >= 3 && (
        <div className='relative'>
          <div className='mx-auto max-w-screen-2xl'>
            <SlideShow umbraco={umbraco} />
          </div>
        </div>
      )}
    </section>
  )
}

const SlideShow = ({ umbraco }) => {
  const [initialLoad, setInitialLoad] = useState(true)

  if (!umbraco?.Cards?.length) return null
  const cardTheme = umbraco?.CardTheme || 'emerald-dark'
  const onResize = (swiper) => {
    window.requestAnimationFrame(() => {
      swiper.wrapperEl.style.justifyContent = swiper.isLocked ? 'center' : 'initial'
      const block = document.querySelector(`[data-block-id='${umbraco.BlockId}']`)
      if (block.querySelector('[data-footer-navigation]')) {
        block.querySelector('[data-footer-navigation]').hidden = swiper.isLocked
      }
    })
  }

  return (
    <div className='relative'>
      <Swiper
        grabCursor
        spaceBetween={22}
        slidesPerView='auto'
        data-style='carousel'
        data-theme={cardTheme}
        data-swiper='CardSectionV1'
        modules={[Pagination, Navigation]}
        className='w-full overflow-visible'
        pagination={{ type: 'progressbar' }}
        onResize={onResize}
        onInit={(swiper) => {
          onResize(swiper)
          if (swiper.slides.length <= 1) return
          const block = document.querySelector(`[data-block-id='${umbraco.BlockId}']`)

          const mobilePrevEl = block.querySelector("[data-footer-navigation] [data-navigation='prev']")
          const mobileNextEl = block.querySelector("[data-footer-navigation] [data-navigation='next']")

          mobilePrevEl.addEventListener('click', () => { swiper.slidePrev() })
          mobileNextEl.addEventListener('click', () => { swiper.slideNext() })
        }}
        onSlideChange={() => setInitialLoad(false)}
        onProgress={(swiper, progress) => {
          window.requestAnimationFrame(() => {
            const block = swiper.el.parentNode
            const prevButton = block.querySelector("[data-navigation='prev']")
            const nextButton = block.querySelector("[data-navigation='next']")
            if (prevButton) { prevButton.className = progress <= 0 ? 'opacity-50 pointer-events-none md:hidden' : '' }
            if (nextButton) { nextButton.className = progress >= 1 ? 'opacity-50 pointer-events-none md:hidden' : '' }
          })
        }}
      >
        {umbraco?.Cards.map((Card, index) => (
          <SwiperSlide key={index} className='w-full sm:w-[386px] h-auto flex'>
            <SlideShowCard card={Card} cardIndex={index} initialLoad={initialLoad} />
          </SwiperSlide>
        ))}
      </Swiper>
      {umbraco?.Cards.length >= 2 && (
        // Leave the below theme as default for all not block inheritances
        <div data-footer-navigation data-theme='charcoal-dark' className='max-md:flex max-md:gap-6 max-md:mx-auto max-md:justify-center mt-4'>
          <div className='md:absolute z-10 md:top-1/2 md:-left-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='prev'
              className=''
            >
              <span className='sr-only'>Previous slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5' />
            </button>
          </div>
          <div className='md:absolute z-10 md:top-1/2 md:-right-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='next'
              className=''
            >
              <span className='sr-only'>Next slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5 rotate-180' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const SlideShowCard = ({ card, cardIndex, initialLoad = true }) => {
  const {
    standardTransitionDistance,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionStagger
  } = animationSettings()

  return (
    <motion.div
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: initialLoad ? standardTransitionDuration : 0, delay: initialLoad ? (standardTransitionDelay + cardIndex * standardTransitionStagger) : 0, ease: standardTransitionEasing }} viewport={{ once: true, amount: standardViewportTrigger }}
      className={`relative isolate justify-end text-[--theme-text] ${card.Media ? 'max-md:h-[520px] h-[620px]' : ''} w-full`}
    >
      <div className={`group/link
        ${card.Button ? '[@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button transition-all duration-300 active:-translate-y-1 active:-translate-x-1 active:shadow-button' : ''} 
        h-full w-full flex flex-col rounded-3xl overflow-hidden ${card.Media ? 'absolute' : ''} top-0 left-0 w-full'
      `}
      >
        {/* TODO Alex : lets talk about this and see if there is a better solution */}
        {card.Button && (
          <a aria-label={card.Button.Name} href={card.Button.Url} target={card.Button.Target} className='absolute inset-0 z-10'>
            <span className='sr-only'>{card.Button.Name}</span>
          </a>
        )}
        <div className={`bg-[--theme-bg] md:mx-0 p-8 flex flex-col md:text-left w-full ${card.Media ? 'justify-end' : 'h-full'} ${!card.Media && card.Button ? 'pb-16' : ''}`}>
          {card.Label && (
            <p className='text-pretty text-base text-[--theme-subLabel] mb-1'>
              {card.Label}
            </p>
          )}
          {card.Headline && (
            <h2 className='text-balance text-3xl font-serif tracking-tight mb-3.5'>
              {card.Headline}
            </h2>
          )}
          {card.Summary && (
            <div
              className='text-pretty text-base _rte'
              dangerouslySetInnerHTML={{ __html: card.Summary }}
            />
          )}
        </div>
        <div className='relative w-full grow'>
          <div className={`absolute inset-0 flex justify-center items-center ${card.Button ? '_media-inactive' : ''}`}>
            {card.Media && (
              <Media umbraco={card} isVideoPreview={!!card?.Button?.Url} />
            )}
          </div>
          {card.Button && (
            <div className='absolute right-6 bottom-6 flex justify-end'>
              <ButtonExpand href={card.Button.Url} target={card.Button.Target}>
                {card.Button.Name}
              </ButtonExpand>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

reactMount('CardSectionV1', CardSectionV1)
