export const Region = {
  AUSTRALIA: 'AUSTRALIA',
  CANADA: 'CANADA',
  GREAT_BRITAIN: 'GREAT_BRITAIN',
  IRELAND: 'IRELAND',
  INDIA: 'INDIA',
  NETHERLANDS: 'NETHERLANDS',
  NORWAY: 'NORWAY',
  NEW_ZEALAND: 'NEW_ZEALAND',
  UNITED_STATES: 'UNITED_STATES',
  REST_OF_THE_WORLD: 'REST_OF_THE_WORLD'
}

export const RegionISO = {
  [Region.AUSTRALIA]: 'AU',
  [Region.CANADA]: 'CA',
  [Region.GREAT_BRITAIN]: 'GB',
  [Region.IRELAND]: 'IE',
  [Region.INDIA]: 'IN',
  [Region.NETHERLANDS]: 'NL',
  [Region.NORWAY]: 'NO',
  [Region.NEW_ZEALAND]: 'NZ',
  [Region.UNITED_STATES]: 'US',
  [Region.REST_OF_THE_WORLD]: 'ROW'
}

export const RegionGroup = {
  [Region.AUSTRALIA]: 'en-AU', // this region is also considered en-DE
  [Region.GREAT_BRITAIN]: 'en-GB', // this region is also considered en-DE
  [Region.NEW_ZEALAND]: 'en-NZ', // this region is also considered en-DE
  [Region.UNITED_STATES]: 'en-US', // this region is also considered en-DE
  PAY: 'en-DE', // Timely Page
  NON_PAY: 'en-IN' // Non Timely Page
}
