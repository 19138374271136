import { TimelyPlanName } from './TimelyPlanName'
import { PricingCalculationMode } from './PricingCalculationMode'
import { PricingRegion } from './PricingRegion'
import { SmsPricingRegion } from './SmsPricingRegion'

const CurrencyCode = {
  AUSTRALIA: 'AUD',
  GREAT_BRITAIN: 'GBP',
  NEW_ZEALAND: 'NZD',
  UNITED_STATES: 'USD'
}

const CurrencySign = {
  DOLLAR: '$',
  POUND: '£'
}

export default function getPricingMatrix () {
  const pricingMatrix = {
    [PricingRegion.IRELAND]: {
      pricingRegion: PricingRegion.IRELAND,
      currencyCode: CurrencyCode.GREAT_BRITAIN,
      sign: CurrencySign.POUND,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 21,
          [TimelyPlanName.ELEVATE]: 32,
          [TimelyPlanName.INNOVATE]: 37
        },
        staff: {
          [TimelyPlanName.BUILD]: 21,
          [TimelyPlanName.ELEVATE]: 26,
          [TimelyPlanName.INNOVATE]: 32
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 208,
          [TimelyPlanName.INNOVATE]: 260
        },
        calculationMode: PricingCalculationMode.STAFF_MULTIPLE
      },
      tag: '',
      sms: 8
    },
    [PricingRegion.GREAT_BRITAIN]: {
      pricingRegion: PricingRegion.GREAT_BRITAIN,
      currencyCode: CurrencyCode.GREAT_BRITAIN,
      sign: CurrencySign.POUND,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 21,
          [TimelyPlanName.ELEVATE]: 32,
          [TimelyPlanName.INNOVATE]: 37
        },
        staff: {
          [TimelyPlanName.BUILD]: 21,
          [TimelyPlanName.ELEVATE]: 26,
          [TimelyPlanName.INNOVATE]: 32
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 208,
          [TimelyPlanName.INNOVATE]: 260
        },
        calculationMode: PricingCalculationMode.STAFF_MULTIPLE
      },
      tag: '',
      sms: 6
    },
    [PricingRegion.CANADA]: {
      pricingRegion: PricingRegion.CANADA,
      currencyCode: CurrencyCode.UNITED_STATES,
      sign: CurrencySign.DOLLAR,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 26,
          [TimelyPlanName.ELEVATE]: 37,
          [TimelyPlanName.INNOVATE]: 43
        },
        staff: {
          [TimelyPlanName.BUILD]: 22,
          [TimelyPlanName.ELEVATE]: 27,
          [TimelyPlanName.INNOVATE]: 33
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 225,
          [TimelyPlanName.INNOVATE]: 275
        },
        calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE
      },
      tag: '',
      sms: 5
    },
    [PricingRegion.REST_OF_THE_WORLD]: {
      pricingRegion: PricingRegion.REST_OF_THE_WORLD,
      currencyCode: CurrencyCode.UNITED_STATES,
      sign: CurrencySign.DOLLAR,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 26,
          [TimelyPlanName.ELEVATE]: 37,
          [TimelyPlanName.INNOVATE]: 43
        },
        staff: {
          [TimelyPlanName.BUILD]: 22,
          [TimelyPlanName.ELEVATE]: 27,
          [TimelyPlanName.INNOVATE]: 33
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 225,
          [TimelyPlanName.INNOVATE]: 275
        },
        calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE
      },
      tag: '',
      sms: 8
    },
    [PricingRegion.UNITED_STATES]: {
      pricingRegion: PricingRegion.UNITED_STATES,
      currencyCode: CurrencyCode.UNITED_STATES,
      sign: CurrencySign.DOLLAR,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 26,
          [TimelyPlanName.ELEVATE]: 37,
          [TimelyPlanName.INNOVATE]: 43
        },
        staff: {
          [TimelyPlanName.BUILD]: 22,
          [TimelyPlanName.ELEVATE]: 27,
          [TimelyPlanName.INNOVATE]: 33
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 225,
          [TimelyPlanName.INNOVATE]: 275
        },
        calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE
      },
      tag: '',
      sms: 5
    },
    [PricingRegion.NEW_ZEALAND]: {
      pricingRegion: PricingRegion.NEW_ZEALAND,
      currencyCode: CurrencyCode.NEW_ZEALAND,
      sign: CurrencySign.DOLLAR,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 42,
          [TimelyPlanName.ELEVATE]: 62,
          [TimelyPlanName.INNOVATE]: 72
        },
        staff: {
          [TimelyPlanName.BUILD]: 39,
          [TimelyPlanName.ELEVATE]: 49,
          [TimelyPlanName.INNOVATE]: 59
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 405,
          [TimelyPlanName.INNOVATE]: 485
        },
        calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE
      },
      tag: '(exc) GST',
      sms: 10
    },
    [PricingRegion.AUSTRALIA]: {
      pricingRegion: PricingRegion.AUSTRALIA,
      currencyCode: CurrencyCode.AUSTRALIA,
      sign: CurrencySign.DOLLAR,
      prices: {
        solo: {
          [TimelyPlanName.BUILD]: 42,
          [TimelyPlanName.ELEVATE]: 62,
          [TimelyPlanName.INNOVATE]: 72
        },
        staff: {
          [TimelyPlanName.BUILD]: 39,
          [TimelyPlanName.ELEVATE]: 49,
          [TimelyPlanName.INNOVATE]: 59
        },
        fixed: {
          [TimelyPlanName.ELEVATE]: 405,
          [TimelyPlanName.INNOVATE]: 485
        },
        calculationMode: PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE
      },
      tag: '(exc) GST',
      sms: 8
    }
  }

  return pricingMatrix
}

export function getSmsPricing () {
  const smsPricing = {
    [SmsPricingRegion.NEW_ZEALAND]: {
      currency: CurrencyCode.NEW_ZEALAND,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 150,
        [TimelyPlanName.INNOVATE]: 200
      }
    },
    [SmsPricingRegion.AUSTRALIA]: {
      currency: CurrencyCode.AUSTRALIA,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    },
    [SmsPricingRegion.UNITED_STATES]: {
      currency: CurrencyCode.UNITED_STATES,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    },
    [SmsPricingRegion.GREAT_BRITAIN]: {
      currency: CurrencyCode.GREAT_BRITAIN,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    },
    [SmsPricingRegion.CANADA]: {
      currency: CurrencyCode.UNITED_STATES,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    },
    [SmsPricingRegion.IRELAND]: {
      currencyCode: CurrencyCode.GREAT_BRITAIN,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    },
    [SmsPricingRegion.REST_OF_THE_WORLD]: {
      currency: CurrencyCode.UNITED_STATES,
      freeSmsPerStaffPerMonth: {
        [TimelyPlanName.BUILD]: 100,
        [TimelyPlanName.ELEVATE]: 200,
        [TimelyPlanName.INNOVATE]: 300
      }
    }
  }

  return smsPricing
}

/**
 * 05/03/2025
 * The pricing model is pretty straightforward for Solo (price is for one person) and 8-20 staff (fixed price). For 2-7 staff:
 * For everything else it's the solo price plus the per staff rate (For example NZ 2 staff on Elevate is the solo price $62 + $49 for one staff member which equals $111)
 * For GBP once you go above Solo it becomes a flat rate for all staff (For example 1 staff is £21 but 2 staff becomes £42 (2 x £21))
 */
export const SOLO_PLUS_STAFF_MULTIPLE = (PricingMatrixCountry) => {
  const country = PricingMatrixCountry
  return ({ plan, staffCount }) => {
    if (staffCount === 1) {
      return country.prices.solo[plan]
    } else if (staffCount >= 8) {
      return country.prices.fixed[plan]
    } else {
      return country.prices.solo[plan] + country.prices.staff[plan] * (staffCount - 1)
    }
  }
}

export const STAFF_MULTIPLE = (PricingMatrixCountry) => {
  const country = PricingMatrixCountry
  return ({ plan, staffCount }) => {
    if (staffCount === 1) {
      return country.prices.solo[plan]
    } else if (staffCount >= 8) {
      return country.prices.fixed[plan]
    } else {
      return country.prices.staff[plan] * staffCount
    }
  }
}

const calculationMode = {
  SOLO_PLUS_STAFF_MULTIPLE,
  STAFF_MULTIPLE
}

export const PriceCalculations = (PricingMatrixCountry) => {
  return calculationMode[PricingMatrixCountry.prices.calculationMode](PricingMatrixCountry)
}
