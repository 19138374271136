import { useState, useEffect } from 'react'

export const useIsViewPortLessOrEqualTo = (screenSize) => {
  const [isLessOrEqualTo, setIsLessOrEqualTo] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsLessOrEqualTo(window.innerWidth <= screenSize)
    }

    handleResize() // Check on mount
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isLessOrEqualTo
}
