/* Hero block V3 aka Hero block

  Image Crops main media Verical
  -------------------------

  Url2280        : HeroVerticalTvLargeCrop              = 2800 x 856    @2280 ^ // custom
  Url1280        : HeroVerticalTvCrop                   = 2280 x 698    @1280 ^
  Url1024        : HeroVerticalDesktopCrop              = 1280 x 698    @1024 ^
  Url768         : HeroVerticalTabletCrop               = 1024 x 698     @768 ^

  Url640         : HeroVerticalMobileLargeCrop/HeroHorizontalMobileLargeCrop          = 768 x 576    @640 ^   (has changed)
  Url            : HeroVerticalMobileCrop/HeroHorizontalMobileCrop                    = 640 x 480             (has changed)

  Image Crops main media Horizontal
  -------------------------

  Url1640           : HeroHorizontalTvCrop                 = 1536 x 920    @1640 ^ // Custom
  Url1024           : HeroHorizontalDesktopCrop            = 940 x 920     @1024 ^
  Url768            : HeroHorizontalTabletCrop             = 1024 x 768     @768 ^
  Url640            : HeroHorizontalMobileLargeCrop        = 768 x 576      @640 ^
  Url               : HeroHorizontalMobileCrop             = 640 x 480
*/

import { reactMount } from '@/lib/reactMount'
import { useRef } from 'react'
import { Media } from '@/components/Media'
import { Button } from '@/components/Button'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

export const HeroBlockV3 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current

  if (umbraco.IsDisplayVertical) {
    return <HeroVertical umbraco={umbraco} />
  }

  return <HeroHorizontal umbraco={umbraco} />
}

const HeroVertical = ({ umbraco }) => {
  const theme = umbraco?.Theme || 'emerald-dark'
  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay
  } = animationSettings()

  return (
    <>
      <div id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-[--theme-bg] text-[--theme-text]'>
        <div className='relative'>
          <div className='mx-auto max-w-screen-2xl'>
            <div className='px-4 md:px-8 lg:pl-8 lg:pr-12 pt-10 pb-5 md:pt-20 flex items-center w-full max-w-5xl'>

              <motion.div
                initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
                className='mx-auto lg:mx-0 w-full'
              >

                {umbraco.Label && (
                  <p className='mb-2.5 text-pretty text-base text-[--theme-subLabel]'>
                    {umbraco.Label}
                  </p>
                )}

                {umbraco.Logo && (
                  <picture className='my-2.5 block'>
                    {/* TODO: update crop to use max width instead - logo should not be cropped instead restricted by height */}
                    <img className='max-h-10 w-auto' alt={umbraco.Logo.AltText} src={umbraco.Logo.Url} />
                  </picture>
                )}

                {umbraco.Headline && (
                  <h1 className='text-pretty text-5xl lg:text-6xl font-serif mb-6'>
                    {umbraco.Headline}
                  </h1>
                )}

                {umbraco.Summary && (
                  <div
                    className='_rte text-pretty text-xl pt-5 border-t border-[--theme-hr] font-light'
                    dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
                  />
                )}

                <div className='mt-10 flex items-center gap-y-4 gap-x-8 flex-wrap sm:mr-4 max-sm:justify-center'>
                  {umbraco.Button && (
                    <Button href={umbraco.Button.Url} target={umbraco.Button.Target} mobile='full'>
                      {umbraco.Button.Name}
                    </Button>
                  )}
                  {umbraco.ButtonLink && (
                    <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                      {umbraco.ButtonLink.Name}
                    </Button>
                  )}
                </div>

                <p className='ml-4 text-pretty text-xs pt-5 max-w-48'>
                  {umbraco.SubLabel}
                </p>
              </motion.div>

            </div>

          </div>
          <div className='relative grow'>
            <div className='relative p-2 flex justify-center items-center aspect-[4/3] lg:aspect-auto lg:h-[698px] 4xl:h-auto 4xl:aspect-hero overflow-hidden'>
              {umbraco.Media && <Media umbraco={umbraco} />}
              {/* Custom motion */}
              <motion.div
                initial={{ inset: 'var(--image-animation-border-size)' }} whileInView={{ inset: '0px' }}
                transition={{ duration: standardTransitionDuration, delay: standardTransitionDelay, ease: standardTransitionEasing }}
                viewport={{ once: true, amount: standardViewportTrigger }} className='absolute _image-border'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HeroHorizontal = ({ umbraco }) => {
  const theme = umbraco?.Theme || 'charcoal-dark'

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay
  } = animationSettings()

  return (
    <>
      <div id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-[--theme-bg] text-[--theme-text]'>
        <div className='relative'>
          <div className='mx-auto max-w-screen-2xl lg:flex lg:h-[80dvh] lg:min-h-[720px] lg:max-h-[974px]'>
            <div className='px-4 md:px-8 lg:pl-8 lg:pr-12 py-10 lg:py-[clamp(40px,10vh,128px)] flex items-end w-full lg:max-w-[450px] xl:max-w-[630px]'>
              <motion.div
                initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
                className='mx-auto lg:mx-0 w-full'
              >
                {umbraco.Label && (
                  <p className='mb-2.5 text-pretty text-base text-[--theme-subLabel]'>
                    {umbraco.Label}
                  </p>
                )}

                {umbraco.Logo && (
                  <picture className='my-2.5 block'>
                    {/* TODO: update crop to use max width instead - logo should not be cropped instead restricted by height */}
                    <img className='max-h-10 w-auto' alt={umbraco.Logo.AltText} src={umbraco.Logo.Url} />
                  </picture>
                )}

                {umbraco.Headline && (
                  <h1 className='text-pretty text-5xl xl:text-6xl font-serif mb-6'>
                    {umbraco.Headline}
                  </h1>
                )}

                {/* Only render below _rte wrapper if content */}
                {umbraco.Summary && (
                  <div
                    className='_rte text-pretty text-xl pt-5 border-t border-[--theme-hr] font-light'
                    dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
                  />
                )}

                <div className='mt-10 flex items-center gap-x-8 gap-y-4 sm:mr-4 flex-wrap max-sm:justify-center'>
                  {umbraco.Button && (
                    <Button href={umbraco.Button.Url} target={umbraco.Button.Target} mobile='full'>
                      {umbraco.Button.Name}
                    </Button>
                  )}
                  {umbraco.ButtonLink && (
                    <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                      {umbraco.ButtonLink.Name}
                    </Button>
                  )}
                </div>

                {/* opacity: 0.9; letter-spacing: 0.02em; */}

                <p className='ml-4 text-pretty text-xs pt-5 max-w-48'>
                  {umbraco.SubLabel}
                </p>
              </motion.div>
            </div>
            <div className='relative grow lg:-mr-8 2xl:absolute 2xl:inset-0 2xl:left-[calc(50%-132px)] lg:mr-0 lg:p-2.5 lg:pl-2 xl:p-2.5 xl:pl-1 max-w-screen-2xl'>
              <div className='relative p-2 h-full flex justify-center items-center aspect-[4/3] lg:aspect-auto  overflow-hidden'>
                {umbraco.Media && <Media umbraco={umbraco} />}
                {/* Custom motion */}
                <motion.div
                  initial={{ inset: 'var(--image-animation-border-size)' }} whileInView={{ inset: '0px' }}
                  transition={{ duration: standardTransitionDuration, delay: standardTransitionDelay, ease: standardTransitionEasing }}
                  viewport={{ once: true, amount: standardViewportTrigger }} className='absolute _image-border lg:rounded-lg'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

reactMount('HeroBlockV3', HeroBlockV3)
