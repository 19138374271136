import { reactMount } from '@/lib/reactMount'
import { useRef, Fragment, useState, useEffect } from 'react'
import { Media } from '@/components/Media'
import { Button } from '@/components/Button'
import { ArrowLongRightIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
import { parseISO, format as formatDate } from 'date-fns'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'

const BlogArticleLandingPage = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.Theme || 'coral-light'

  return (
    <div data-theme={theme}>
      <Breadcrumbs Breadcrumbs={umbraco.Breadcrumbs} />
      <HeroBlock umbraco={umbraco} />
      {/* Leave the below hard coded theme as this section should not inherit theming but be the same for all */}
      <div data-theme='charcoal-light' className='bg-white text-[--theme-text] py-12 lg:py-14'>
        <div className='mx-auto max-w-screen-2xl lg:flex items-start justify-between gap-x-6 gap-y-8 px-4 md:px-6'>
          <Anchors umbraco={umbraco} />
          <div className='max-w-[987px]'>
            <Body umbraco={umbraco} />
            <div>
              <Features umbraco={umbraco} />
            </div>
          </div>
        </div>
      </div>

      <RelatedArticles umbraco={umbraco} />
    </div>
  )
}

const HeroBlock = ({ umbraco }) => {
  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay
  } = animationSettings()

  return (
    <div className='bg-[--theme-bg] text-[--theme-text]'>
      <div className='relative'>
        <div className='mx-auto max-w-screen-2xl justify-between lg:flex lg:min-h-[595px]'>
          <div className='px-4 md:px-8 xl:px-6 py-10 pt-16 xl:py-16 flex items-end w-full lg:max-w-[500px]'>
            <motion.div
              initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
              transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
              className='mx-auto lg:mx-0'
            >
              <div className='tex-base font-medium flex items-center'>
                {umbraco.Tags && (
                  <a href={umbraco.Tags[0].Url} target={umbraco.Tags[0].Target} className='max-w-[calc(100%-96px)] block truncate text-[--theme-link-text] whitespace-nowrap'>
                    {umbraco.Tags[0].Name}
                  </a>
                )}

                <time className='shrink-0 grow pl-2.5 before:content-[""] before:inline-block before:bg-[--theme-text] before:size-1 before:mr-2.5 before:mt-1 inline-flex items-center' dateTime={umbraco.PublishedDate}>{formatDate(parseISO(umbraco.PublishedDate), 'MMM dd, yyyy')}</time>
              </div>

              {umbraco.Headline && (
                <h1 className='text-pretty text-4xl lg:text-5xl font-serif mb-6'>
                  {umbraco.Headline}
                </h1>
              )}

              <hr className='border-[--theme-hr]' />

              {umbraco.Summary && (
                <div
                  className='_rte text-pretty text-base pt-5 font-light'
                  dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
                />
              )}

              {umbraco.Author && (
                <div className='flex text-base items-center mt-5 gap-4 text-[--theme-link-text]'>
                  {umbraco.Author.Image && (
                    <div className='relative size-10'>
                      <img
                        src={umbraco.Author.Image.Url}
                        alt={umbraco.Author.Name}
                        className='w-full h-full object-cover object-center absolute top-0 left-0 rounded-full mr-3 border border-charcoal-900 shadow-avatar'
                      />
                    </div>
                  )}
                  <p className='text-base font-medium'>{umbraco.Author.Name}</p>
                  <span> | </span>
                  <p className='text-base'>{umbraco.Author.Team}</p>
                </div>
              )}

              <div className='mt-10 flex items-center gap-x-8 mr-4'>
                {umbraco.Button && (
                  <Button href={umbraco.Button.Url} target={umbraco.Button.Target}>
                    {umbraco.Button.Name}
                  </Button>
                )}
                {umbraco.ButtonLink && (
                  <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                    {umbraco.ButtonLink.Name}
                  </Button>
                )}
              </div>
            </motion.div>
          </div>
          <div className='relative max-w-[1011px] grow lg:mr-0 lg:pt-5 lg:pb-10 lg:pr-6'>
            <div className='relative p-2 h-full flex justify-center items-center aspect-[4/3] lg:aspect-auto w-full overflow-hidden'>
              {umbraco.Media && <Media umbraco={umbraco} />}
              <motion.div
                initial={{ inset: 'var(--image-animation-border-size)' }} whileInView={{ inset: '0px' }}
                transition={{ duration: standardTransitionDuration, delay: standardTransitionDelay, ease: standardTransitionEasing }}
                viewport={{ once: true, amount: standardViewportTrigger }} className='absolute _image-border lg:rounded-3xl'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Body = ({ umbraco }) => {
  if (!umbraco.Body) return null

  return (
    <div
      className='_rte _rte--page pt-2.5 text-pretty text-xl font-light'
      dangerouslySetInnerHTML={{ __html: umbraco.Body }}
    />
  )
}

const Anchors = ({ className, umbraco }) => {
  const [isAsideOpen, setIsAsideOpen] = useState(false)
  const [activeId, setActiveId] = useState(null)

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const validId = document.querySelector(`a[id=${entry.target.id}]`) || document.querySelector(`a[name=${entry.target.id}]`)
            if (validId) {
              setActiveId(entry.target.id)
            }
          } else {
            setActiveId(null)
          }
        })
      },
      {
        root: null,
        rootMargin: '0px 0px -85% 0px', // trigger when the anchor is 15% from the top of the viewport
        threshold: 1
      }
    )

    const anchorElements = document.querySelectorAll('a[id]')
    anchorElements.forEach((anchor) => {
      observer.observe(anchor)
    })

    return () => anchorElements.forEach((anchor) => observer.unobserve(anchor))
  }, [])

  if (!umbraco.Anchors?.length) {
    return (
      <aside className={`${className} max-lg:-mt-6 max-lg:-mx-4`} />
    )
  }

  return (
    <aside className={`${className} pt-2.5 flex flex-col gap-4 max-lg:-mt-6 max-lg:-mx-6 lg:sticky top-16 mb-5 lg:min-w-[280px] lg:w-auto px-2 grow`}>
      <div className='lg:max-w-[296px] pb-4 max-lg:px-4 border-b border-[--theme-hr] lg:border-[--theme-hr-subtle]'>
        <h3 className='select-none text-xl font-semibold flex justify-between max-lg:cursor-pointer max-lg:pb-2' onClick={() => setIsAsideOpen(!isAsideOpen)}>
          In this article
          {isAsideOpen
            ? (
              <span className='flex items-center lg:hidden'>
                <span className='sr-only'>Close menu</span>
                <MinusIcon className='size-5 ml-1.5' />
              </span>
              )
            : (
              <span className='flex items-center lg:hidden'>
                <span className='sr-only'>Open menu</span>
                <PlusIcon className='size-5 ml-1.5' />
              </span>
              )}
        </h3>
        <div className={`${isAsideOpen ? 'aside-open' : ''} flex flex-wrap flex-col gap-1 max-lg:hidden max-lg:[&.aside-open]:flex -mx-2 pt-2.5`}>
          {umbraco.Anchors.map((anchor) => (
            <a key={anchor.Name} href={anchor.Url} target={anchor.Target} className={`group py-2 px-2.5 flex items-start rounded-md text-pretty text-charcoal-800 transition-all duration-200 hover:text-midnight-700 select-none hover:pl-4 hover:bg-charcoal-100 ${anchor.Url.includes('#' + activeId) ? 'text-midnight-700 select-none pl-4 bg-charcoal-100 ' : ''}`}>
              {anchor.Name}
              <ArrowLongRightIcon className={`size-5 ml-2 mt-0.5 opacity-0 transition duration-200 group-hover:opacity-100 ${anchor.Url.includes('#' + activeId) ? 'opacity-100' : ''}`} />
            </a>
          ))}
        </div>
      </div>
    </aside>
  )
}

const Features = ({ umbraco }) => {
  if (!umbraco.Features?.length) return null

  return (
    <div className='pt-8 pb-2 border-t border-[--theme-hr-subtle] my-6'>
      <h3 className='text-xl font-medium pt-2'>
        Features used in this article
      </h3>
      <div className='flex flex-wrap gap-5 pt-3'>
        {umbraco.Features.map((feature) => (
          <a
            key={feature.Headline} href={feature.Button.Url} target={feature.Button.Target} className='
            [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
            shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button rounded-3xl transition-all duration-300
            flex bg-rose-900 overflow-hidden w-full max-w-[400px]
            active:-translate-y-1 active:-translate-x-1 active:shadow-button
            '
          >
            <div className='relative w-[90px] shrink-0'>
              {feature.Media?.Image?.Url && <img src={feature.Media.Image.Url} alt={feature.Media.Image.Alt} className='absolute w-full h-full object-cover object-center' />}
            </div>
            <div className='py-2.5 px-4 text-white'>
              <h4 className='text-xl font-medium'>{feature.Headline}</h4>
              <span className='flex items-center text-base text-rose-200'>
                <span>Read more</span>
                <ArrowLongRightIcon className='size-5 ml-1.5' />
              </span>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

const RelatedArticles = ({ umbraco }) => {
  if (!umbraco.RelatedArticles) return null
  const cardCount = umbraco.RelatedArticles?.length

  let gridCols = 'md:grid-cols-2'
  if (cardCount === 2) { gridCols = 'md:grid-cols-2' }
  if (cardCount === 3) { gridCols = 'lg:grid-cols-3' }

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <section className='py-16 bg-[--theme-bg] text-[--theme-text]'>
      <motion.header
        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
        transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
        className='pb-5 px-4 mx-auto max-w-screen-2xl md:px-10'
      >
        <div className='max-w-xl px-2.5'>
          <div>
            {umbraco.Tags?.map((tag, index) => (
              <a key={index} href={tag.Url} target={tag.Target} className='pr-2.5 inline-flex items-center text-[--theme-subLabel] after:content-[""] after:inline-block after:bg-[--theme-text] after:size-1 after:ml-2.5 after:mt-1 last:after:hidden'>
                {tag.Name}
              </a>
            ))}
          </div>
          <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance sm:text-5xl'>
            Related articles
          </h2>
        </div>
      </motion.header>
      <div className={`mx-auto px-4 max-w-screen-2xl pt-2 grid gap-4 md:px-10 ${gridCols}`}>
        {umbraco.RelatedArticles.map((card, index) => (
          <Card card={card} key={card.Id} cardIndex={index} />
        ))}
      </div>
    </section>
  )
}

const Card = ({ card, cardIndex }) => {
  const {
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDistance,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionStagger
  } = animationSettings()

  const isStackedContent = useIsViewPortLessOrEqualTo(768)
  return (
    <motion.a
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: standardTransitionDuration, delay: isStackedContent ? 0 : (standardTransitionDelay + cardIndex * standardTransitionStagger), ease: standardTransitionEasing }} viewport={{ once: true, standardViewportTrigger }}
      href={card.Button.Url} target={card.Button.Target}
      className='group relative isolate justify-end h-[500px] text-charcoal-800 w-full'
    >
      <div className='
        [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button
        h-full flex flex-col rounded-3xl overflow-hidden absolute top-0 left-0 w-full transition-all duration-300
        border border-charcoal-200
        active:-translate-y-1 active:-translate-x-1 active:shadow-button
        '
      >
        <div className='relative w-full grow'>
          <div className='absolute inset-0 flex justify-center items-center'>
            {card.Media && (
              <Media umbraco={card} isVideoPreview />
            )}
          </div>
        </div>
        <div className='relative md:mx-0 p-8 flex flex-col justify-start md:text-left w-full bg-white h-48 overflow-hidden after:content-[""] after:h-10 after:block after:absolute after:left-0 after:right-0 after:bottom-0 after:bg-gradient-to-t after:from-white after:via-white/95 after:via-20% after:to-white/0'>
          {!!card?.PublishedDate && !!card.Tags?.length && (
            <div className='text-base flex items-center pb-2'>
              <span className='max-w-[calc(100%-96px)] block truncate font-medium text-midnight-500 whitespace-nowrap'>
                {card.Tags[0]}
              </span>
              {!!card?.PublishedDate && (
                <time className='shrink-0 grow pl-2.5 before:content-[""] before:inline-block before:bg-charcoal-800 before:size-1 before:mr-2.5 before:mt-1 inline-flex items-center' dateTime={card.PublishedDate}>{formatDate(parseISO(card.PublishedDate), 'MMM dd, yyyy')}</time>
              )}
            </div>
          )}

          {card.Headline && (
            <h2 className='text-balance text-xl font-medium mb-2.5'>
              {card.Headline}
            </h2>
          )}

          {card.Summary && (
            <div
              className='text-pretty text-sm'
              dangerouslySetInnerHTML={{ __html: card.Summary }}
            />
          )}
        </div>
      </div>
    </motion.a>
  )
}

reactMount('BlogArticleLandingPage', BlogArticleLandingPage)
