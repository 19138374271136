/*
  Quote block V1b aka Quote block card carousel

  Image Crops main media
  -------------------------
  Url1024         : QuoteDesktopCrop            = 610 x 610    @1024 ^
  Url768          : QuoteTabletCrop             = 475 x 610    @768 ^
  Url             : QuoteMobileCrop             = 575 x 360
*/

import { reactMount } from '@/lib/reactMount'
import { useId, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { Media } from '@/components/Media'
import { ChevronLeftIcon, StarIcon } from '@heroicons/react/24/solid'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const QuoteBlockV1b = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  umbraco.BlockId = useId()
  const theme = umbraco?.Theme || 'rose-light'

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <div id={umbraco?.AnchorTarget || null} data-theme={theme} data-block-id={umbraco.BlockId} className='py-16 bg-[--theme-bg] text-[--theme-text] w-full overflow-hidden'>
      <div className='mx-auto max-w-7xl px-6 md:px-8'>
        <motion.div
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='mx-auto max-w-xl text-center px-2.5'
        >

          {umbraco.Headline && (
            <h2 className='text-pretty text-4xl font-serif tracking-tight sm:text-balance md:mt-2 sm:text-5xl'>
              {umbraco.Headline}
            </h2>
          )}
          {umbraco.Summary && (
            <div
              className='_rte mt-2 md:mt-4 text-pretty text-base sm:text-xl font-light'
              dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
            />
          )}
        </motion.div>
      </div>
      {!!umbraco?.Quotes?.length && (
        <div className='relative pt-6 sm:pt-10'>
          <div className='mx-auto max-w-screen-3xl px-4 md:px-6 lg:px-8'>
            <SlideShow umbraco={umbraco} />
          </div>
        </div>
      )}
    </div>
  )
}

const SlideShow = ({ umbraco }) => {
  if (!umbraco?.Quotes?.length) return null
  const cardTheme = umbraco?.CardTheme || 'emerald-dark'
  const [initialLoad, setInitialLoad] = useState(true)

  const onResize = (swiper) => {
    window.requestAnimationFrame(() => {
      swiper.wrapperEl.style.justifyContent = swiper.isLocked ? 'center' : 'initial'
      const block = document.querySelector(`[data-block-id='${umbraco.BlockId}']`)
      if (block.querySelector('[data-footer-navigation]')) {
        block.querySelector('[data-footer-navigation]').hidden = swiper.isLocked
      }
    })
  }

  return (
    <div className='relative'>
      <Swiper
        className={`w-full overflow-visible ${umbraco?.Quotes?.length > 1 ? 'pr-1.5' : ''} sm:pr-0`}
        data-style='carousel'
        data-swiper={'QuoteBlockV1b' + umbraco.BlockId}
        data-theme={cardTheme}
        grabCursor
        modules={[Pagination, Navigation]}
        onResize={onResize}
        pagination={{ type: 'progressbar' }}
        slidesPerView='auto'
        navigation
        spaceBetween={umbraco?.Quotes?.length > 1 ? 8 : 0}
        breakpoints={{
          768: {
            spaceBetween: umbraco?.Quotes?.length > 1 ? 20 : 0
          }
        }}
        onInit={(swiper) => {
          onResize(swiper)
          if (swiper.slides.length <= 1) return
          const block = document.querySelector(`[data-block-id='${umbraco.BlockId}']`)

          const mobilePrevEl = block.querySelector("[data-footer-navigation] [data-navigation='prev']")
          const mobileNextEl = block.querySelector("[data-footer-navigation] [data-navigation='next']")

          mobilePrevEl.addEventListener('click', () => { swiper.slidePrev() })
          mobileNextEl.addEventListener('click', () => { swiper.slideNext() })
        }}
        onSlideChange={() => setInitialLoad(false)}
        onProgress={(swiper, progress) => {
          window.requestAnimationFrame(() => {
            const block = swiper.el.parentNode
            const prevButton = block.querySelector("[data-navigation='prev']")
            const nextButton = block.querySelector("[data-navigation='next']")
            if (prevButton) { prevButton.className = progress <= 0 ? 'opacity-50 pointer-events-none md:hidden' : '' }
            if (nextButton) { nextButton.className = progress >= 1 ? 'opacity-50 pointer-events-none md:hidden' : '' }
          })
        }}
      >
        {umbraco?.Quotes.map((Quote, index) => (
          <SwiperSlide key={index} className='w-full sm:w-auto h-auto flex items-end'>
            <SlideShowCard card={Quote} cardIndex={index} initialLoad={initialLoad} />
          </SwiperSlide>
        ))}
      </Swiper>
      {umbraco?.Quotes.length >= 2 && (
        // Leave the below theme as default for all not block inheritances
        <div data-footer-navigation data-theme='charcoal-dark' className='max-md:flex max-md:gap-6 max-md:mx-auto max-md:justify-center mt-4'>
          <div className='md:absolute z-10 md:top-1/2 md:-left-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='prev'
              className=''
            >
              <span className='sr-only'>Previous slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5' />
            </button>
          </div>
          <div className='md:absolute z-10 md:top-1/2 md:-right-[20px] md:-translate-y-1/2'>
            <button
              type='button'
              data-navigation='next'
              className=''
            >
              <span className='sr-only'>Next slide</span>
              <ChevronLeftIcon aria-hidden='true' className='size-5 rotate-180' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const SlideShowCard = ({ card, cardIndex, initialLoad = true }) => {
  const mediaCardSpacing = 'px-6 md:px-10 max-md:py-6 max-md:pb-4'
  const mediaContentWidth = 'w-full sm:w-[465px] md:w-[calc((100vw/2)-40px)] lg:w-[465px]'
  const {
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDistance,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionStagger
  } = animationSettings()

  return (
    <div className='mx-auto h-full select-none w-full md:w-auto'>
      <motion.div
        initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
        transition={{ duration: initialLoad ? standardTransitionDuration : 0, delay: initialLoad ? (standardTransitionDelay + cardIndex * standardTransitionStagger) : 0, ease: standardTransitionEasing }} viewport={{ once: true, amount: standardViewportTrigger }}
        className='relative isolate overflow-hidden rounded-3xl h-full flex flex-col justify-end md:flex-row text-[--theme-text]'
      >
        <div className={`bg-[--theme-bg] grow md:mx-0 py-16 flex flex-col max-md:justify-start justify-end md:text-left w-full md:h-[610px] break-words ${card.Media ? mediaContentWidth : 'sm:w-[465px]'} ${card.Media ? mediaCardSpacing : 'px-10'}`}>
          <svg className='block text-[--theme-action] max-md:w-[32px] max-md:h-auto max-md:mb-3 mb-5' width='60' height='44' viewBox='0 0 60 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M38.7758 23.6452C40.4316 21.7008 43.2916 20.5043 47.0547 20.5043C52.9253 20.5043 57.7421 24.8418 57.7421 31.2732C57.7421 38.3029 52.3232 43.0891 45.0979 43.0891C36.5179 43.0891 30.7979 36.3585 30.7979 26.487C30.7979 10.7824 43.5926 1.06043 60 0.910866V3.30396C47.9579 4.35093 37.8726 10.7824 38.7758 23.6452ZM7.97789 23.6452C9.63368 21.7008 12.4937 20.5043 16.2568 20.5043C22.1274 20.5043 26.9442 24.8418 26.9442 31.2732C26.9442 38.3029 21.5252 43.0891 14.3 43.0891C5.71999 43.0891 0 36.3585 0 26.487C0 10.7824 12.7947 1.06043 29.2021 0.910866V3.30396C17.16 4.35093 7.07473 10.7824 7.97789 23.6452Z' fill='currentColor' />
          </svg>
          <h2 className='text-balance font-serif tracking-tight text-xl md:text-2xl lg:text-3xl'>
            {card.Quote}
          </h2>
          {card.Rating >= 1 && (
            <p className='max-md:mt-3 mt-5 text-[--theme-action] flex'>
              {Array.from({ length: card.Rating }).map((_, index) => (<StarIcon key={index} className='size-5' />))}
            </p>
          )}
          <hr className='border-[--theme-hr] my-4 md:my-6' />
          <div className='flex flex-col gap-1 -mt-0.5'>
            {card.Reviewer && (
              <p className='text-base font-medium'>
                {card.Reviewer}
              </p>
            )}
            {card.Company && (
              <p className='text-base text-[--theme-subLabel]'>
                {card.Company}
              </p>
            )}
          </div>
        </div>
        {card.Media && (
          <div className='relative h-[240px] sm:h-[280px] md:h-auto md:aspect-auto w-full md:max-w-[calc((100vw/2)-40px)] lg:max-w-[calc(100vw-620px)] md:w-[610px]'>
            <Media umbraco={card} />
          </div>
        )}
      </motion.div>
    </div>
  )
}

reactMount('QuoteBlockV1b', QuoteBlockV1b)
