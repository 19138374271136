import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'

export const Breadcrumbs = ({ Theme = null, Breadcrumbs }) => {
  Theme = Theme === 'light' ? 'coral-dark' : Theme === 'dark' ? 'coral-light' : Theme

  return (
    <nav data-theme={Theme} aria-label='Breadcrumb' className='absolute z-10 w-full '>
      <div className='w-full mx-auto max-w-screen-2xl py-2 md:py-4 text-[--theme-text]'>
        <ol role='list' className='flex items-center gap-3 lg:gap-4  lg:flex px-4 md:px-8 lg:max-w-lg'>
          {Breadcrumbs.slice(-2).map((page, index) => (
            <Fragment key={page.Url}>
              {!!index && <li><ChevronRightIcon aria-hidden='true' className='size-5 shrink-0' /></li>}
              <li className='overflow-hidden shrink-0 last:shrink'>
                <a
                  href={page.Url}
                  aria-current={(Breadcrumbs.slice(-2).length - 1) === index ? 'page' : undefined}
                  className='block text-sm hover:opacity-80 pb-0.5 truncate block'
                >
                  {page.Name}
                </a>
              </li>
            </Fragment>
          ))}
        </ol>
      </div>
    </nav>
  )
}
