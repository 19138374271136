import { reactMount } from '@/lib/reactMount'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import { Button } from '@/components/Button'
import { useRef } from 'react'
import { useSessionStorage } from 'react-use'

const NotificationsBar = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco.Theme || 'charcoal-dark'

  if (!umbraco.Notifications) return null
  const [notificationDismissed, setNotificationDismissed] = useSessionStorage('notificationDismissed', 0)
  if (umbraco.Notifications.IsDismissable && notificationDismissed) return null

  const handleOnClick = () => {
    setNotificationDismissed(1)
  }

  return (
    <div data-theme={theme} className='bg-[--theme-bg] text-[--theme-text] relative isolate flex items-center justify-center gap-x-6 overflow-hidden px-6 py-3.5 md:px-8 sm:before:flex-1'>
      <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
        <p className='text-base'>
          {umbraco.Notifications.Notification}
        </p>

        {umbraco.Notifications.Button && (
          <Button href={umbraco.Notifications.Button.Url} target={umbraco.Notifications.Button.Target} size='sm'>
            {umbraco.Notifications.Button.Name} <span aria-hidden='true' className='ml-2'><ArrowLongRightIcon aria-hidden='true' className='size-5 relative -bottom-[1px]' /></span>
          </Button>
        )}
      </div>

      <div className='flex flex-1 justify-end'>
        {umbraco.Notifications.IsDismissable && (
          <button onClick={handleOnClick} type='button' className='-mr-4 p-3 focus-visible:outline-offset-[-4px]'>
            <span className='sr-only'>Dismiss</span>
            <XMarkIcon aria-hidden='true' className='size-5' />
          </button>
        )}
      </div>
    </div>
  )
}

reactMount('NotificationsBar', NotificationsBar)
