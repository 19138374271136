import { reactMount } from '@/lib/reactMount'
import { useRef, useState } from 'react'
import { Button } from '@/components/Button'
import { ButtonTag } from '@/components/ButtonTag'
import { ArrowPathIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import useSWRMutation from 'swr/mutation'
import { fetcher } from '@/lib/fetcher'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

// keys must match BE types
const FILTERS = {
  All: 'All results',
  Features: 'Features',
  Pricing: 'Pricing',
  Blog: 'Blog',
  WhatsNew: 'What\'s new',
  SalonResources: 'Salon guides and resources',
  FreeTemplates: 'Templates and tools'
}

const SearchResultsPage = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current.Data

  const searchQueryFromUrl = new URLSearchParams(window.location.search)?.get('query') || ''

  const [searchQuery, setSearchQuery] = useState(searchQueryFromUrl)
  const [currentSearchQuery, setCurrentSearchQuery] = useState(searchQueryFromUrl)
  const [title, setTitle] = useState(searchQueryFromUrl)
  const [filter, setFilter] = useState('All')
  const [page, setPage] = useState(0)
  const [articles, setArticles] = useState(umbraco.Pages)
  const [showLoadMore, setShowLoadMore] = useState(umbraco.ShowLoadMore)
  const [filtersCount, setFiltersCount] = useState(umbraco.FiltersCount)

  const { trigger, isMutating } = useSWRMutation(
    '/umbraco/api/search/search/',
    fetcher
  )

  const fetchArticles = async (keyword, filterValue, pageNumber) => {
    try {
      const { data } = await trigger({
        method: 'GET',
        params: {
          query: encodeURIComponent(keyword),
          type: filterValue,
          page: pageNumber
        }
      })

      setArticles(pageNumber === 0 ? data.Pages : [...articles, ...data.Pages])
      setShowLoadMore(data.ShowLoadMore)
      setFiltersCount(data.FiltersCount)
      const newUrl = new URL(window.location)
      newUrl.searchParams.set('query', keyword)
      newUrl.searchParams.set('type', filterValue)
      newUrl.searchParams.set('page', pageNumber)
      window.history.pushState({}, '', newUrl)
    } catch (error) {
      resetSearchState()
    }
  }

  const resetSearchState = () => {
    setArticles([])
    setShowLoadMore(false)
    setPage(0)
    setFilter('All')
  }

  const search = async () => {
    if (searchQuery.trim() === '') return
    setCurrentSearchQuery(searchQuery)
    setTitle(searchQuery)
    setPage(0)
    setFilter('All')
    await fetchArticles(searchQuery, 'All', 0)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search()
    }
  }

  const handleFilterClick = async (newFilter) => {
    setFilter(newFilter)
    setPage(0)
    await fetchArticles(currentSearchQuery, newFilter, 0)
  }

  const handleLoadMoreArticlesClick = async () => {
    const newPage = page + 1
    setPage(newPage)
    await fetchArticles(currentSearchQuery, filter, newPage)
  }

  const handleClear = () => {
    resetSearchState()
    setSearchQuery('')
    setTitle('Search')
    setCurrentSearchQuery('')
  }

  const renderArticles = (article) => (
    <motion.div
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
    >
      <a
        href={article.Url} key={article.Id} className='
        border border-charcoal-200 p-4 w-full flex gap-5 rounded-2xl
        [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        transition-all duration-300 shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:border-charcoal-900 [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button text-charcoal-800
        active:-translate-y-1 active:-translate-x-1 active:shadow-button active:border-charcoal-900
        '
      >
        {article.Image && (
          <div className='hidden md:block relative shrink-0 md:w-[180px] h-[118px] overflow-hidden rounded-2xl'>
            <img src={article.Image.Url} alt={article.Image.Alt} className='absolute object-cover w-full h-full inset-0' />
          </div>
        )}
        {article.Headline && article.Description && (
          <div className='pr-5'>
            <p className='text-sm font-medium text-midnight-500 mb-1.5'>{article.Tag}</p>

            {article.Headline && (
              <h2 className='text-xl font-semibold mb-2'>{article.Headline}</h2>
            )}

            {article.Description && (
              <div
                className='font-light'
                dangerouslySetInnerHTML={{ __html: article.Description }}
              />
            )}
          </div>
        )}
      </a>
    </motion.div>
  )

  const renderNoArticlesFound = () => (
    <motion.div
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
      className='flex flex-col justify-center items-center max-w-xl mx-auto text-center w-full text-charcoal-800'
    >
      <p><span className='font-semibold mb-1'>0 results</span> for this keyboard</p>

      <div className='relative w-full before:content-[""] before:block before:pb-[90%]'>
        <img className='absolute object-cover h-full w-full inset-0' src='/static/image/no-search-results.svg' alt='No results' />
      </div>

      <div>
        <p className='font-semibold text-xl pb-1'>No results found</p>
        <p className='text-base text-charcoal-700'>We can't find anything with that term at the moment, try searching something else.</p>
      </div>
    </motion.div>
  )

  // Get only filters with count > 0
  const activeFilters = filtersCount && Object.entries(filtersCount).filter(([, count]) => count)
  // Display filter buttons if there are more than 2 filters and articles are present
  // The number 2 is because we always have 'All' filter and at least one more filter
  const shouldDisplayFilterButtons = !!articles.length && activeFilters.length > 2

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionDistance
  } = animationSettings()

  return (
    <>
      <div className='py-12 lg:pt-16 xl:pt-20 text-charcoal-800'>
        <motion.header
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='mx-auto max-w-5xl px-4 text-center'
        >
          <h1 className='text-3xl md:text-4xl font-serif pb-1'>{title || 'Search'}</h1>

          <label htmlFor='searchTerm' className='cursor-text mx-auto max-w-xl border border-charcoal-200 rounded-lg p-4 flex gap-4 items-center focus-within:ring-2 my-4'>
            <input
              id='searchTerm'
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder='Search...'
              className='grow outline-none order-2'
            />

            <div className='order-1'>
              <span className='sr-only'>Search</span>
              {!isMutating && <MagnifyingGlassIcon className='size-5' />}
              {isMutating && <ArrowPathIcon className='animate-spin' />}
            </div>

            {!!searchQuery?.trim() && (
              <button onClick={handleClear} className='order-3'>
                <XMarkIcon className='size-5' />
              </button>
            )}
          </label>

          {shouldDisplayFilterButtons && (
            <div className='flex max-xl:flex-wrap gap-4 pt-1 xl:pt-0 pb-7 items-center justify-center'>
              <h5 className='max-xl:w-full max-xl:grow tex-center text-midnight-800'>Filter</h5>
              <div data-theme='charcoal-light' className='flex gap-2.5 flex-wrap justify-center'>
                {activeFilters.map(([filterType]) => (
                  <ButtonTag key={filterType} onClick={() => handleFilterClick(filterType)} state={filter === filterType ? 'active' : 'default'}> {FILTERS[filterType]} </ButtonTag>
                ))}
              </div>
            </div>
          )}
        </motion.header>

        <div className='mx-auto max-w-5xl flex flex-col gap-3 px-4 py-4'>
          {articles.map(renderArticles)}
          {!!currentSearchQuery && articles.length === 0 && renderNoArticlesFound()}
        </div>

        {showLoadMore && (
          <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
            className='flex justify-center pt-8'
          >
            <Button
              disabled={isMutating} onClick={handleLoadMoreArticlesClick}
            >
              <span>Load more stories</span>
              {isMutating && <ArrowPathIcon className='animate-spin size-5' />}
            </Button>
          </motion.div>
        )}
      </div>
    </>
  )
}

reactMount('SearchResultsPage', SearchResultsPage)
