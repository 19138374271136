import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'

export const animationSettings = () => {
  const isMobileSettings = useIsViewPortLessOrEqualTo(768)

  const standardTransitionDuration = isMobileSettings ? 0.5 : 1
  const standardTransitionDelay = 0.2
  const standardTransitionDistance = '32px'
  const standardTransitionEasing = [0.250, 0.460, 0.450, 0.940]
  const standardTransitionStagger = 0.15
  const standardViewportTrigger = isMobileSettings ? 'some' : 0.1

  const standardTransition = {
    duration: standardTransitionDuration,
    delay: standardTransitionDelay,
    ease: standardTransitionEasing
  }

  return {
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionDistance,
    standardTransitionEasing,
    standardTransitionStagger,
    standardViewportTrigger,
    standardTransition
  }
}
