export const fetcher = (url, { arg }) => {
  const fetchData = async () => {
    if (!url) throw new Error('URL not provided')

    if (!arg?.method) throw new Error('Method not provided')
    arg.headers ??= {}
    arg.headers['Content-Type'] ??= 'application/json'

    if (arg.method.toUpperCase() === 'GET' && arg.params) {
      const queryParams = new URLSearchParams(arg.params).toString()
      url += `?${queryParams}`
    }

    const response = await fetch(url, arg)
    const data = await response.json()

    if (!response.ok) {
      return Promise.reject(data)
    }

    data.data = camelCaseToPascalCase(data.data)
    return Promise.resolve(data)
  }

  return fetchData()
}

export const camelCaseToPascalCase = (obj) => {
  if (obj === null || typeof obj !== 'object') return obj
  if (Array.isArray(obj)) return obj.map(camelCaseToPascalCase)

  return Object.keys(obj).reduce((acc, key) => {
    const pascalCaseKey = key.replace(/^(.)(.*)$/g, (_, g1, g2) => g1.toUpperCase() + g2)
    acc[pascalCaseKey] = camelCaseToPascalCase(obj[key])
    return acc
  }, {})
}
