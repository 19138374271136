import { Region, RegionISO } from './Region'

export const PricingRegion = {
  AUSTRALIA: Region.AUSTRALIA,
  CANADA: Region.CANADA,
  GREAT_BRITAIN: Region.GREAT_BRITAIN,
  IRELAND: Region.IRELAND,
  NEW_ZEALAND: Region.NEW_ZEALAND,
  UNITED_STATES: Region.UNITED_STATES,
  REST_OF_THE_WORLD: Region.REST_OF_THE_WORLD
}

export const PricingRegionISO = {
  AUSTRALIA: RegionISO.AUSTRALIA,
  CANADA: RegionISO.CANADA,
  GREAT_BRITAIN: RegionISO.GREAT_BRITAIN,
  IRELAND: RegionISO.IRELAND,
  NEW_ZEALAND: RegionISO.NEW_ZEALAND,
  UNITED_STATES: RegionISO.UNITED_STATES,
  REST_OF_THE_WORLD: RegionISO.REST_OF_THE_WORLD
}

const validPricingRegions = Object.values(PricingRegion)
export const isPricingRegion = (value) => {
  return validPricingRegions.includes(value)
}

export const getPricingRegionByISO = (iso) => {
  for (const region in PricingRegionISO) {
    if (PricingRegionISO[region] === iso) {
      return region
    }
  }
  return PricingRegion.REST_OF_THE_WORLD
}
