import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'

const scrollToHash = () => {
  if (!window.location.hash) return
  const elementWithIdOrNameEqualToHash = document.querySelector(`[id='${window.location.hash.substring(1)}'], [name='${window.location.hash.substring(1)}']`)
  if (!elementWithIdOrNameEqualToHash) return
  const emptyLink = document.createElement('a')
  emptyLink.href = window.location.hash
  document.body.insertBefore(emptyLink, document.body.firstChild)
  emptyLink.click()
  emptyLink.remove()
}

const RootComponent = ({ Component, props }) => {
  // this useEffect will simulate a trigger on mount for
  // whenever we mount a component to any [data-react-mount]
  useEffect(() => {
    scrollToHash()
  }, [])

  return <Component {...props} />
}

const mountComponent = (element, Component) => {
  const dataset = element?.dataset ?? null
  const root = createRoot(element)
  root.render(<RootComponent Component={Component} props={{ dataset }} />)
}

export const reactMount = (ComponentName, Component) => {
  document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll(`[data-react-mount='${ComponentName}']`)
    elements.forEach(element => mountComponent(element, Component))
  })
}
