import { reactMount } from '@/lib/reactMount'
import { Fragment, useRef, useState } from 'react'
import { Button } from '@/components/Button'
import { ButtonTag } from '@/components/ButtonTag'
import useSWRMutation from 'swr/mutation'
import { fetcher } from '@/lib/fetcher'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { parseISO, format as formatDate } from 'date-fns'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import { Media } from '@/components/Media'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const BlogCategoryLandingPage = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco.Theme || 'coral-light'

  return (
    <div data-theme={theme}>
      <Breadcrumbs Breadcrumbs={umbraco.Breadcrumbs} />
      <HeroBlock umbraco={umbraco} />
      {/* Leave the below hard coded theme as this section should not inherit theming */}
      <div data-theme='charcoal-light' className='bg-[--theme-bg]'>
        <Articles umbraco={umbraco} />
      </div>
    </div>
  )
}

const HeroBlock = ({ umbraco }) => {
  return (
    <div className='bg-[--theme-bg] text-[--theme-text]'>
      <div className='mx-auto max-w-screen-2xl px-4 md:px-8 lg:px-12 py-16'>
        {umbraco.Headline && (
          <h1 className='text-pretty text-5xl lg:text-6xl font-serif mb-6'>
            {umbraco.Headline}
          </h1>
        )}

        {umbraco.Summary && (
          <div
            className='_rte text-pretty text-xl font-light'
            dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
          />
        )}

        {!!umbraco.Tags?.length && (
          <div className='flex gap-2.5 pt-5 pb-7 flex-wrap'>
            {umbraco.Tags.map((tag) => (
              <Fragment key={tag.Name}>
                <ButtonTag
                  href={tag.Url}
                  target={tag.Target}
                  state={window.location.pathname.replace(/\/$/, '').endsWith(tag.Url) ? 'active' : 'default'}
                >
                  {tag.Name}
                </ButtonTag>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const Articles = ({ umbraco }) => {
  if (!umbraco?.Features) return null
  const [articles, setArticles] = useState(umbraco.Features.Features)
  const [showLoadMore, setShowLoadMore] = useState(umbraco.Features.ShowLoadMore)

  // initial is 1 because the first page is already loaded by the server
  const [page, setPage] = useState(1)

  const { trigger, isMutating } = useSWRMutation(
    `/umbraco/api/article/articles?contentTypeTag=${encodeURIComponent(umbraco.ContentTypeTag)}&page=${page}`,
    fetcher
  )

  const loadMoreArticles = async () => {
    try {
      const { success, data: Features } = await trigger({
        method: 'GET'
      })

      if (!success) throw new Error('Failed to load articles')
      if (!Features?.Features?.length) throw new Error('No articles found')
      setArticles(prevArticles => [...prevArticles, ...Features.Features])
      setPage(prevPage => prevPage + 1)
      setShowLoadMore(Features.ShowLoadMore)
    } catch (err) {
      setShowLoadMore(false)
      console.error('Error loading articles:', err)
    }
  }

  let gridCols = 'md:grid-cols-2'
  if (articles.length === 2) { gridCols = 'md:grid-cols-2' }
  if (articles.length >= 3) { gridCols = 'lg:grid-cols-3' }

  return (
    <section className='pt-8 pb-16 px-4 md:px-6 lg:px-8 bg-[--theme-bg] text-[--theme-text]'>
      <div className={`mx-auto max-w-screen-2xl pt-2 grid gap-4 ${gridCols}`}>
        {articles.map((card, index) => (
          <Card card={card} key={card.Id + '' + index + ''} />
        ))}
      </div>
      {showLoadMore && (
        <div className='flex justify-center pt-7'>
          <Button
            disabled={isMutating}
            onClick={loadMoreArticles}
          >
            <span>Load more stories</span>
            {isMutating && <ArrowPathIcon className='size-5' />}
          </Button>
        </div>
      )}
    </section>
  )
}

// TODO @justin @alex : please use CardDesktopCrop for the card image

const Card = ({ card }) => {
  const {
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionDistance
  } = animationSettings()

  return (
    <motion.a
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: standardTransitionDuration, delay: standardTransitionDelay, ease: standardTransitionEasing }} viewport={{ once: true, amount: standardViewportTrigger }}
      href={card.Button.Url} target={card.Button.Target}
      className=' group relative isolate justify-end text-charcoal-800 h-[500px]'
    >
      <div className='
        [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button
        h-full flex flex-col rounded-3xl overflow-hidden absolute top-0 left-0 w-full transition-all duration-300
        border border-charcoal-200
        active:-translate-y-1 active:-translate-x-1 active:shadow-button
        '
      >
        {card.Media.Image && (
          <div className='relative w-full grow'>
            <Media umbraco={card} isVideoPreview />
          </div>
        )}

        <div className='relative md:mx-0 p-8 flex flex-col justify-start md:text-left w-full bg-white h-48 overflow-hidden after:content-[""] after:h-10 after:block after:absolute after:left-0 after:right-0 after:bottom-0 after:bg-gradient-to-t after:from-white after:via-white/95 after:via-20% after:to-white/0'>
          {!!card?.PublishedDate && !!card.Tags?.length && (
            <div className='text-base flex items-center pb-2'>
              <span className='max-w-[calc(100%-96px)] block truncate font-medium text-midnight-500 whitespace-nowrap'>
                {card.Tags[0]}
              </span>
              {!!card?.PublishedDate && (
                <time className='shrink-0 grow pl-2.5 before:content-[""] before:inline-block before:bg-charcoal-800 before:size-1 before:mr-2.5 before:mt-1 inline-flex items-center' dateTime={card.PublishedDate}>{formatDate(parseISO(card.PublishedDate), 'MMM dd, yyyy')}</time>
              )}
            </div>
          )}

          {card.Headline && (
            <h2 className='text-balance text-xl font-medium mb-2.5'>
              {card.Headline}
            </h2>
          )}

          {card.Summary && (
            <div
              className='text-pretty text-sm'
              dangerouslySetInnerHTML={{ __html: card.Summary }}
            />
          )}
        </div>

      </div>
    </motion.a>
  )
}

reactMount('BlogCategoryLandingPage', BlogCategoryLandingPage)
