import { reactMount } from '@/lib/reactMount'
import { useRef, useState } from 'react'
import { CheckIcon, UserIcon, UsersIcon, UserGroupIcon, ArrowLongRightIcon, PlusIcon, StarIcon, MinusIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Button } from '@/components/Button'
import { useSessionStorage } from 'react-use'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'

import { PricingRegion } from '@/lib/pricingMatrix/PricingRegion'
import { TimelyPlanName } from '@/lib/pricingMatrix/TimelyPlanName'
import getPricingMatrix, { getSmsPricing, PriceCalculations } from '@/lib/pricingMatrix/pricing-matrix'
import { getPricingRegionByISO } from '../../lib/pricingMatrix/PricingRegion'

/* The client dictated the order of the regions */
export const RegionLabels = {
  [PricingRegion.AUSTRALIA]: 'Australia (exc GST)',
  [PricingRegion.NEW_ZEALAND]: 'New Zealand (exc GST)',
  [PricingRegion.GREAT_BRITAIN]: 'Great Britain',
  [PricingRegion.IRELAND]: 'Ireland',
  [PricingRegion.UNITED_STATES]: 'USA + EU',
  [PricingRegion.CANADA]: 'Canada',
  [PricingRegion.REST_OF_THE_WORLD]: 'Rest of the world'
}

const _plans = [TimelyPlanName.BUILD, TimelyPlanName.ELEVATE, TimelyPlanName.INNOVATE]

const numberToString = (number) => {
  // returns String because we want to keep the trailing zeros: .5 will show as .50
  return number % 1 ? number.toFixed(2) : String(number)
}

const getPlansInfoByRegion = ({ region, planId }) => {
  const _plansInfo = {
    [TimelyPlanName.BUILD]: {
      id: TimelyPlanName.BUILD,
      name: 'Build',
      description: 'For salons who want to simplify appointments and payments.',
      featured: false,
      cta: 'Try Build for free',
      features: {
        title: 'Key features:',
        list: []
      }
    },
    [TimelyPlanName.ELEVATE]: {
      id: TimelyPlanName.ELEVATE,
      name: 'Elevate',
      description: 'For salons who want to put their brand first and reduce admin.',
      featured: false,
      cta: 'Try Elevate for free',
      features: {
        title: 'Everything in Build, plus:',
        list: []
      }
    },
    [TimelyPlanName.INNOVATE]: {
      id: TimelyPlanName.INNOVATE,
      name: 'Innovate',
      description: 'For salons who want to transform and put the client experience first.',
      featured: true,
      cta: 'Try Innovate for free',
      features: {
        title: 'Everything in Elevate, plus:',
        list: []
      }
    }
  }

  if (planId === TimelyPlanName.BUILD) {
    _plansInfo[TimelyPlanName.BUILD].features.list.push(
      'Online and social media booking',
      'Send automated customer reminders with SMS and email'
    )

    if ([PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN].includes(region)) {
      _plansInfo[TimelyPlanName.BUILD].features.list.push('Seamless in-salon and online payments with simple fees')
    }

    if ([PricingRegion.IRELAND, PricingRegion.UNITED_STATES].includes(region)) {
      _plansInfo[TimelyPlanName.BUILD].features.list.push('Online payments with simple fees')
    }

    _plansInfo[TimelyPlanName.BUILD].features.list.push(
      'Manage stock levels'
    )
  }

  if (planId === TimelyPlanName.ELEVATE) {
    _plansInfo[TimelyPlanName.ELEVATE].features.list.push(
      'Send digital consultation forms',
      'Store client photos and documents ',
      'Waitlist to manage appointment requests and cancellations',
      'Targeted SMS campaigns and automated follow-up messages',
      'Set pricing tiers based on staff skills and seniority'
    )
  }

  if (planId === TimelyPlanName.INNOVATE) {
    if ([PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN].includes(region)) {
      _plansInfo[TimelyPlanName.INNOVATE].features.list.push('Direct phone support')
    }

    _plansInfo[TimelyPlanName.INNOVATE].features.list.push(
      'Reduce admin with automatic consultation forms ',
      'Encourage rebooking with automated reminders',
      'Create and track staff sales targets and performance',
      'VIP services for special clients',
      'Send customers to a personalised booking confirmation page'
    )
  }

  return _plansInfo[planId]
}

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const hardCoded = {
  Label: 'Choose your plan',
  Headline: 'Find the perfect plan for your business',
  Summary: ''
}

const maxStaff = 20
const makeStaffCountNumber = (staffCount) => {
  const staffCountNumber = parseInt(staffCount)
  return isNaN(staffCountNumber) ? 1 : staffCountNumber === 0 ? 1 : staffCountNumber
}

const PricePlansV1 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const [region, setRegion] = useState(getPricingRegionByISO(window.localStorage.getItem('regionIso')))
  const [staffCount, setStaffCount] = useSessionStorage('staffCount', '1')

  /* eslint-disable operator-linebreak */
  const hasHeaderContent = false
    || hardCoded?.Label
    || hardCoded?.Headline
    || hardCoded?.Summary
  /* eslint-enable operator-linebreak */

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <section id={umbraco?.AnchorTarget || null} className='bg-charcoal-50'>
      {hasHeaderContent && (
        <motion.header
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='text-center bg-midnight-50 pt-16 pb-[240px]'
        >
          <div className='container mx-auto max-w-5xl px-4 md:px-8 lg:px-10 pt-2.5'>
            {hardCoded?.Label && (
              <p className='text-pretty mb-3 text-base text-midnight-500'>
                {hardCoded.Label}
              </p>
            )}

            {hardCoded.Headline && (
              <h2 className='mb-4 text-4xl md:text-6xl text-pretty font-serif tracking-tight text-charcoal-800'>
                {hardCoded.Headline}
              </h2>
            )}

            {hardCoded.Summary && (
              <div
                className='_rte text-base text-midnight-800 max-w-[556px] mx-auto font-light'
                dangerouslySetInnerHTML={{ __html: hardCoded.Summary }}
              />
            )}

            <StaffCounter staffCount={staffCount} setStaffCount={setStaffCount} />
          </div>
        </motion.header>
      )}

      <div className='mx-auto max-w-7xl px-6 lg:px-8 pb-16 -mt-[220px]'>
        <div className='mx-auto flex flex-col gap-y-4'>
          {/*

            Whe may use below banner in the future
            {parseInt(staffCount) >= maxStaff && (
              <p className='text-center bg-white/50 rounded-lg p-2 mt-8'>Have more than {maxStaff} staff? <a href='#' className='text-midnight-500 underline'>Book a call with our Sales team</a>, we will craft a package to suit you </p>
            )}

          */}

          <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
            className='flex justify-end pb-2'
          >
            <RegionSelect region={region} setRegion={setRegion} />
          </motion.div>
          <div className='isolate mx-auto w-full grid grid-cols-1 gap-y-8 gap-x-3 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
            {_plans.map((plan, index) => (
              <PriceCard key={plan} region={region} staffCount={staffCount} planId={plan} cardIndex={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const priceMatrix = getPricingMatrix()
const smsMatrix = getSmsPricing()

const getPlanPrice = (region, planId, staffCount) => {
  staffCount = makeStaffCountNumber(staffCount)
  const priceMatrixCountry = priceMatrix[region]
  const priceMatrixSms = smsMatrix[region]

  const calculatePlanPrices = PriceCalculations(priceMatrixCountry)
  const priceType = staffCount === 1 ? 'solo' : staffCount >= 8 ? 'fixed' : 'staff'

  const freeSmsPerStaffPerMonth = priceMatrixSms?.freeSmsPerStaffPerMonth?.[planId] * staffCount

  let total = calculatePlanPrices({ plan: planId, staffCount })
  let price = 0

  if (priceType === 'fixed') {
    total = calculatePlanPrices({ plan: planId, staffCount })
    price = (total / staffCount).toFixed(2)
  } else {
    total = calculatePlanPrices({ plan: planId, staffCount })
    price = priceMatrixCountry.prices?.[priceType]?.[planId]
  }

  const isDisabled = (planId === TimelyPlanName.BUILD && staffCount > 7) || staffCount > 19

  return {
    isDisabled,
    priceTag: priceMatrixCountry.tag,
    currencyCode: priceMatrixCountry.currencyCode,
    sign: priceMatrixCountry.sign,
    price,
    total,
    freeSmsPerStaffPerMonth
  }
}

const PriceCard = ({ region, planId, staffCount, cardIndex }) => {
  staffCount = makeStaffCountNumber(staffCount)
  const plan = getPlansInfoByRegion({ region, planId })
  const planPrice = getPlanPrice(region, planId, staffCount)

  const isStackedContent = useIsViewPortLessOrEqualTo(1024)

  const {
    standardViewportTrigger,
    standardTransitionDuration,
    standardTransitionDelay,
    standardTransitionDistance,
    standardTransitionEasing,
    standardTransitionStagger
  } = animationSettings()

  const StaffIcon = staffCount <= 2
    ? UserIcon
    : staffCount <= 5
      ? UsersIcon
      : UserGroupIcon

  return (
    <motion.div
      initial={{ translateY: standardTransitionDistance, opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }}
      transition={{ duration: standardTransitionDuration, delay: isStackedContent ? 0 : (standardTransitionDelay + cardIndex * standardTransitionStagger), ease: standardTransitionEasing }} viewport={{ once: true, standardViewportTrigger }}
      key={plan.id}
    >
      <div
        className={classNames(
          plan.featured ? 'bg-midnight-700 ring-midnight-700' : 'ring-charcoal-200 bg-white',
          'rounded-2xl py-6 ring-1 w-full'
        )}
      >
        <h3
          id={plan.id}
          className={classNames(plan.featured ? 'text-white' : 'text-midnight-700', 'text-lg font-medium flex justify-between pl-7 pr-5')}
        >

          {plan.name}
          {plan.featured && (
            <span className='px-2 h-6 inline-flex items-center rounded-full border border-white leading-none font-light text-xs'>
              <StarIcon className='size-2 -mt-0.5 mr-0.5' />
              Recommended
            </span>
          )}
        </h3>

        {planPrice.isDisabled && (
          <p className={classNames(
            plan.featured ? 'text-white' : 'text-charcoal-800',
            'text-sm font-semibold px-7 pt-7 lg:h-20'
          )}
          >
            {planId === TimelyPlanName.BUILD
              ? 'Only available for salons with 1-7 staff'
              : 'Book a call for a custom quote'}
          </p>
        )}

        {!planPrice.isDisabled && (
          <>
            {staffCount >= 1 && staffCount <= 7 && (
              <>

                <p className='mt-4 flex items-baseline gap-x-1 px-7'>
                  <span
                    className={classNames(
                      plan.featured ? 'text-white' : 'text-charcoal-800',
                      'text-4xl tracking-tight font-serif'
                    )}
                  >
                    {planPrice.sign}{numberToString(planPrice.total / staffCount)}
                  </span>

                  <span className='relative block pl-1'>
                    <span className={classNames(plan.featured ? 'text-white' : 'text-charcoal-800', 'text-xs absolute -top-4 leading-none')}>per staff</span>
                    <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800', 'text-xs block leading-none')}>billed monthly</span>
                  </span>
                </p>

                <p className='px-7 flex flex-wrap items-center pt-2 pb-1 text-xs'>
                  <span className={classNames(plan.featured ? 'text-white after:bg-white' : 'text-charcoal-800 after:bg-charcoal-800', 'nowrap flex items-center leading-none mr-2.5 after:content-[""] after:size-1 after:rounded-full after:ml-2.5')}>
                    <StaffIcon aria-hidden='true' className='fill-[currentColor] block size-4 -mt-1 mr-2' />

                    <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800')}>
                      {staffCount > 0 ? staffCount : 1} staff
                    </span>
                  </span>

                  <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800', 'nowrap flex items-center leading-none')}>
                    Total:&nbsp;&nbsp;
                    <span className='font-semibold'>
                      {planPrice.sign}{planPrice.total} {planPrice.currencyCode}/month {planPrice.priceTag}
                    </span>
                  </span>
                </p>
              </>
            )}
            {staffCount >= 8 && staffCount <= 19 && (
              <>
                <p className='mt-4 flex items-baseline gap-x-1 px-7'>
                  <span
                    className={classNames(
                      plan.featured ? 'text-white' : 'text-charcoal-800',
                      'text-4xl tracking-tight font-serif'
                    )}
                  >
                    {planPrice.sign}{planPrice.total}
                  </span>

                  <span className='relative block pl-1'>
                    <span className={classNames(plan.featured ? 'text-white' : 'text-charcoal-800', 'text-xs absolute -top-4 leading-none')}>fixed price</span>
                    <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800', 'text-xs block leading-none')}>billed monthly</span>
                  </span>
                </p>

                <p className='px-7 flex flex-wrap items-center pt-2 pb-1 text-xs'>
                  <span className={classNames(plan.featured ? 'text-white after:bg-white' : 'text-charcoal-800 after:bg-charcoal-800', 'nowrap flex items-center leading-none mr-2.5 after:content-[""] after:size-1 after:rounded-full after:ml-2.5')}>
                    <StaffIcon aria-hidden='true' className='fill-[currentColor] block size-4 -mt-1 mr-2' />

                    <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800')}>
                      8 - {maxStaff} staff
                    </span>
                  </span>

                  <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800', 'nowrap flex items-center leading-none')}>
                    <span className='font-semibold'>
                      {planPrice.currencyCode}/month {planPrice.priceTag}
                    </span>
                  </span>
                </p>
              </>
            )}
          </>
        )}

        <p className={classNames(plan.featured ? 'text-white' : 'text-charcoal-800', 'my-4 text-sm px-7 text-pretty lg:h-10')}>
          {plan.description}
        </p>

        <div className='px-5 pt-3'>
          {(plan.id !== 'build' && staffCount >= maxStaff) && (
            <Button
              href='/book-a-call'
              aria-describedby={plan.id}
              variant={plan.featured ? 'default' : 'outline'}
              mobile='block'
              size='sm'
            >
              Book a call
              <ArrowLongRightIcon aria-hidden='true' className='fill-[currentColor] block size-5 ml-2' />
            </Button>
          )}

          {!(plan.id !== 'build' && staffCount >= maxStaff) && (
            <Button
              href={'https://app.gettimely.com/register?plan=' + plan.id}
              aria-describedby={plan.id}
              variant={plan.featured ? 'default' : 'outline'}
              mobile='block'
              size='sm'
              className={classNames(planPrice.isDisabled ? 'disabled' : '')}
            >
              {plan.cta}
              <ArrowLongRightIcon aria-hidden='true' className='fill-[currentColor] block size-5 ml-2' />
            </Button>
          )}
        </div>

        <ul
          role='list'
          className={classNames(
            plan.featured ? 'text-white' : 'text-charcoal-800',
            planPrice.isDisabled ? 'hidden lg:block opacity-50' : '',
            'mt-8 space-y-5 text-sm px-7'
          )}
        >

          <li className='flex items-center gap-x-3 '>
            <PlusIcon
              aria-hidden='true'
              className='size-5 -mt-1 flex-none text-[currentColor] _icon-stroke'
            />

            <span className='pb-0.5'>
              {planPrice.freeSmsPerStaffPerMonth} SMS <span className={classNames(plan.featured ? 'text-midnight-200' : 'text-midnight-800')}>per month included</span>
            </span>
          </li>

          <li className='flex items-center gap-x-3'>
            <PlusIcon
              aria-hidden='true'
              className='size-5 -mt-1 flex-none text-[currentColor] _icon-stroke'
            />

            <span className='pb-0.5'>
              No new client fees
            </span>
          </li>
        </ul>

        <hr className={classNames(plan.featured ? 'border-midnight-500' : 'border-charcoal-200', planPrice.isDisabled ? 'hidden lg:block opacity-50' : '', 'my-4 border-t')} />

        <p className={classNames(plan.featured ? 'text-white' : 'text-charcoal-800 border-charcoal-800', planPrice.isDisabled ? 'hidden lg:block opacity-50' : '', 'text-xs pt-2.5 px-7')}>{plan.features.title}</p>

        <ul
          role='list'
          className={classNames(
            plan.featured ? 'text-white' : 'text-charcoal-800',
            planPrice.isDisabled ? 'hidden lg:block opacity-50' : '',
            'mt-5 space-y-5 pb-2 text-sm px-7'
          )}
        >
          {plan.features.list.map((feature) => (
            <li key={feature} className='flex items-center gap-x-3'>
              <CheckIcon
                aria-hidden='true'
                className='size-5 -mt-1 flex-none text-[currentColor] _icon-stroke'
              />
              <span className='pb-0.5 text-pretty'>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  )
}

const RegionSelect = ({ region, setRegion }) => {
  const countryKeyValue = Object.entries(RegionLabels)
  const selectedCountry = RegionLabels[region]
  return (
    <Menu as='div' className='relative inline-block text-left text-charcoal-800'>
      <div>
        <MenuButton className='inline-flex w-full items-center justify-center gap-x-1.5 rounded-lg bg-transparent px-3 py-2 text-base hover:bg-gray-50'>
          <span className='relative -top-0.5'>{selectedCountry}</span>
          <ChevronDownIcon aria-hidden='true' className='-mr-1 size-[18px] stroke-[2.5] text-charcoal-800' />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
      >
        <div className='py-1'>
          {countryKeyValue.map(([key, value]) => (
            <MenuItem key={key}>
              <button
                onClick={() => setRegion(key)}
                className='block w-full text-left px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none'
              >
                {value}
              </button>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  )
}

const StaffCounter = ({ staffCount, setStaffCount }) => {
  const increment = () => {
    setStaffCount((prev) => {
      if (isNaN(parseInt(prev))) return '1'
      if (prev === maxStaff + '+') return prev
      const _prev = parseInt(prev)
      const total = _prev < maxStaff ? _prev + 1 : prev
      if (total === maxStaff) return maxStaff + '+'
      return total
    })
  }

  const decrement = () => {
    setStaffCount((prev) => {
      if (isNaN(parseInt(prev))) return '1'
      if (prev === 1) return prev
      const _prev = parseInt(prev)
      const total = _prev > 1 ? _prev - 1 : prev
      return total
    })
  }

  const handleOnChange = (e) => {
    const value = e.target.value.replace(/[^0-9+]/g, '').slice(0, 2)
    setStaffCount(value)
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      increment()
    } else if (e.key === 'ArrowDown') {
      decrement()
    }
  }

  const StaffIcon = parseInt(staffCount) <= 2
    ? UserIcon
    : parseInt(staffCount) <= 5
      ? UsersIcon
      : UserGroupIcon

  return (
    <div>
      <p className='text-charcoal-800 text-center text-lg font-medium pt-8 mb-2'>How many bookable staff?</p>
      <div className='inline-flex items-center gap-2'>
        <button onClick={decrement} className='p-2'>
          <span className='sr-only'>Decrement</span>
          <MinusIcon className='size-6 text-white rounded-full bg-midnight-700 [@media(hover:hover)]:[@media(pointer:fine)]:hover:active:scale-100 [@media(hover:hover)]:[@media(pointer:fine)]:hover:scale-110 [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-midnight-700' />
        </button>
        <div className='-mr-px grid grow grid-cols-1 focus-within:relative'>
          <label htmlFor='query' className='sr-only'>Staff count</label>
          <input
            id='query'
            name='query'
            type='text'
            value={staffCount}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            className='col-start-1 row-start-1 block w-20 text-center rounded-lg bg-white py-4 pl-10 pr-3 text-base text-charcoal-800 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-charcoal-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-midnight-700'
          />
          <StaffIcon
            aria-hidden='true'
            className='pointer-events-none col-start-1 row-start-1 mx-3 size-5 self-center text-charcoal-500'
          />
        </div>
        <button onClick={increment} className='p-2'>
          <span className='sr-only'>Increment</span>
          <PlusIcon className='size-6 text-white rounded-full bg-midnight-700 [@media(hover:hover)]:[@media(pointer:fine)]:hover:active:scale-100 [@media(hover:hover)]:[@media(pointer:fine)]:hover:scale-110 [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-midnight-700' />
        </button>
      </div>
    </div>
  )
}

reactMount('PricePlansV1', PricePlansV1)
