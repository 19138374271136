import { reactMount } from '@/lib/reactMount'
import { Fragment, useRef, useState } from 'react'
import { CheckIcon, MinusIcon, ArrowLongRightIcon, StarIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { Button } from '@/components/Button'
import { TimelyPlanName } from '@/lib/pricingMatrix/TimelyPlanName'
import { PricingRegion, getPricingRegionByISO } from '@/lib/pricingMatrix/PricingRegion'
import { RegionGroup } from '@/lib/pricingMatrix/Region'
import { getSmsPricing } from '@/lib/pricingMatrix/pricing-matrix'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const tiers = [
  {
    featured: false,
    id: TimelyPlanName.BUILD,
    name: 'Build',
    signUpUrl: 'https://app.gettimely.com/register?plan=' + TimelyPlanName.BUILD
  },
  {
    featured: false,
    id: TimelyPlanName.ELEVATE,
    name: 'Elevate',
    signUpUrl: 'https://app.gettimely.com/register?plan=' + TimelyPlanName.ELEVATE
  },
  {
    featured: true,
    id: TimelyPlanName.INNOVATE,
    name: 'Innovate',
    signUpUrl: 'https://app.gettimely.com/register?plan=' + TimelyPlanName.INNOVATE
  }
]

const createTier = (pla1Value, plan2Value, plan3value) => {
  return {
    [TimelyPlanName.BUILD]: pla1Value,
    [TimelyPlanName.ELEVATE]: plan2Value,
    [TimelyPlanName.INNOVATE]: plan3value
  }
}

const IncludedMonthlySMSPerStaff = () => {
  const smsPricing = getSmsPricing()

  const acc = []
  for (const region in smsPricing) {
    acc.push({
      name: 'Included monthly SMS per staff (excludes targeted SMS)',
      tiers: createTier(
        smsPricing[region].freeSmsPerStaffPerMonth[TimelyPlanName.BUILD] + ' SMS',
        smsPricing[region].freeSmsPerStaffPerMonth[TimelyPlanName.ELEVATE] + ' SMS',
        smsPricing[region].freeSmsPerStaffPerMonth[TimelyPlanName.INNOVATE] + ' SMS'
      ),
      region: [region]
    })
  }
  return acc
}

/* eslint-disable no-multi-spaces */
const sections = [
  {
    name: 'Online bookings',
    region: [],
    features: [
      { name: 'Online appointment calendar', tiers: createTier(true, true, true) },
      { name: 'Waitlist to quickly fill cancellations', tiers: createTier(false, true, true) },
      { name: 'Timely mobile app for mobile or tablet', tiers: createTier(true, true, true) },
      { name: 'Online booking 24/7 for clients', tiers: createTier(true, true, true) },
      { name: 'Minimise gaps between appointments', tiers: createTier(true, true, true) },
      { name: 'Add booking buttons to Instagram, Facebook and Messenger', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'In-salon payments',
    region: [RegionGroup.PAY, PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN],
    features: [
      { name: 'Take card payments in-salon with a Timely terminal', tiers: createTier(true, true, true) },
      { name: 'Use Tap to Pay on iPhone to accept contactless payments', tiers: createTier(true, true, true) },
      { name: 'Surcharge card processing fees', tiers: createTier(true, true, true), region: [PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA] },
      { name: 'Next business day payouts', tiers: createTier(true, true, true) },
      { name: 'Simplify your end of day cash up', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Online payments & deposits',
    region: [RegionGroup.PAY, PricingRegion.UNITED_STATES, PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN],
    features: [
      { name: 'Take deposits to reduce no shows', tiers: createTier(true, true, true) },
      { name: 'Capture customer card details and charge cancellation fees', tiers: createTier(true, true, true) },
      { name: 'Keep cards on file for faster checkout or cardless checkout', tiers: createTier(true, true, true) },
      { name: 'Boost sales with gift vouchers in-salon or online', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Hardware & POS',
    region: [],
    features: [
      { name: 'Connect to supported point of sale hardware', tiers: createTier(true, true, true) },
      { name: 'Accept payments anywhere with a mobile terminal or iPhone', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Digital consultations',
    region: [],
    features: [
      { name: 'Create consultation forms with the Consult app for iOS', tiers: createTier(true, true, true) },
      { name: 'Send electronic forms before appointments', tiers: createTier(false, true, true) },
      { name: 'Save consultation forms to customer profiles in Timely', tiers: createTier(false, true, true) },
      { name: 'Set up consultation forms to send automatically', tiers: createTier(false, false, true) }
    ]
  },
  {
    name: 'Client management',
    region: [],
    features: [
      { name: 'Client login for managing and rebooking appointments', tiers: createTier(true, true, true) },
      { name: 'Create exclusive VIP client services', tiers: createTier(false, false, true) },
      { name: 'Allow customers to bulk buy services as a package', tiers: createTier(true, true, true) },
      { name: 'Group services that you would normally perform together', tiers: createTier(true, true, true) },
      { name: 'Client appointment reminders', tiers: createTier(true, true, true) },
      { name: 'Client’s appointment history', tiers: createTier(true, true, true) },
      { name: 'Client profiles', tiers: createTier(true, true, true) },
      { name: 'Before and after photos', tiers: createTier(false, true, true) },
      { name: 'Scan or upload documents to client profiles', tiers: createTier(false, true, true) },
      { name: 'Add notes to client profiles', tiers: createTier(true, true, true) },
      { name: 'Loyalty rewards', tiers: createTier(false, true, true) },
      { name: 'Advanced rewards', tiers: createTier(false, false, true) },
      { name: 'Appointment confirmations by email and SMS', tiers: createTier(true, true, true) },
      { name: 'One-off email and SMS messaging', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Client loyalty',
    region: [],
    features: [
      { name: 'Rebooking reminders and reconnect messages', tiers: createTier(false, false, true) },
      { name: 'Send thank you messages', tiers: createTier(false, true, true) },
      { name: 'Request reviews from clients', tiers: createTier(false, true, true) },
      { name: 'Send aftercare and follow-up instructions', tiers: createTier(false, true, true) }
    ]
  },
  {
    name: 'Staff management',
    region: [],
    features: [
      { name: 'Set tiered pricing depending on staff experience', tiers: createTier(false, true, true) },
      { name: 'Track staff performance and dashboard', tiers: createTier(true, true, true) },
      { name: 'Set weekly and monthly staff targets', tiers: createTier(false, false, true) },
      { name: 'Let staff manage clients and appointments through the app', tiers: createTier(true, true, true) },
      { name: 'Manage timesheets and rosters', tiers: createTier(true, true, true) },
      { name: 'Keep staff up to date automatically with appointment notifications', tiers: createTier(true, true, true) },
      { name: 'Assign and control staff access and logins', tiers: createTier(true, true, true) },
      { name: 'Admin logins for administrators and non-booking staff', tiers: createTier(true, true, true) },
      { name: 'Track activity log', tiers: createTier(false, true, true) },
      { name: 'Pin access and device security', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Reporting & analytics',
    region: [],
    features: [
      { name: 'Business performance reports', tiers: createTier(true, true, true) },
      { name: 'Financial reports and dashboard', tiers: createTier(true, true, true) },
      { name: 'Stock, appointment, client and staff reports', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Stock & inventory',
    region: [],
    features: [
      { name: 'Retail and professional stock management', tiers: createTier(true, true, true) },
      { name: 'Order stock directly from suppliers', tiers: createTier(false, true, true) },
      { name: 'Use the Timely Stock app', tiers: createTier(true, true, true) },
      { name: 'Connect the Timely Stock app to Timely', tiers: createTier(false, true, true) }
    ]
  },
  {
    name: 'Add-ons',
    region: [],
    features: [
      { name: 'Accounting integrations - Xero, MYOB and Quickbooks', tiers: createTier(true, true, true) },
      { name: 'Stripe', tiers: createTier(false, true, true), region: [PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA] },
      { name: 'Connect to any website, including Squarespace and Wix', tiers: createTier(true, true, true) },
      { name: 'Run marketing campaigns with Mailchimp', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Marketing tools',
    region: [],
    features: [
      { name: 'Free, customisable mini-website', tiers: createTier(true, true, true) },
      { name: 'Create custom promo codes', tiers: createTier(false, true, true) },
      { name: 'Understand website traffic with Google Analytics', tiers: createTier(false, true, true) },
      { name: 'Track campaigns with Google Tag Manager', tiers: createTier(false, false, true) },
      { name: 'Custom confirmation page', tiers: createTier(false, false, true) },

      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '10c per SMS', '10c per SMS'), region: [PricingRegion.NEW_ZEALAND] },
      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '8c per SMS', '8c per SMS'), region: [PricingRegion.AUSTRALIA] },
      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '6p per SMS', '6p per SMS'), region: [PricingRegion.GREAT_BRITAIN] },
      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '8p per SMS', '8p per SMS'), region: [PricingRegion.IRELAND] },
      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '5c per SMS', '5c per SMS'), region: [PricingRegion.UNITED_STATES] },
      { name: 'Targeted SMS campaign marketing', tiers: createTier(false, '5c per SMS', '5c per SMS'), region: [PricingRegion.CANADA] },

      { name: 'Targeted email marketing with Mailchimp', tiers: createTier(true, true, true) }
    ]
  },
  {
    name: 'Business admin',
    region: [],
    features: [
      { name: 'Security to keep sensitive data secure', tiers: createTier(true, true, true) },
      { name: 'Track incomings and outgoings with cash management', tiers: createTier(true, true, true) },
      { name: 'Create and manage multiple locations', tiers: createTier(true, true, true) },
      { name: 'Automate admin with Zapier API', tiers: createTier(false, false, true) }
    ]
  },
  {
    name: 'Onboarding and support from Timely',
    region: [],
    features: [
      { name: 'Resources, business guides, and Timely Learn', tiers: createTier(true, true, true) },
      { name: 'Online support, help centre, chatbot and live messaging', tiers: createTier(true, true, true) },
      { name: 'Call back and phone support', tiers: createTier(false, false, true), region: [PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN] },
      { name: 'Direct phone support', tiers: createTier('$30 per month', '$30 per month', true), region: [PricingRegion.NEW_ZEALAND] },
      { name: 'Direct phone support', tiers: createTier('$30 per month', '$30 per month', true), region: [PricingRegion.AUSTRALIA] },
      { name: 'Direct phone support', tiers: createTier('£20 per month', '£20 per month', true), region: [PricingRegion.GREAT_BRITAIN] },
      { name: 'Online training', tiers: createTier(false, false, true), region: [PricingRegion.NEW_ZEALAND, PricingRegion.AUSTRALIA, PricingRegion.GREAT_BRITAIN] },
      { name: 'Transfer data from your current system', tiers: createTier(false, true, true) }
    ]
  },
  {
    name: 'Included SMS and rates',
    region: [],
    features: [
      ...IncludedMonthlySMSPerStaff(),
      { name: 'Rates for excess SMS', tiers: createTier('10c per SMS', '10c per SMS', '10c per SMS'), region: [PricingRegion.NEW_ZEALAND] },
      { name: 'Rates for excess SMS', tiers: createTier('8c per SMS', '8c per SMS', '8c per SMS'), region: [PricingRegion.AUSTRALIA] },
      { name: 'Rates for excess SMS', tiers: createTier('6p per SMS', '6p per SMS', '6p per SMS'), region: [PricingRegion.GREAT_BRITAIN] },
      { name: 'Rates for excess SMS', tiers: createTier('8p per SMS', '8p per SMS', '8p per SMS'), region: [PricingRegion.IRELAND] },
      { name: 'Rates for excess SMS', tiers: createTier('5c per SMS', '5c per SMS', '5c per SMS'), region: [PricingRegion.UNITED_STATES] },
      { name: 'Rates for excess SMS', tiers: createTier('5c per SMS', '5c per SMS', '5c per SMS'), region: [PricingRegion.CANADA] },
      { name: 'Rates for excess SMS', tiers: createTier('8c per SMS', '8c per SMS', '8c per SMS'), region: [PricingRegion.REST_OF_THE_WORLD] }
    ]
  }
]
/* eslint-enabled no-multi-spaces */

const hardCoded = {
  Label: 'Compare our plans',
  Headline: 'Find the plan that’s right for you',
  Summary: '<p>Compare plans to find your perfect fit and keep your clients coming back.</p>'
}

/* eslint-disable operator-linebreak */
const hasHeaderContent = false
    || hardCoded?.Label
    || hardCoded?.Headline
    || hardCoded?.Summary
/* eslint-enable operator-linebreak */

const ComparisonTableV1a = ({ dataset }) => {
  const umbracoRef = useRef(JSON.parse(dataset.umbraco))
  const umbraco = umbracoRef.current
  const region = getPricingRegionByISO(window.localStorage.getItem('regionIso'))
  const regionGroup = window.localStorage.getItem('regionGroup')

  /* eslint-disable operator-linebreak */
  const filteredSections = sections.filter(s => {
    return false
      || !s.region.length
      || s.region.includes(regionGroup)
      || s.region.includes(region)
  })
  /* eslint-enable operator-linebreak */

  const [openSections, setOpenSections] = useState([filteredSections[0].name])

  const handleSectionClick = (section) => {
    setOpenSections((prevOpenSections) =>
      prevOpenSections.includes(section.name)
        ? prevOpenSections.filter((name) => name !== section.name)
        : [...prevOpenSections, section.name]
    )
  }

  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <section id={umbraco?.AnchorTarget || null} className='bg-white py-16 max-sm:pb-8'>
      {hasHeaderContent && (
        <motion.header
          initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
          transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
          className='text-center'
        >
          <div className='container mx-auto max-w-5xl px-4 md:px-8 lg:px-10'>
            {hardCoded?.Label && (
              <p className='text-pretty mb-1 text-sm font-medium text-midnight-500'>
                {hardCoded.Label}
              </p>
            )}

            {hardCoded.Headline && (
              <h2 className='mb-4 text-4xl md:text-5xl text-pretty font-serif tracking-tight text-charcoal-800'>
                {hardCoded.Headline}
              </h2>
            )}

            {hardCoded.Summary && (
              <div
                className='_rte text-base md:text-xl text-pretty text-charcoal-800 font-light'
                dangerouslySetInnerHTML={{ __html: hardCoded.Summary }}
              />
            )}
          </div>
        </motion.header>
      )}

      <motion.div
        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
        transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
        className='mx-auto px-4 md:px-8 pt-8 md:pt-10 lg:pt-16 max-w-screen-2xl'
      >
        {/* NOT MOBILE */}
        <table className='_comparison-table w-full text-left max-md:hidden table-fixed border-collapse'>
          <caption className='sr-only'>Pricing plan comparison</caption>
          <colgroup>
            <col className='w-2/5' />
            {tiers.map((tier) => (
              <col key={tier.id} data-featured={tier.featured || null} className='w-1/5 data-[featured]:text-white' />
            ))}
          </colgroup>
          <thead>
            <tr>
              <td className='p-0 bg-white' />
              {tiers.map((tier) => (
                <th key={tier.id} scope='col' data-featured={tier.featured || null} className='rounded-t-2xl group p-0 bg-white data-[featured]:bg-midnight-700 data-[featured]:text-white'>
                  <div className='text-lg font-medium px-5 pt-3' />
                </th>
              ))}
            </tr>
            <tr className='sticky top-16'>
              <td className='p-0 bg-white' />
              {tiers.map((tier) => (
                <th key={tier.id} scope='col' data-featured={tier.featured || null} className='group p-0 bg-white text-charcoal-800 data-[featured]:bg-midnight-700 data-[featured]:text-white'>
                  <div className='text-lg font-medium px-5 pt-2'>
                    {tier.name}<span className='sr-only'>plan</span>
                  </div>
                  <div className='px-5 pt-3 pb-5'>
                    <Button
                      variant={tier.featured ? 'default' : 'outline'}
                      href={tier.signUpUrl}
                      size='sm'
                    >
                      Get started <span className='sr-only'>with {tier.name} plan</span>
                    </Button>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {filteredSections.map((section) => {
            const isOpen = openSections.includes(section.name)

            return (
              <tbody key={section.name} className='group _table-group'>
                <tr>
                  <td scope='col' colSpan='5' className='p-0 border-0'>
                    <button onClick={() => handleSectionClick(section)} className='w-full font-medium text-charcoal-800 flex items-stretch group/button'>
                      <span className='flex gap-2 items-center px-4 pt-[11px] pb-[13px] w-2/5 border-t border-charcoal-200] bg-charcoal-50 group-hover/button:bg-charcoal-100 text-left'>
                        {isOpen && <MinusIcon aria-hidden='true' className='size-5 _icon-stroke duration-400 -mb-[1px]' />}
                        {!isOpen && <PlusIcon aria-hidden='true' className='size-5 _icon-stroke duration-400 -mb-[1px]' />}
                        <span><span className='sr-only'> Toggle section </span>{section.name}</span>
                      </span>
                      {tiers.map((tier) => (
                        <span key={tier.id} data-featured={tier.featured || null} className='bg-charcoal-50 w-1/5 border-t border-charcoal-200] data-[featured]:border-midnight-500 bg-charcoal-50 group-hover/button:bg-charcoal-100 data-[featured]:bg-midnight-600 data-[featured]:group-hover/button:bg-midnight-700' />
                      ))}
                    </button>
                  </td>
                </tr>

                {section.features.filter((feature) => (!feature?.region || feature.region.includes(region))).map((feature) => (
                  <tr key={'desktop' + feature.name} className={`border-b border-gray-100 last:border-none  ${isOpen ? '' : 'hidden'}`}>
                    <td scope='row' className='px-4 pt-[11px] pb-[13px] text-charcoal-800 border-t border-midnight-500 text-pretty'>
                      {feature.name}
                    </td>

                    {tiers.map((tier) => (
                      <td key={tier.id} data-featured={tier.featured || null} className='group px-4 py-3 max-sm:text-center border-t border-midnight-500 text-charcoal-800 data-[featured]:bg-midnight-700 data-[featured]:text-white'>
                        {typeof feature.tiers[tier.id] === 'string'
                          ? (
                            <>
                              <span className='sr-only'>{tier.name} includes:</span>
                              <span className='text-sm/6 group-data-[featured]:text-white'>{feature.tiers[tier.id]}</span>
                            </>
                            )
                          : (
                            <>
                              {feature.tiers[tier.id] === true
                                ? (
                                  <CheckIcon aria-hidden='true' className='fill-[currentColor] inline-block size-5 _icon-stroke' />
                                  )
                                : (
                                  <MinusIcon aria-hidden='true' className='fill-[currentColor] inline-block size-5 _icon-stroke' />
                                  )}

                              <span className='sr-only'>
                                {feature.tiers[tier.id] === true
                                  ? `Included in ${tier.name}`
                                  : `Not included in ${tier.name}`}
                              </span>
                            </>
                            )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )
          })}
          <tfoot>
            <tr>
              <td className='p-0 bg-white' />
              {tiers.map((tier) => (
                <th key={tier.id} scope='col' data-featured={tier.featured || null} className='rounded-b-2xl group p-0 bg-white data-[featured]:bg-midnight-700 data-[featured]:text-white'>
                  <div className='text-lg font-medium px-5 pt-3' />
                </th>
              ))}
            </tr>
          </tfoot>
        </table>

        {/* MOBILE */}
        <TabGroup className='md:hidden'>
          <TabList className='relative flex sticky top-16 bg-white z-10 h-[46px]'>
            {tiers.map((tier) => (
              <Tab
                key={tier.id}
                data-featured={tier.featured || null}
                className='w-1/3  border-b border-gray-100 py-2 text-lg font-medium flex justify-center items-center text-charcoal-800 data-[selected]:border-midnight-700 data-[featured]:border-b-2 [&:not([data-focus])]:focus:outline-none'
              >
                {tier.featured && <StarIcon aria-hidden='true' className='fill-midnight-700 block size-5 mr-1 mb-1' />}
                {tier.name}
              </Tab>
            ))}
          </TabList>
          <TabPanels as={Fragment}>
            {tiers.map((tier) => (
              <TabPanel key={tier.id} data-featured={tier.featured || null} className='group animate-slideIn'>
                <div className='py-3.5 sticky top-[110px] bg-white'>
                  <Button
                    href={tier.signUpUrl}
                    variant={tier.featured ? 'default' : 'outline'}
                    mobile='block'
                  >
                    Get started <span className='sr-only'>with {tier.name} plan</span>
                    <ArrowLongRightIcon aria-hidden='true' className='fill-[currentColor] block size-5 ml-2' />
                  </Button>
                </div>
                {sections.map((section) => {
                  const isOpen = openSections.includes(section.name)

                  return (
                    <div key={section.name} className='group-data-[featured]:bg-midnight-700 bg-charcoal-50 group-data-[featured]:text-white text-charcoal-800'>
                      <button onClick={() => handleSectionClick(section)} className='group-data-[featured]:bg-midnight-600 px-4 py-3 text-base font-medium border-t border-charcoal-200 w-full flex gap-2 items-center'>
                        {isOpen && <MinusIcon aria-hidden='true' className='size-5 duration-400' />}
                        {!isOpen && <MinusIcon aria-hidden='true' className='size-5 duration-400' />}
                        <span><span className='sr-only'> Toggle section </span>{section.name}</span>
                      </button>

                      <dl className={`w-full grid ease-out duration-400 ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
                        <div className='overflow-hidden'>
                          {section.features.filter((feature) => (!feature?.region || feature.region.includes(region))).map((feature) => (
                            <div
                              key={'mobile' + feature.name}
                              className='grid grid-cols-2 border-t border-charcoal-200 px-4 py-4 bg-white text-charcoal-700 group-data-[featured]:bg-midnight-700 group-data-[featured]:text-white'
                            >
                              <dt className='text-sm/6 font-normal group-data-[featured]:text-midnight-200 text-pretty'>{feature.name}</dt>
                              <dd className='text-center'>
                                {typeof feature.tiers[tier.id] === 'string'
                                  ? (
                                    <span className='text-sm/6 '>{feature.tiers[tier.id]}</span>
                                    )
                                  : (
                                    <>
                                      {feature.tiers[tier.id] === true
                                        ? (
                                          <CheckIcon aria-hidden='true' className='fill-[currentColor] inline-block size-5' />
                                          )
                                        : (
                                          <MinusIcon aria-hidden='true' className='fill-[currentColor] inline-block size-5' />
                                          )}

                                      <span className='sr-only'>{feature.tiers[tier.id] === true ? 'Yes' : 'No'}</span>
                                    </>
                                    )}
                              </dd>
                            </div>
                          ))}
                        </div>
                      </dl>
                    </div>
                  )
                })}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </motion.div>
    </section>
  )
}

reactMount('ComparisonTableV1a', ComparisonTableV1a)
