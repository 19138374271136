/*
  Call to action V3 aka Image call to action

  Image Crops main media
  -------------------------
  Url1280         : ImageCallToActionTvCrop           = 856 x 550     @1280 ^
  Url1024         : ImageCallToActionDesktopCrop      = 600 x 550     @1024 ^
  Url768          : ImageCallToActionTabletCrop       = 920 x 550     @768 ^
  Url640          : ImageCallToActionMobileLargeCrop  = 720 x 360     @640 ^
  Url             : ImageCallToActionMobileCrop       = 560 x 360
*/

import { reactMount } from '@/lib/reactMount'
import { useRef } from 'react'
import { Media } from '@/components/Media'
import { Button } from '@/components/Button'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'
import { useIsViewPortLessOrEqualTo } from '@/hooks/useIsViewPortLessOrEqualTo'

const CallToActionV3 = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco?.Theme || 'charcoal-dark'
  const cardTheme = umbraco?.CardTheme || 'midnight-dark'

  const isMobile = useIsViewPortLessOrEqualTo(768)

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionDelay
  } = animationSettings()

  return (
    <div id={umbraco?.AnchorTarget || null} data-theme={theme} className='bg-[--theme-bg]'>
      <div className='mx-auto max-w-screen-2xl md:py-16 md:px-8 _cta-animation-distance'>
        <motion.div initial={{ translateY: 'var(--cta-animation-distance)', opacity: 0 }} whileInView={{ translateY: '0', opacity: 1 }} transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}>
          <div data-theme={cardTheme} className='text-[--theme-text] lg:h-[550px] relative isolate overflow-hidden md:rounded-3xl lg:flex lg:pt-0'>
            <div className={`${umbraco.MediaLeft ? 'lg:order-2' : ''} bg-[--theme-bg] relative px-6 md:px-16 pt-20 pb-10 lg:py-16 mx-auto text-center w-full lg:w-1/2 lg:max-w-[602px] 2xl:max-w-[606px] lg:mx-0 justify-center lg:flex flex-col lg:py-32 xl:text-left`}>
              <motion.div
                initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 0.25, delay: isMobile ? standardTransitionDelay : 0.8 }} viewport={{ once: true, amount: standardViewportTrigger }}
              >
                {umbraco.BackgroundImage && (
                  <picture className='absolute inset-0 size-full block'>
                    <img className='absolute inset-0 size-full object-cover object-center' loading='lazy' alt='' role='presentation' src={umbraco.BackgroundImage.Url} />
                  </picture>
                )}
                <div className='relative w-full lg:max-w-[344px] mx-auto xl:max-w-none'>
                  {umbraco.Headline && (
                    <h2 className='text-balance font-serif text-4xl tracking-tight lg:text-5xl'>
                      {umbraco.Headline}
                    </h2>
                  )}

                  {umbraco.Summary && (
                    <div
                      className='_rte mt-6 text-pretty text-xl font-light'
                      dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
                    />
                  )}
                  {/* Add condition her for if either button  */}

                  {(umbraco.Button || umbraco.ButtonLink) && (
                    <div className='mt-6 flex items-center justify-center gap-y-4 gap-x-6 xl:justify-start flex-wrap'>
                      {umbraco.Button && (
                        <Button href={umbraco.Button.Url} target={umbraco.Button.Target}>
                          {umbraco.Button.Name}
                        </Button>
                      )}

                      {umbraco.ButtonLink && (
                        <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                          {umbraco.ButtonLink.Name}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
            <div className='relative h-[360px] md:h-[550px] lg:h-full lg:mt-0 grow'>
              {umbraco.Media && <Media umbraco={umbraco} />}
            </div>
          </div>
        </motion.div>
      </div>
    </div>

  )
}

reactMount('CallToActionV3', CallToActionV3)
