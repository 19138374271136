/*
  Image Crops main media Verical
  (check with alex as these crops could be the same as hero block v3 to save on too many crops only difference being 628px height)
  -------------------------

  Url1280        : HeroVerticalTvCrop                   = 2280 x 698    @1280 ^
  Url1024        : HeroVerticalDesktopCrop              = 1280 x 698    @1024 ^
  Url768         : HeroVerticalTabletCrop               = 1024 x 698     @768 ^
  Url640         : HeroVerticalMobileLargeCrop          = 768 x 698      @640 ^
  Url            : HeroVerticalMobileCrop               = 640 x 698

  Image Crops main media Horizontal
  -------------------------

  Url1024           : FormHorizontalDesktopCrop            = 640 x 800     @1024 ^
  Url768            : FormHorizontalTabletCrop             = 940 x 940      @768 ^
  Url640            : FormHorizontalMobileLargeCrop        = 768 x 768      @640 ^
  Url               : FormHorizontalMobileCrop             = 640 x 640
*/

import { reactMount } from '@/lib/reactMount'
import { useEffect, useRef, useState } from 'react'
import { Media } from '@/components/Media'
import { ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline'

function scrollToSelector (selector) {
  const el = document.querySelector(selector)
  if (!el) return
  el.scrollIntoView({ behavior: 'smooth' })
}

const FormSection = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  const theme = umbraco.Theme || 'charcoal-light'
  const ReferralId = useRef(umbraco?.ReferralId || window.sessionStorage.getItem('referralId'))

  if (umbraco?.MarketoFormId == null || umbraco?.MarketoFormId.trim() === '') {
    console.error('Marketo form id is missing')
    return null
  }
  if (document.querySelector('#mktoForm_' + umbraco.MarketoFormId.trim())) {
    return null
  }

  const Header = () => (
    <header className='relative z-10 max-w-2xl mx-auto text-center px-4 pb-8 flex flex-col justify-center gap-3 text-[--theme-text]'>
      {umbraco.Label && <p>{umbraco.Label}</p>}
      {umbraco.Headline && <h2 className='text-4xl md:text-5xl font-serif'>{umbraco.Headline}</h2>}
      {umbraco.Summary && <div className='_rte pb-1 text-base font-light' dangerouslySetInnerHTML={{ __html: umbraco.Summary }} />}
    </header>
  )

  const MarketoFormWrapper = () => (
    <div className='relative z-10 max-w-2xl mx-auto px-4'>
      <div data-style='marketo-form' data-theme='charcoal-dark' className='max-w-xl mx-auto bg-white rounded-lg py-5 md:py-8 px-4 md:px-12 border border-charcoal-200'>
        <MarketoForm baseUrl='//go.gettimely.com' munchkinId='304-RPU-875' formId={umbraco.MarketoFormId.trim()} referralId={ReferralId.current} />
      </div>
    </div>
  )

  return (
    <>
      {umbraco.IsDisplayVertical && (
        <section id={umbraco?.AnchorTarget || null} data-form-id={umbraco.MarketoFormId.trim()} data-theme={theme} className='relative py-12 lg:py-16 bg-charcoal-50 min-h-[800px]'>
          <Header />
          <MarketoFormWrapper />
          <div data-theme='midnight-dark' className='absolute top-0 left-0 w-full h-[628px]'>
            <Media umbraco={umbraco} />
          </div>
        </section>
      )}

      {!umbraco.IsDisplayVertical && (
        <div id={umbraco?.AnchorTarget || null} data-form-id={umbraco.MarketoFormId.trim()} className='relative pb-8 md:pt-8 md:pb-16 bg-charcoal-50 min-h-[800px]'>
          <div className='max-w-7xl flex flex-col lg:flex-row mx-auto'>
            <section data-theme={theme} className='order-2 md:pt-8 lg:order-1 w-full lg:w-[620px] shrink-0 xl:pl-5'>
              <Header />
              <MarketoFormWrapper />
            </section>
            <div data-theme='midnight-dark' className='order-1 lg:order-2 relative aspect-square lg:aspect-auto lg:h-[640px] xl:h-[800px] top-0 left-0 w-full lg:sticky md:px-8 lg:px-5 lg:top-16 pb-8 md:py-8'>
              <div className='relative w-full h-full md:rounded-lg overflow-hidden'>
                <Media umbraco={umbraco} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const loadScript = (src, async = true) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = async
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

const initMunchkin = (munchkinId) => {
  if (typeof window.Munchkin !== 'undefined') {
    window.Munchkin.init(munchkinId)
  }
}

const loadMunchkinScript = () => {
  if (typeof window.Munchkin !== 'undefined') {
    return Promise.resolve()
  }
  return loadScript('//munchkin.marketo.net/munchkin.js')
}

const loadMarketoFormScript = ({ baseUrl }) => {
  if (window.MktoForms2) {
    return Promise.resolve()
  }
  return loadScript(baseUrl + '/js/forms2/js/forms2.min.js', false)
}

const MarketoForm = ({ baseUrl, munchkinId, formId, referralId }) => {
  const [formState, setFormState] = useState('default')
  const [isMutating, setIsMutating] = useState(false)

  useEffect(() => {
    const initializeMunchkin = async () => {
      try {
        await loadMunchkinScript()
        initMunchkin(munchkinId)
      } catch (error) {
        console.error('Error loading Munchkin script:', error)
      }
    }

    const initializeForm = async () => {
      try {
        await loadMarketoFormScript({ baseUrl })
        window.MktoForms2.loadForm(baseUrl, munchkinId, formId, (form) => {
          const REFERRAL_INPUT_ID = 'AP_Referrer_ID__c_contact'
          const input = document.querySelector(`[data-form-id="${formId}"] input[id="${REFERRAL_INPUT_ID}"]`)

          if (input && referralId) {
            input.value = referralId
          }

          form.onSuccess((values, followUpUrl) => {
            setFormState('success')
            scrollToSelector(`[data-form-id="${formId}"]`)

            if (!!followUpUrl && (followUpUrl.includes('.pdf') || followUpUrl.includes('target=_blank'))) {
              window.open(followUpUrl, '_blank')
              return false
            } else {
              setIsMutating(true)
              setTimeout(() => {
                window.location = followUpUrl
              }, 2000)
              return false
            }
          })
        })
      } catch (error) {
        console.error('Error loading Marketo form:', error)
      }
    }

    initializeMunchkin()
    initializeForm()

    return () => {
      if (window.MktoForms2) {
        window.MktoForms2.whenReady((form) => {
          form.remove()
        })
      }
    }
  }, [baseUrl, munchkinId, formId])

  return (
    <>
      {formState === 'default' && <form id={'mktoForm_' + formId} />}
      {formState === 'success' && (
        <div className='flex items-center rounded-md bg-green-100 px-4 py-3 text-green-700 gap-2'>
          <CheckIcon className='size-5' />
          <span>Form submitted!</span>
        </div>
      )}
    </>
  )
}

export default MarketoForm

reactMount('FormSection', FormSection)
