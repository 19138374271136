import { PlusIcon, XMarkIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid'

// Example of usage:
// <ButtonExpand icon='x'>
//   Close
// </ButtonExpand>

const variants = {
  defaultClassName: `
    group select-none relative [@media(hover:hover)]:[@media(pointer:fine)]:max-w-9 group-hover/link:w-auto group-hover/link:max-w-full hover:w-auto hover:max-w-full translate-z-0 backface-hidden transition duration-300 transition-all inline-flex justify-center items-center font-medium
    disabled:bg-[--theme-button-1-disabled-bg] disabled:text-[--theme-button-1-disabled-text] disabled:pointer-events-none
    [&.disabled]:bg-[--theme-button-1-disabled-bg] [&.disabled]:text-[--theme-button-1-disabled-text] [&.disabled]:pointer-events-none
    bg-[--theme-button-1-bg] text-[--theme-button-1-text] rounded-full
    group
  `,
  size: {
    base: 'p-2 text-sm',
    lg: 'py-2 px-4 text-base'
  }
}

export const ButtonExpand = (props) => {
  const theme = props?.theme || ''

  const {
    children,
    href = null,
    target = null,
    type = 'button',
    icon = '>',
    size = 'base',
    onClick
  } = props

  const TagName = href ? 'a' : 'button'

  const linkProps = { href, target }
  const buttonProps = { type }

  const className = `${variants.defaultClassName} ${variants.size[size]}`

  let Icon = null
  if (icon === '+') Icon = PlusIcon
  if (icon === 'x') Icon = XMarkIcon
  if (icon === '>') Icon = ArrowLongRightIcon

  return (
    <TagName
      {...(href ? linkProps : buttonProps)}
      onClick={onClick}
      className={className}
      data-theme={theme}
    >
      <span className='whitespace-nowrap group-hover/link:overflow-visible group-hover/link:max-w-full group-hover:overflow-visible group-hover:max-w-full [@media(hover:hover)]:[@media(pointer:fine)]:max-w-9 overflow-hidden transition duration-300 transition-all'>
        <span className='px-2 relative -top-[1px] h-5 block group-hover/link:opacity-100 group-hover/link:delay-300 group-hover:opacity-100 group-hover:delay-200 [@media(hover:hover)]:[@media(pointer:fine)]:opacity-0 duration-300 transition-all delay-50'>{children}</span>
      </span>
      {!!Icon && <Icon aria-hidden='true' className='size-5 absolute top-1/2 right-[7px] -mt-2.5 hidden [@media(hover:hover)]:[@media(pointer:fine)]:block group-hover/link:opacity-0 group-hover:opacity-0 transition transition-opacity duration-200 delay-100 group-hover:delay-0' />}
    </TagName>
  )
}
