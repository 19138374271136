const variants = {
  defaultClassName: `
    shrink-0 select-none relative translate-z-0 backface-hidden transition duration-200 inline-flex justify-center items-center font-medium
    before:absolute before:content-[""] before:w-full before:transition-all before:duration-200
    [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:translate-x-0 [@media(hover:hover)]:[@media(pointer:fine)]:focus:translate-y-0 [@media(hover:hover)]:[@media(pointer:fine)]:focus:translate-x-0
    active:-translate-y-1 active:bg-[--theme-button-1-hover-bg] active:-translate-x-1 active:before:opacity-100
  `,
  variant: {
    default: `
    bg-[--theme-button-1-bg] text-[--theme-button-1-text] [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1
    [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-[--theme-button-1-hover-bg] will-change-transform rounded-full
    [@media(hover:hover)]:[@media(pointer:fine)]:focus:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:focus:before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:active:before:opacity-0
    before:h-full before:block before:rounded-full before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:hover:before:opacity-100 before:shadow-button
    disabled:bg-[--theme-button-1-disabled-bg] disabled:text-[--theme-button-1-disabled-text] disabled:pointer-events-none
    [&.disabled]:bg-[--theme-button-1-disabled-bg] [&.disabled]:text-[--theme-button-1-disabled-text] [&.disabled]:pointer-events-none
    `,
    link: 'bg-transparent pt-2 pb-2 pl-0 pr-0 text-[--theme-text] before:left-0 before:bottom-0 before:border-b-2 before:border-[--theme-text] [@media(hover:hover)]:[@media(pointer:fine)]:hover:before:w-0',
    outline: `
      rounded-full bg-transparent border border-[--theme-button-outline] text-[--theme-button-outline] [@media(hover:hover)]:[@media(pointer:fine)]:hover:border-transparent [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:bg-midnight-700 [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-white rounded-full
      [@media(hover:hover)]:[@media(pointer:fine)]:focus:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:focus:before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:active:bg-[--theme-button-1-active-bg] [@media(hover:hover)]:[@media(pointer:fine)]:active:before:opacity-0
      before:h-full before:block before:rounded-full before:opacity-0 [@media(hover:hover)]:[@media(pointer:fine)]:hover:before:opacity-100 before:shadow-button
      disabled:border-charcoal-300 disabled:text-charcoal-300 disabled:bg-transparent disabled:pointer-events-none [&.disabled]:border-charcoal-300 [&.disabled]:text-charcoal-300 [&.disabled]:bg-transparent [&.disabled]:pointer-events-none
      active:border-transparent active:bg-midnight-700 active:text-white
    `
  },
  size: {
    base: 'pt-[14px] pb-[17px] px-8 text-base',
    sm: 'pt-[7px] pb-[9px] px-4 text-sm',
    icon: 'max-sm:w-auto py-4 pl-4 pr-4',
    iconsm: 'max-sm:w-auto pt-2 pb-2 pl-2 pr-2'
  },
  mobile: {
    default: '',
    full: 'max-sm:w-full',
    block: 'w-full',
    sm: 'max-sm:py-2 max-sm:px-4 max-sm:text-sm'
  }
}

export const Button = (props) => {
  const theme = props?.theme || null

  const {
    children,
    href = null,
    target = null,
    disabled = null,
    type = 'button',
    variant = 'default',
    size = 'base',
    mobile = 'default',
    onClick
  } = props

  const TagName = href ? 'a' : 'button'

  const linkProps = { href, target }
  const buttonProps = { type }

  const className = `${props?.className || ''} ${variants.defaultClassName} ${variants.size[size]} ${variants.variant[variant]} ${variants.mobile[mobile]}`

  return (
    <TagName
      {...(href ? linkProps : buttonProps)}
      onClick={onClick}
      className={className}
      data-theme={theme}
      disabled={disabled}
    >
      {children}
    </TagName>
  )
}
