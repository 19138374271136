/* TODO @Alex @justine please see image crops for navigation items below
  ColumnLinkWithImage will reuse exsisting crops but have one extra crop added @justine

  GlobalNavigationTileCrop and IconLargeCrop are both new crops that currently don't exist yet

  ColumnLinkWithImage crops
  -------------------------
  Url1280        : GlobalNavigationTileCrop             = 320 x 220     @1280 ^
  Url640         : ImageCallToActionMobileLargeCrop     = 720 x 360      @640 ^
  Url            : QuoteMobileCrop                      = 575 x 360

  ColumnDescriptiveLinkWithImage crop
  -----------------------------------
  Url            : IconLargeCrop                      = 128 x 128

*/

import { reactMount } from '@/lib/reactMount'
import { useRef, Fragment, useState, createContext, useContext } from 'react'
import { Button } from '@/components/Button'

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverBackdrop
} from '@headlessui/react'

import { Bars3Icon, ChevronDownIcon, MagnifyingGlassIcon, ArrowLeftEndOnRectangleIcon, XMarkIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { XCircleIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
import { LogoDark } from '@/components/icons/logo'
import { Media } from '@/components/Media'

/**
 * useAccordionContext to keep track which accordion is open.
 * Client require only 1 accordion open at a time per level.
 * We consider up to 2 levels of accordion
 * openAccordionIndex.level1 = index of level 1 accordion
 * openAccordionIndex.level2 = index of level 2 accordion
 * toggleAccordion = function to toggle accordion open/close
 */

// Layouts as per umbraco
const LAYOUTS = {
  Column: 'Column',
  DescriptiveLinkWithImage: 'Descriptive Link with Image',
  Heading: 'Heading',
  LinkWithImage: 'Link with Image',
  Link: 'Link'
}

const initialAccordionState = { level1: null, level2: null }

const AccordionContext = createContext()

const useAccordionContext = () => {
  const context = useContext(AccordionContext)
  if (!context) throw new Error('useAccordionContext must be used within an AccordionProvider')
  return context
}

const AccordionProvider = ({ children }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(initialAccordionState)

  const toggleAccordion = (level, index) => {
    setOpenAccordionIndex((prev) => ({
      ...prev,
      [level]: prev[level] === index ? null : index,
      ...(level === 'level1' ? { level2: null } : {})
    }))
  }

  return (
    <AccordionContext.Provider value={{ openAccordionIndex, toggleAccordion }}>
      {children}
    </AccordionContext.Provider>
  )
}

const GlobalNavigation = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current

  return (
    <div className='h-16'>
      <header className='bg-white fixed top-0 left-0 right-0 z-20 border-b border-charcoal-800'>
        <nav aria-label='Top' className='mx-auto max-w-screen-3xl px-4 sm:px-6 lg:px-8 text-charcoal-800'>
          <div className='flex h-16 items-center justify-between'>
            <div className='flex xl:w-60'>
              <a href='/' className='-mt-1'>
                <span className='sr-only'>Timely</span>
                <LogoDark />
              </a>
            </div>

            <div className='hidden xl:flex lg:self-stretch grow justify-center'>
              <div className='flex h-full space-x-7 justify-center'>
                {umbraco.map((item, index) => (
                  <Fragment key={index}>
                    {item.Layout === LAYOUTS.Link && <LayoutLink {...item} />}
                    {item.Layout === LAYOUTS.Column && <LayoutColumns {...item} />}
                  </Fragment>
                ))}
              </div>
            </div>

            <div className='flex items-center justify-end xl:w-60'>
              <div>
                <Search umbraco={umbraco} />
              </div>

              <div data-theme='charcoal-light' className='ml-4 lg:ml-6 shrink-0 max-xl:hidden'>
                <Button size='sm' href='https://app.gettimely.com/register'>
                  Free trial
                </Button>
              </div>

              <div className='ml-4 lg:ml-6'>
                <a href='https://app.gettimely.com/calendar' className='group -m-2 flex items-center p-2 space-x-2 [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700'>
                  <span className='text-base pb-1'>Login</span>
                  {/* Custom adjustment for client request size-5 stroke too thin/small stroke-2 too thick */}
                  <ArrowLeftEndOnRectangleIcon aria-hidden='true' className='size-[21px] ml-1.5' />
                </a>
              </div>

              <div className='xl:hidden ml-4 lg:ml-6'>
                <MobileNavigation umbraco={umbraco} />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

const MobileNavigation = ({ umbraco }) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton className='relative appearance-none outline-none active:outline-none focus:outline-none border-0 -mb-px flex items-center pt-px text-sm font-medium transition-colors duration-200 ease-out'>
            <span className='sr-only'>Open menu</span>
            <div className='relative'>
              <Bars3Icon aria-hidden='true' className={`size-5 duration-200 absolute ${open ? 'opacity-0' : ' opacity-100'}`} />
              <XCircleIcon aria-hidden='true' className={`size-5 duration-200 text-coral-400  ${open ? 'opacity-100' : ' opacity-0'}`} />
            </div>
          </PopoverButton>

          <PopoverPanel
            data-disable-main-scroll
            transition
            className='bg-white absolute z-10 inset-x-0 top-[65px] h-[calc(100dvh-65px)] text-sm transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in _mobile-main-menu'
          >
            <AccordionProvider>
              <div className='pt-6 px-4 sm:px-6 lg:px-8 flex flex-col grow overflow-auto h-[calc(100%-76px)]'>
                {umbraco.map((item, index) => (
                  <Fragment key={index}>
                    {item.Layout === LAYOUTS.Link && <LayoutLink {...item} />}
                    {item.Layout === LAYOUTS.Column && <MobileLayoutColumns index={index} {...item} />}
                  </Fragment>
                ))}
              </div>
            </AccordionProvider>

            <div className='space-y-6 py-5 px-4 sm:px-6 lg:px-8'>
              <div className='flow-root' data-theme='charcoal-light'>
                <Button size='sm' href='https://app.gettimely.com/register' mobile='block'>
                  Free trial
                </Button>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}

const Search = ({ umbraco }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const inputRef = useRef(null)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    if (searchQuery.trim()) {
      window.location.href = `/search/?query=${searchQuery}&type=All`
    }
  }

  const handleClear = () => {
    setSearchQuery('')
    inputRef.current.focus()
  }

  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            className='relative border-0 appearance-none outline-none active:outline-none focus:outline-none -mb-px flex items-center pt-px text-sm font-medium transition-colors duration-200 ease-out [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-purple-700'
          >
            <span className='sr-only'>Open search</span>
            <div className='relative'>
              <MagnifyingGlassIcon aria-hidden='true' className={`size-5 duration-200 absolute stroke-2 ${open ? 'opacity-0' : ' opacity-100'}`} />
              <XCircleIcon aria-hidden='true' className={`size-5 duration-200 text-coral-400 ${open ? 'opacity-100' : ' opacity-0'}`} />
            </div>
          </PopoverButton>

          <PopoverBackdrop className={`fixed transition-opacity duration-700 inset-0 top-16 bg-black/15 pointer-events-none ${open ? 'opacity-100' : ' opacity-0'}`} />

          <PopoverPanel
            focus
            transition
            className='lg:max-w-[calc(100vw-32px)] max-lg:h-[calc(100dvh-65px)] xl:max-w-screen-xl w-full left-1/2 top-[65px] lg:top-full lg:rounded-3xl z-10 p-8 xl:p-16 lg:mt-3 -translate-x-[50%] flex flex-col gap-4 bg-white absolute transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'
          >
            <div className='flex gap-2 mb-1 lg:mb-3'>
              {/*
                input must always be first so the focus feature in PopoverPanel works properly
                control the order of the rest of focusable elements with css order property e.g. order-1, order-2, order-3
              */}
              <input
                type='text'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder='Search Timely'
                className='order-2 grow outline-none placeholder-charcoal-400 text-3xl md:text-4xl font-serif pl-1 max-w-[calc(100%-44px)] _leading-search'
                ref={inputRef}
              />

              <button className='order-1 outline-0 appearance-none outline-none focus:outline-none active:outline-none mt-1' onClick={handleSearch}>
                <MagnifyingGlassIcon aria-hidden='true' className='size-6 charcoal-500' />
              </button>

              {!!searchQuery?.trim() && (
                <button onClick={handleClear} className='order-3 [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700'>
                  <XMarkIcon className='size-5 stroke-2' />
                </button>
              )}
            </div>

            <ul className='flex flex-col gap-1'>
              <li className='text-sm pb-1'>Quick links</li>
              <li className='leading-none'>
                <a href='/features/payments' className='text-midnight-500 inline-flex items-center gap-1'>
                  <ArrowLongRightIcon aria-hidden='true' className='size-5' />
                  Payments
                </a>
              </li>
              <li className='leading-none'>
                <a href='/features/consultation-app' className='text-midnight-500 inline-flex items-center gap-1'>
                  <ArrowLongRightIcon aria-hidden='true' className='size-5' />
                  Digital consultations
                </a>
              </li>
              <li className='leading-none'>
                <a href='/pricing' className='text-midnight-500 inline-flex items-center gap-1'>
                  <ArrowLongRightIcon aria-hidden='true' className='size-5' />
                  Pricing
                </a>
              </li>
              <li className='leading-none'>
                <a href='/features/online-bookings' className='text-midnight-500 inline-flex items-center gap-1'>
                  <ArrowLongRightIcon aria-hidden='true' className='size-5' />
                  Online bookings
                </a>
              </li>
              <li className='leading-none'>
                <a href='/industry/beauty-software' className='text-midnight-500 inline-flex items-center gap-1'>
                  <ArrowLongRightIcon aria-hidden='true' className='size-5' />
                  Beauty
                </a>
              </li>
            </ul>
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}

const MobileLayoutColumns = ({ index, ...item }) => {
  const { openAccordionIndex, toggleAccordion } = useAccordionContext()
  const isOpen = openAccordionIndex.level1 === index

  const flatColumns = [item.Column1, item.Column2, item.Column3, item.Column4, item.Column5].flat()
  const layoutLinksCount = flatColumns.filter(subItem => subItem.Layout === LAYOUTS.Link).length
  const makeLinkListAccordions = layoutLinksCount > 7

  flatColumns.forEach((subItem, subIndex) => {
    subItem.ItemIndex = subIndex
  })

  return (
    <dl key={item.Name} className='_mobile-stagger'>
      <dt>
        <button
          className='appearance-none outline-none active:outline-none focus:outline-none border-0 flex justify-between w-full py-5 text-left text-xl font-medium rounded-lg [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700 data-[open]:text-midnight-700 data-[open]:font-medium'
          onClick={() => toggleAccordion('level1', index)}
        >
          <span>{item.Name}</span>

          <div className='relative'>
            <PlusIcon aria-hidden='true' className={`size-5 _icon-stroke ease-out duration-400 ${isOpen ? 'opacity-0' : 'opacity-100'} absolute`} />
            <MinusIcon aria-hidden='true' className={`size-5 _icon-stroke ease-in duration-400 ${isOpen ? 'opacity-100' : 'opacity-0'}`} />
          </div>
        </button>
      </dt>
      <dd className={`grid ease-out duration-400 ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
        <div className='group relative text-sm overflow-hidden'>
          <div className='flex flex-col gap-4'>
            {!!item.Column1?.length && <MobileColumn makeLinkListAccordions={makeLinkListAccordions} items={item.Column1} />}
            {!!item.Column2?.length && <MobileColumn makeLinkListAccordions={makeLinkListAccordions} items={item.Column2} />}
            {!!item.Column3?.length && <MobileColumn makeLinkListAccordions={makeLinkListAccordions} items={item.Column3} />}
            {!!item.Column4?.length && <MobileColumn makeLinkListAccordions={makeLinkListAccordions} items={item.Column4} />}
            {!!item.Column5?.length && <MobileColumn makeLinkListAccordions={makeLinkListAccordions} items={item.Column5} />}
          </div>
        </div>
      </dd>
    </dl>
  )
}

const MobileColumn = ({ makeLinkListAccordions, items }) => {
  const { openAccordionIndex, toggleAccordion } = useAccordionContext()

  if (!makeLinkListAccordions) {
    return (
      <div className='flex flex-col gap-4 max-w-3xl shrink-0'>
        {items.map((item) => (
          <Fragment key={item.ItemIndex}>
            {item.Layout === LAYOUTS.Heading && <ColumnHeading {...item} />}
            {item.Layout === LAYOUTS.Link && <ColumnLink {...item} />}
            {item.Layout === LAYOUTS.LinkWithImage && <ColumnLinkWithImage {...item} />}
            {item.Layout === LAYOUTS.DescriptiveLinkWithImage && <ColumnDescriptiveLinkWithImage {...item} />}
          </Fragment>
        ))}
      </div>
    )
  }

  const renderHeadingWithLinks = (item, items, index) => {
    const isOpen = openAccordionIndex.level2 === item.ItemIndex

    return (
      <div>
        <dl key={item.ItemIndex}>
          <dt>
            <button
              className='appearance-none outline-none active:outline-none max-xl:mt-2 focus:outline-none border-0 flex justify-between w-full py-2 text-left text-base font-medium rounded-lg data-[open]:text-midnight-700 data-[open]:font-medium'
              onClick={() => toggleAccordion('level2', item.ItemIndex)}
            >
              <span>{item.Heading}</span>
              <div className='relative'>
                <PlusIcon aria-hidden='true' className={`size-5 _icon-stroke ease-out duration-400 ${isOpen ? 'opacity-0' : 'opacity-100'} absolute`} />
                <MinusIcon aria-hidden='true' className={`size-5 _icon-stroke ease-in duration-400 ${isOpen ? 'opacity-100' : 'opacity-0'}`} />
              </div>
            </button>
          </dt>
          <dd className={`grid ease-out duration-400 border-b border-charcoal-200 ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
            <div className='group relative text-sm overflow-hidden'>
              <div className='flex flex-col gap-4 pb-3 pt-1.5'>
                {items.slice(index + 1).map((subItem, subIndex) => {
                  if (subItem.Layout === LAYOUTS.Link) {
                    return <ColumnLink key={subIndex} {...subItem} />
                  } else if (subItem.Layout === LAYOUTS.Heading) {
                    return <Fragment key={subIndex} />
                  }
                  return null
                })}
              </div>
            </div>
          </dd>
        </dl>
      </div>
    )
  }

  const renderNonLinkItem = (item) => {
    return (
      <Fragment key={item.ItemIndex}>
        {item.Layout === LAYOUTS.LinkWithImage && <ColumnLinkWithImage {...item} />}
        {item.Layout === LAYOUTS.DescriptiveLinkWithImage && <ColumnDescriptiveLinkWithImage {...item} />}
      </Fragment>
    )
  }

  return (
    <div className='flex flex-col gap-4 max-w-3xl shrink-0'>
      {items.reduce((acc, item, index) => {
        if (item.Layout === LAYOUTS.Heading) {
          acc.push(renderHeadingWithLinks(item, items, index))
        } else if ([LAYOUTS.LinkWithImage, LAYOUTS.DescriptiveLinkWithImage].includes(item.Layout)) {
          acc.push(renderNonLinkItem(item))
        }
        return acc
      }, [])}
    </div>
  )
}

const LayoutColumns = (item) => {
  if (!item?.Column1 && !item?.Column2 && !item?.Column3 && !item?.Column4 && !item?.Column5) return null

  return (
    <Popover className='flex'>
      {({ open }) => (
        <>
          <div className='relative flex'>
            <PopoverButton className='relative appearance-none outline-none active:outline-none focus:outline-none border-0 -mb-px flex items-center pt-px transition-colors duration-200 ease-out [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700 data-[open]:text-midnight-700'>
              <span className='pb-1'>{item.Name}</span>
              <ChevronDownIcon aria-hidden='true' className={`size-[18px] ml-1 stroke-[2.5] ${open ? 'rotate-180' : ''}`} />
            </PopoverButton>
          </div>
          <div className={`fixed transition-opacity duration-700 inset-0 top-16 bg-black/15 pointer-events-none ${open ? 'opacity-100' : ' opacity-0'}`} />
          <PopoverPanel
            transition
            className='absolute max-w-[calc(100vw-32px)] left-[50%] top-full z-10 p-8 xl:p-16 mt-3 rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition -translate-x-[50%] data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'
          >
            <div className='relative bg-white w-auto'>
              <div className='mx-auto max-w-7xl'>
                <div className={`flex flex-col lg:flex-row gap-4 xl:gap-8 group ${item.Column5?.length ? 'nav-5-col' : ''}`}>
                  {!!item.Column1?.length && <Column items={item.Column1} />}
                  {!!item.Column2?.length && <Column items={item.Column2} />}
                  {!!item.Column3?.length && <Column items={item.Column3} />}
                  {!!item.Column4?.length && <Column items={item.Column4} />}
                  {!!item.Column5?.length && <Column items={item.Column5} />}
                </div>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}

const LayoutLink = ({ Link }) => {
  if (!Link) return null

  // pr-1 is here to compensate for the space between the links when there is a chevron icon (ml-1)
  return (
    <a href={Link.Url} target={Link.Target} className='py-5 xl:py-0 xl:pb-1 flex items-center text-xl xl:text-base max-xl:font-medium [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700 -mb-0.5 pr-1 _mobile-stagger'>
      {Link.Name}
    </a>
  )
}

const Column = ({ items }) => {
  return (
    <div className='flex flex-col gap-4 lg:w-56 shrink-0 lg:group-[.nav-5-col]:max-w-[calc((100vw-156px)/5)] xl:group-[.nav-5-col]:max-w-[calc((100vw-288px)/5)]'>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.Layout === LAYOUTS.Heading && <ColumnHeading {...item} />}
          {item.Layout === LAYOUTS.Link && <ColumnLink {...item} />}
          {item.Layout === LAYOUTS.LinkWithImage && <ColumnLinkWithImage {...item} />}
          {item.Layout === LAYOUTS.DescriptiveLinkWithImage && <ColumnDescriptiveLinkWithImage {...item} />}
        </Fragment>
      ))}
    </div>
  )
}

const ColumnHeading = ({ Heading }) => {
  return (
    <h3 className='text-lg font-medium lg:pl-3 mt-2 xl:mt-0'>{Heading}</h3>
  )
}
const ColumnLink = ({ Link }) => {
  if (!Link) return null

  return (
    <a href={Link.Url} target={Link.Target} className='text-base [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-midnight-700 pl-4 lg:pl-3 last:mb-2 xl:last-mb-0'>
      {Link.Name}
    </a>
  )
}

const ColumnLinkWithImage = (Item) => {
  if (!Item?.Link) return null
  return (
    <a
      href={Item.Link.Url} target={Item.Link.Target} className='relative text-base rounded-xl overflow-hidden
      [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:hover:-translate-x-1 will-change-transform
        transition-all duration-300 shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:hover:border-charcoal-900 [@media(hover:hover)]:[@media(pointer:fine)]:hover:shadow-button
        active:-translate-y-1 active:-translate-x-1 active:border-charcoal-900 active:shadow-button
        '
    >
      <div className='aspect-[9/5] lg:aspect-[4/3] relative'>
        <Media umbraco={Item} isVideoPreview />
      </div>
      <div className='py-[14px] pb-[18px]  px-5 block text-base bg-rose-200'>
        {Item.Link.Name}
      </div>
    </a>
  )
}

const ColumnDescriptiveLinkWithImage = ({ Link, Image, Description }) => {
  if (!Link) return null
  return (
    <a href={Link.Url} target={Link.Target} className='group/dlwi relative text-lg font-medium flex gap-2.5'>
      {Image && (
        <img
          alt={Image.AltText}
          src={Image.Url}
          className='aspect-square rounded-lg object-cover bg-rose-200 size-14 lg:size-16
          [@media(hover:hover)]:[@media(pointer:fine)]:group-hover/dlwi:-translate-y-1 [@media(hover:hover)]:[@media(pointer:fine)]:group-hover/dlwi:-translate-x-1 will-change-transform
          transition-all duration-300 shadow-cardInactive [@media(hover:hover)]:[@media(pointer:fine)]:group-hover/dlwi:border-charcoal-900 [@media(hover:hover)]:[@media(pointer:fine)]:group-hover/dlwi:shadow-button text-charcoal-800
          group-active/dlwi:-translate-y-1 group-active/dlwi:-translate-x-1 group-active/dlwi:shadow-button group-active/dlwi:border-charcoal-900
          '
        />
      )}
      <div className='block font-medium [@media(hover:hover)]:[@media(pointer:fine)]:group-hover/dlwi:text-midnight-700'>
        {Link.Name}
        <p className='text-sm font-light'>{Description}</p>
      </div>
    </a>
  )
}

reactMount('GlobalNavigation', GlobalNavigation)
