import { useState } from 'react'
import { PlayIcon } from '@heroicons/react/24/solid'
import { VideoModal } from '@/components/VideoModal'
import { Button } from '@/components/Button'

/** media.Image model example from backend
  const Image = {
    Url64: 'https:// ... /.jpg',
    Url32: 'https:// ... /.jpg',
    Url16: 'https:// ... /.jpg',
    Url: 'https:// ... /.jpg',
    AltText: 'Placeholder image'
  }
*/

// background list from: https://journey-digital.atlassian.net/browse/TIMELY-381?focusedCommentId=46471
const backgroundColors = ['bg-rose-900', 'bg-rose-300', 'bg-rose-100', 'bg-emerald-800', 'bg-emerald-200', 'bg-emerald-100', 'bg-coral-200', 'bg-coral-50', 'bg-charcoal-50']

export const getImageByBreakpoint = (mediaImage) => {
  const imageByBreakpoint = {}
  for (const minWidth in mediaImage) {
    if (mediaImage[minWidth] != null && minWidth !== 'Url' && minWidth.startsWith('Url')) {
      const newMinWidth = minWidth.replace(/\D/g, '') + 'px'
      imageByBreakpoint[newMinWidth] = mediaImage[minWidth]
    }
  }
  return imageByBreakpoint
}

/**
 * Media component that displays an image, video, or Vimeo video based on the provided Umbraco media data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.umbraco - The Umbraco media data object.
 * @param {boolean} [props.isVideoPreview=false] - Flag indicating whether the component is in preview mode. Use case: when Media component is used inside a link or button block, e.g. An article or card.
 * @returns {JSX.Element|null} The rendered Media component or null if no media is available.
 */
export const Media = ({ umbraco, isVideoPreview = false }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

  const media = umbraco?.Media || umbraco?.DefaultMedia || umbraco.Header?.Hero?.Media || (umbraco?.Image ? umbraco : null)

  if (!media) return null

  const hasImage = media?.Image?.Url
  const hasVideo = media?.VideoUrl
  const hasVimeo = !!media.VimeoUrl?.includes('vimeo.com')
  const vimeoId = hasVimeo && media.VimeoUrl.match(/vimeo\.com\/(\d+)/)?.[1]
  const vimeoUrl = hasVimeo && `https://player.vimeo.com/video/${vimeoId}`

  const imageByBreakpoint = getImageByBreakpoint(media.Image)

  return (
    <div className='absolute inset-0 flex justify-center items-center'>

      {hasImage && !hasVideo && (
        <picture className={`absolute inset-0 size-full ${backgroundColors[Math.floor(Math.random() * backgroundColors.length)]}`}>
          {Object.keys(imageByBreakpoint).map((minWidth) => (
            <source key={minWidth} media={`(min-width: ${minWidth})`} srcSet={imageByBreakpoint[minWidth]} />
          ))}
          <img
            loading='lazy'
            alt={media.Image.AltText || ''}
            src={media.Image.Url}
            className='duration-300 opacity-0 absolute inset-0 size-full object-cover'
            onLoad={(e) => {
              e.target.classList.remove('opacity-0')
            }}
          />
        </picture>
      )}

      {hasVideo && (
        // Because Images are always required, there is no need for poster attribute
        <video
          className={`absolute inset-0 object-cover object-center size-full ${backgroundColors[Math.floor(Math.random() * backgroundColors.length)]}`} autoPlay loop playsInline muted
        >
          <source src={media?.VideoUrl} type='video/mp4' />
        </video>
      )}

      {/* Themes are always the same for these */}
      {hasVimeo && !isVideoPreview && (
        <>
          <Button
            theme='gray-dark'
            size='icon'
            onClick={() => setIsVideoModalOpen(true)}
          >
            <span className='sr-only'>Play video</span>
            <PlayIcon aria-hidden='true' className='size-5' />
          </Button>
          {isVideoModalOpen && (
            <VideoModal
              theme='gray-light'
              setIsOpen={setIsVideoModalOpen}
              title={umbraco.Headline}
              src={vimeoUrl}
            />
          )}
        </>
      )}
    </div>
  )
}
