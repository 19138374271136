/*
  Content section V1a aka Accordion list content

  Image Crops main media
  -------------------------
  Url1024      : AccordionDesktopCrop        = 870 x 870       @1024 ^
  Url768       : AccordionTabletCrop         = 942 x 706       @768 ^
  Url640       : AccordionMobileLargeCrop    = 680 x 510       @640 ^
  Url          : AccordionMobileCrop         = 560 x 560

  Image Crop for icon image
  -------------------------
  Url         : IconSmallCrop               = 40 x 40
*/

import { reactMount } from '@/lib/reactMount'
import { useEffect, useId, useRef, useState } from 'react'
import { Media } from '@/components/Media'
import { Button } from '@/components/Button'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper/modules'
import { motion } from 'motion/react'
import { animationSettings } from '@/animationSettings'

const ContentSectionV1a = ({ dataset, umbraco }) => {
  const umbracoRef = useRef(umbraco || JSON.parse(dataset.umbraco))
  umbraco ||= umbracoRef.current
  umbraco.BlockId = useId()
  umbraco.InteractiveList.forEach((tile) => { if (!tile.Media) { tile.Media = umbraco.DefaultMedia } })
  const theme = umbraco?.Theme || 'charcoal-dark'
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const mediaContentWidthSpacingLeft = 'xl:pr-1 xl:pl-8 2xl:pr-2'
  const mediaContentWidthSpacingRight = 'xl:pl-1 xl:pr-8 2xl:pl-2'
  const contentWidthSpacingLeft = 'lg:pl-8 md:pr-8 lg:pr-12'
  const contentWidthSpacingRight = 'lg:pr-8 md:pl-8 lg:pl-12 lg:order-2'

  const {
    standardTransition,
    standardViewportTrigger,
    standardTransitionEasing,
    standardTransitionDelay
  } = animationSettings()

  return (
    <>
      <section id={umbraco?.AnchorTarget || null} data-block-id={umbraco.BlockId} data-theme={theme} className='bg-[--theme-bg] text-[--theme-text]' style={{ '--progress': '0%' }}>
        <div className='relative mx-auto max-w-screen-2xl'>
          <div className='flex flex-col lg:flex-row w-full'>
            <div className='lg:hidden py-10 px-4 md:px-8 pt-12 pb-8'>
              <Header umbraco={umbraco} />
            </div>
            <div className={`${umbraco.MediaLeft ? contentWidthSpacingRight : contentWidthSpacingLeft} shrink-0 max-lg:order-3 px-4 md:px-8 pb-10 lg:py-20 flex items-center w-full lg:max-w-[450px] xl:max-w-[630px]`}>
              <motion.div
                initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
                className='mx-auto lg:mx-0 w-full'
              >
                <div className='max-lg:hidden'>
                  <Header umbraco={umbraco} />
                </div>

                <Accordions umbraco={umbraco} currentSlideIndex={currentSlideIndex} setCurrentSlideIndex={setCurrentSlideIndex} />

                <div className='mt-8 flex items-center gap-y-4 gap-x-8 lg:mr-4 flex-wrap max-sm:justify-center'>
                  {umbraco.Button && (
                    <Button href={umbraco.Button.Url} target={umbraco.Button.Target} mobile='full'>
                      {umbraco.Button.Name}
                    </Button>
                  )}
                  {umbraco.ButtonLink && (
                    <Button href={umbraco.ButtonLink.Url} target={umbraco.ButtonLink.Target} variant='link'>
                      {umbraco.ButtonLink.Name}
                    </Button>
                  )}
                </div>

                <p className='ml-4 text-pretty text-xs pt-5 max-w-48 max-sm:text-center max-sm:mx-auto max-sm:mt-2'>
                  {umbraco.SubLabel}
                </p>

              </motion.div>
            </div>

            {!!umbraco.InteractiveList?.length && (
              <div className={`relative grow px-4 md:px-8 lg:py-5 lg:px-2.5 ${umbraco.MediaLeft ? mediaContentWidthSpacingLeft : mediaContentWidthSpacingRight} h-full w-full lg:max-w-[calc(100%-450px)] xl:max-w-[calc(100%-630px)] lg:sticky lg:top-16`}>
                <Slides
                  umbraco={umbraco}
                  currentSlideIndex={currentSlideIndex}
                  setCurrentSlideIndex={setCurrentSlideIndex}
                />
              </div>
            )}

            {!umbraco.InteractiveList?.length && (
              <div className={`relative grow px-4 md:px-8 lg:py-5 lg:px-2.5 ${umbraco.MediaLeft ? mediaContentWidthSpacingLeft : mediaContentWidthSpacingRight} h-full w-full lg:max-w-[calc(100%-450px)] xl:max-w-[calc(100%-630px)] lg:sticky lg:top-16`}>
                <div className='lg:h-[910px] max-h-[calc(100dvh-104px)] relative p-2 h-full flex justify-center items-center aspect-square sm:aspect-[4/3] lg:aspect-auto overflow-hidden'>
                  <Media umbraco={umbraco} />
                  {/* Custom motion */}
                  <motion.div
                    initial={{ inset: 'var(--image-animation-border-size)' }} whileInView={{ inset: '0px' }}
                    transition={{ duration: standardTransition, delay: standardTransitionDelay, ease: standardTransitionEasing }}
                    viewport={{ once: true, amount: standardViewportTrigger }} className='absolute _image-border rounded-lg'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const Slides = ({ className, umbraco, currentSlideIndex, setCurrentSlideIndex }) => {
  const swiperRef = useRef(null)

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          swiperRef.current.autoplay.start()
        } else {
          swiperRef.current.autoplay.stop()
        }
      },
      {
        root: null,
        rootMargin: '15% 0px',
        threshold: 0.1
      }
    )

    if (swiperRef.current) {
      swiperRef.current.autoplay.stop()
      observer.observe(swiperRef.current.el)
    }

    return () => {
      if (swiperRef.current) {
        observer.unobserve(swiperRef.current.el)
      }
    }
  }, [])

  useEffect(() => {
    updateProgress(umbraco.BlockId, 0)
    if (swiperRef.current.realIndex !== currentSlideIndex) {
      swiperRef.current.slideToLoop(currentSlideIndex)
    }
  }, [currentSlideIndex])

  const updateProgress = (BlockId, progress) => {
    window.requestAnimationFrame(() => {
      if (isModalOpen()) {
        stopAutoplayUntilModalCloses()
      } else {
        document.querySelector(`[data-block-id="${BlockId}"]`)?.style.setProperty('--progress', `${progress}%`)
      }
    })
  }

  const isModalOpen = () => !!document.querySelector('[data-headlessui-portal]')

  const stopAutoplayUntilModalCloses = () => {
    swiperRef.current.autoplay.stop()
    const interval = setInterval(() => {
      if (!isModalOpen()) {
        swiperRef.current.autoplay.start()
        clearInterval(interval)
      }
    }, 250)
  }

  const {
    standardTransitionEasing,
    standardTransitionDuration,
    standardTransitionDelay
  } = animationSettings()

  return (
    <Swiper
      onSwiper={(swiper) => { swiperRef.current = swiper }}
      loop
      effect='fade'
      allowTouchMove={false}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false
      }}
      onSlideChangeTransitionEnd={(s) => {
        setCurrentSlideIndex(s.realIndex)
      }}
      modules={[Autoplay, EffectFade]}
      className={`${className} w-full lg:h-[910px] max-h-[calc(100dvh-104px)]`}
      onAutoplayTimeLeft={(s, _, progress) => {
        updateProgress(umbraco.BlockId, (100 - progress * 100))
      }}
    >
      {umbraco.InteractiveList.map((tile) => (
        <SwiperSlide key={tile.Headline} className='relative p-2 h-full flex justify-center items-center aspect-square sm:aspect-[4/3] lg:aspect-auto overflow-hidden'>
          <Media umbraco={tile} />
          {/* Custom motion */}
          <motion.div
            initial={{ inset: 'var(--image-animation-border-size)' }} whileInView={{ inset: '0px' }}
            transition={{ duration: standardTransitionDuration, delay: standardTransitionDelay, ease: standardTransitionEasing }}
            viewport={{ once: true }} className='absolute _image-border rounded-lg'
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const Accordions = ({ umbraco, currentSlideIndex, setCurrentSlideIndex }) => {
  return (
    <div className='mx-auto max-w-4xl'>
      <div className='mt-6'>
        {umbraco.InteractiveList.map((tile, index) => {
          const isOpen = index === currentSlideIndex

          return (
            <div className='relative' key={tile.Headline}>
              <article>
                <header>
                  {/* This quick 200ms animation is to hide a progress bar flash */}
                  <ProgressBar className={`duration-200 ${isOpen ? 'opacity-100' : 'opacity-0'}`} />
                  <button
                    className={`${isOpen ? 'pointer-events-none' : ''} group flex w-full items-start justify-start text-left border-t border-[--theme-hr] pt-[14px] pb-[16px] overflow-x-hidden [@media(hover:hover)]:[@media(pointer:fine)]:hover:text-[--theme-button-1-bg]`}
                    onClick={(e) => { !isOpen && setCurrentSlideIndex(index) }}
                  >
                    {tile.Headline && (
                      <h3 className='flex relative text-xl font-medium'>
                        {tile.Icon && (
                          <>
                            <img className={`absolute left-0 top-0 size-5 mt-1 mr-2.5 duration-300 delay-100  ${isOpen ? 'translate-x-0 opacity-100' : '-translate-x-8 opacity-0'}`} alt={tile.Icon.AltText} src={tile.Icon.Url} />
                            <span className={`text-pretty duration-300 delay-100 ${isOpen ? 'translate-x-8' : 'translate-x-0'}`}>{tile.Headline}</span>
                          </>
                        )}
                        {!tile.Icon && (
                          <span className='text-pretty'>{tile.Headline}</span>
                        )}
                      </h3>
                    )}
                  </button>
                </header>
                <div className={`grid ease-out duration-400 ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
                  {tile.Paragraph && (
                  // this div.overflow-hidden is used for the transition effect
                    <div className={`overflow-hidden ease-in duration-400 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
                      <div
                        className='text-base _rte font-light pb-4'
                        dangerouslySetInnerHTML={{ __html: tile.Paragraph }}
                      />
                    </div>
                  )}
                </div>
              </article>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Header = ({ umbraco }) => {
  const {
    standardTransition,
    standardViewportTrigger
  } = animationSettings()

  return (
    <motion.header
      initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
      transition={standardTransition} viewport={{ once: true, amount: standardViewportTrigger }}
      className='lg:pt-6'
    >
      {umbraco.Label && (
        <p className='mb-2 text-pretty text-base text-[--theme-subLabel]'>
          {umbraco.Label}
        </p>
      )}
      {umbraco.Logo && (
        <picture className='my-2.5 block'>
          {/* TODO: update crop to use max width instead - logo should not be cropped instead restricted by height */}
          <img className='max-h-10 w-auto' loading='lazy' alt={umbraco.Logo.AltText} src={umbraco.Logo.Url} />
        </picture>
      )}
      {umbraco.Headline && (
        <h2 className='text-pretty text-4xl xl:text-5xl font-serif mb-5'>
          {umbraco.Headline}
        </h2>
      )}

      {umbraco.Summary && (
        <div
          className='_rte text-pretty text-base font-light'
          dangerouslySetInnerHTML={{ __html: umbraco.Summary }}
        />
      )}
    </motion.header>
  )
}

const ProgressBar = ({ className }) => {
  return (
    <div data-progress-bar aria-hidden='true' className={`absolute top-[1px] w-full overflow-hidden bg-transparent ${className}`}>
      <div className='w-[--progress] h-1 bg-coral-400' />
    </div>
  )
}

reactMount('ContentSectionV1a', ContentSectionV1a)
