export const SocialIconFacebook = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.5152 0C6.94637 0 0 6.94637 0 15.5152C0 24.0839 6.94637 31.0303 15.5152 31.0303C24.0839 31.0303 31.0303 24.0839 31.0303 15.5152C31.0303 6.94637 24.0839 0 15.5152 0ZM17.128 16.1967V24.6377H13.6355V16.197H11.8906V13.2882H13.6355V11.5417C13.6355 9.16868 14.6207 7.75758 17.42 7.75758H19.7504V10.6667H18.2937C17.204 10.6667 17.132 11.0733 17.132 11.8319L17.128 13.2878H19.7669L19.4581 16.1967H17.128Z' fill='currentColor' />
    </svg>
  )
}

export const SocialIconInstagram = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.5679 0C6.9991 0 0.0527344 6.94637 0.0527344 15.5152C0.0527344 24.0839 6.9991 31.0303 15.5679 31.0303C24.1367 31.0303 31.083 24.0839 31.083 15.5152C31.083 6.94637 24.1367 0 15.5679 0ZM12.1585 7.29041C13.0411 7.25024 13.3231 7.24042 15.5704 7.24042H15.5679C17.8159 7.24042 18.0969 7.25024 18.9795 7.29041C19.8604 7.33075 20.4621 7.47022 20.9896 7.67485C21.5344 7.88603 21.9946 8.16875 22.4549 8.62904C22.9152 9.08898 23.1979 9.55065 23.41 10.0949C23.6134 10.621 23.753 11.2223 23.7944 12.1033C23.8341 12.9859 23.8444 13.2679 23.8444 15.5153C23.8444 17.7626 23.8341 18.0439 23.7944 18.9266C23.753 19.8071 23.6134 20.4086 23.41 20.9349C23.1979 21.479 22.9152 21.9407 22.4549 22.4006C21.9952 22.8609 21.5342 23.1443 20.9901 23.3557C20.4636 23.5603 19.8616 23.6998 18.9807 23.7401C18.0981 23.7803 17.8169 23.7901 15.5694 23.7901C13.3223 23.7901 13.0404 23.7803 12.1578 23.7401C11.277 23.6998 10.6755 23.5603 10.149 23.3557C9.60515 23.1443 9.14349 22.8609 8.68371 22.4006C8.2236 21.9407 7.94088 21.479 7.72935 20.9347C7.52489 20.4086 7.38543 19.8073 7.34492 18.9264C7.30492 18.0437 7.29492 17.7626 7.29492 15.5153C7.29492 13.2679 7.30527 12.9857 7.34474 12.1031C7.38439 11.2225 7.52403 10.621 7.72918 10.0947C7.94122 9.55065 8.22394 9.08898 8.68423 8.62904C9.14417 8.16892 9.60584 7.8862 10.1501 7.67485C10.6762 7.47022 11.2775 7.33075 12.1585 7.29041Z' fill='currentColor' />
      <path fillRule='evenodd' clipRule='evenodd' d='M14.8206 8.73156C14.9648 8.73133 15.1198 8.7314 15.2871 8.73148L15.5629 8.73156C17.7723 8.73156 18.0342 8.73949 18.9067 8.77914C19.7135 8.81603 20.1513 8.95084 20.443 9.0641C20.8292 9.21409 21.1045 9.39337 21.3939 9.68299C21.6836 9.97261 21.8628 10.2484 22.0132 10.6346C22.1264 10.9259 22.2614 11.3638 22.2981 12.1706C22.3378 13.0429 22.3464 13.305 22.3464 15.5133C22.3464 17.7216 22.3378 17.9837 22.2981 18.856C22.2612 19.6628 22.1264 20.1007 22.0132 20.392C21.8632 20.7782 21.6836 21.0531 21.3939 21.3426C21.1043 21.6322 20.8294 21.8115 20.443 21.9615C20.1517 22.0752 19.7135 22.2097 18.9067 22.2466C18.0344 22.2863 17.7723 22.2949 15.5629 22.2949C13.3534 22.2949 13.0915 22.2863 12.2192 22.2466C11.4124 22.2094 10.9745 22.0746 10.6827 21.9613C10.2965 21.8113 10.0207 21.632 9.73108 21.3424C9.44146 21.0528 9.26217 20.7776 9.11184 20.3913C8.99858 20.1 8.8636 19.6621 8.82688 18.8553C8.78723 17.983 8.7793 17.721 8.7793 15.5112C8.7793 13.3015 8.78723 13.0409 8.82688 12.1685C8.86377 11.3618 8.99858 10.9239 9.11184 10.6322C9.26182 10.246 9.44146 9.9702 9.73108 9.68058C10.0207 9.39096 10.2965 9.21167 10.6827 9.06135C10.9744 8.94757 11.4124 8.8131 12.2192 8.77604C12.9826 8.74156 13.2784 8.73122 14.8206 8.72949V8.73156ZM19.9754 10.1055C19.4272 10.1055 18.9824 10.5498 18.9824 11.0982C18.9824 11.6464 19.4272 12.0912 19.9754 12.0912C20.5236 12.0912 20.9684 11.6464 20.9684 11.0982C20.9684 10.55 20.5236 10.1052 19.9754 10.1052V10.1055ZM11.3184 15.5152C11.3184 13.1685 13.221 11.2658 15.5677 11.2657C17.9144 11.2657 19.8166 13.1684 19.8166 15.5152C19.8166 17.862 17.9146 19.7638 15.5678 19.7638C13.2211 19.7638 11.3184 17.862 11.3184 15.5152Z' fill='currentColor' />
      <path d='M15.5688 12.7568C17.0921 12.7568 18.3271 13.9917 18.3271 15.5151C18.3271 17.0384 17.0921 18.2734 15.5688 18.2734C14.0454 18.2734 12.8105 17.0384 12.8105 15.5151C12.8105 13.9917 14.0454 12.7568 15.5688 12.7568Z' fill='currentColor' />
    </svg>
  )
}

export const SocialIconTikTok = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.5991 0C7.03035 0 0.0839844 6.94637 0.0839844 15.5152C0.0839844 24.0839 7.03035 31.0303 15.5991 31.0303C24.1679 31.0303 31.1143 24.0839 31.1143 15.5152C31.1143 6.94637 24.1679 0 15.5991 0ZM20.3653 10.2255C20.9312 10.8178 21.6858 11.2266 22.5191 11.3923C22.7943 11.4462 23.0748 11.4733 23.356 11.473V14.128C21.9279 14.1304 20.535 13.7164 19.3733 12.9444V18.3631C19.3732 19.339 19.0603 20.2927 18.4746 21.1025C17.8888 21.9123 17.0567 22.5415 16.0845 22.9097C15.1123 23.278 14.0442 23.3686 13.0163 23.1699C11.9885 22.9713 11.0477 22.4925 10.3139 21.7946C9.54449 21.0624 9.0405 20.125 8.87243 19.1135C8.70437 18.1019 8.88064 17.0669 9.37659 16.153C9.87254 15.2392 10.6634 14.4923 11.6384 14.0169C12.6135 13.5414 13.7241 13.3611 14.8148 13.5013V16.2161C14.3257 16.0714 13.7997 16.0753 13.3131 16.2273C12.8266 16.3792 12.4047 16.6713 12.1087 17.0611C11.8128 17.4509 11.6581 17.9183 11.6671 18.3953C11.6761 18.8724 11.8483 19.3344 12.1588 19.7143C12.4646 20.088 12.8886 20.3632 13.3713 20.5013C13.854 20.6394 14.3712 20.6335 14.8501 20.4843C15.329 20.3351 15.7455 20.0502 16.0412 19.6696C16.3369 19.289 16.4969 18.8318 16.4987 18.3623V7.75757H19.3733V8.09963C19.4519 8.8884 19.7994 9.63323 20.3653 10.2255Z' fill='currentColor' />
    </svg>
  )
}

export const SocialIconYoutube = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.5426 0C6.97592 0 0.03125 6.94637 0.03125 15.5152C0.03125 24.0839 6.97592 31.0303 15.5426 31.0303C24.1093 31.0303 31.054 24.0839 31.054 15.5152C31.054 6.94637 24.1093 0 15.5426 0ZM22.0103 10.1814C22.7222 10.3768 23.2829 10.9526 23.4731 11.6837C23.8189 13.0089 23.8189 15.7738 23.8189 15.7738C23.8189 15.7738 23.8189 18.5386 23.4731 19.8638C23.2829 20.595 22.7222 21.1707 22.0103 21.3662C20.7201 21.7212 15.5462 21.7212 15.5462 21.7212C15.5462 21.7212 10.3722 21.7212 9.08197 21.3662C8.37004 21.1707 7.80938 20.595 7.61911 19.8638C7.27344 18.5386 7.27344 15.7738 7.27344 15.7738C7.27344 15.7738 7.27344 13.0089 7.61911 11.6837C7.80938 10.9526 8.37004 10.3768 9.08197 10.1814C10.3722 9.82629 15.5462 9.82629 15.5462 9.82629C15.5462 9.82629 20.7201 9.82629 22.0103 10.1814Z' fill='currentColor' />
      <path d='M13.9922 18.618V13.4463L18.1286 16.0322L13.9922 18.618Z' fill='currentColor' />
    </svg>
  )
}
